const constants = {
  ADMIN_ROOM_ID: "eyJhbGciOiJIUzI1NiJ9.QURNSU4gUk9PTQ.WAiiKlsNW_WwsysjPY4TJcvePzRkB3pJvh_tmf1xPSY",
  DOMAIN:
    process.env.NODE_ENV === "development"
      ? "http://localhost:5001"
      :  "https://youvah.com",
      // "https://youvah-v1.herokuapp.com",

  NEW_DOMAIN:
    process.env.NODE_ENV === "development"
      ? "http://localhost:5001"
      : // ? "https://youvah.com"
      "https://youvah.com",
  idToPOC: {
        "649bcba1e6da06f4fae8653e": "Anshul",
        "649bcba1e6da06f4fae8653f": "Akshay",
        "649bcba1e6da06f4fae86540": "Shyam",
        "64a3b733a8a16de2e4894833": "Kuldeep",
        "64a11376a8a16de2e4894825": "Deepika",
        "651e5ee97e6d414554fa3f2e": "Naama",
        "652833fe81efc0b4ddaa3f50": "Archi",
        "6528342581efc0b4ddaa3f51": "Divyank",
        "6528345881efc0b4ddaa3f52": "Chanchal",
        "6528348381efc0b4ddaa3f53": "Sakshi",
        "652834ad81efc0b4ddaa3f54": "Richard",
        "652834de81efc0b4ddaa3f55": "Himani",
        "652997a5e13119fcf267b9fa": "Kashyap",
        "65299825e13119fcf267b9fb": "Nehal",
        "65299825e13119fcf267b9fc": "Yashashree",
        "65299825e13119fcf267b9fd": "Aastha",
        "65323853e8bdc11d099c8baa": "Dipali Yadav",
        "65323853e8bdc11d099c8bab": "Swati Malakar",
        "65323853e8bdc11d099c8bac": "Chhavani Karma",
        "65323853e8bdc11d099c8bad": "Vedansh Naik",
        "65323853e8bdc11d099c8bae": "Aakash Yadav",
        "65323853e8bdc11d099c8baf": "Shailendra Kewat",
        "65323853e8bdc11d099c8bb0": "Srashti Sahu",
        "65323853e8bdc11d099c8bb1": "Chanchal thakur",
        "65323853e8bdc11d099c8bb2": "Ramesh Kag",
        "65323853e8bdc11d099c8bb3": "Arshdeep Singh",
        "65323853e8bdc11d099c8bb4": "Ashwini",
        "65323853e8bdc11d099c8bb5": "Suresh Patidar",
        "65323853e8bdc11d099c8bb6": "Shyam Patel",
        "65323853e8bdc11d099c8bb7": "Gaurav Soni",
        "65337a4143d2714c06c1c184": "Richa Nema",
        "65571487c57d2975cd00176a": "Yashu",
        "659bd0ec2b51a131a915d7c3": "Saloni chouhan",
        "659bd1042b51a131a915d7c4": "Nishchay chadar",
        "653c8df2c50255f3708c4961": "Yatharth Bhawsar",
        "663321c04717433c88bd5d44": "Devesh Relwani",
        "66825c66fc2385731821a8d0": "Shikha",
        "66a49313e6d73300cabce0bf":"Vinay",
        "N/A": "Direct",
      },
  idToFilter:
    process.env.NODE_ENV === "development"
      ? {
        "649ba3c4c0b5cb7fe02e03f0": "Anshul",
        "649ba3c7c0b5cb7fe02e03fa": "Akshay",
        "N/A": "Direct",
      }
      : {
        "649bcba1e6da06f4fae8653e": "Anshul",
        "649bcba1e6da06f4fae8653f": "Akshay",
        "649bcba1e6da06f4fae86540": "Shyam",
        "64a3b733a8a16de2e4894833": "Kuldeep",
        "651e5ee97e6d414554fa3f2e": "Naama",
        "652834de81efc0b4ddaa3f55": "Himani",
        "652997a5e13119fcf267b9fa": "Kashyap",
        "65299825e13119fcf267b9fc": "Yashashree",
        "65299825e13119fcf267b9fd": "Aastha",
        "65571487c57d2975cd00176a": "Yashu",
        "653c8df2c50255f3708c4961": "Yatharth Bhawsar",
        "663321c04717433c88bd5d44": "Devesh Relwani",
        "66825c66fc2385731821a8d0": "Shikha",
        "N/A": "Direct",
      },
  telecmiIds: {
    "D62Y0El6waXk3JC6fOx5HjPdtKV2": {
      userId: "1000_33333581",
      callerId: 918031405180
    },
    "HzC5Zn0iZLh3TszzFP4yu94w1Sb2": {
      userId: "1002_33333581",
      callerId: 918031405181
    },
    "fKIZf9xgrJZSYi81Q3lQNYO4kKp1": {
      userId: "1003_33333581",
      callerId: 918031405182
    },
    "Sya6KqjrALXLVReGlxRkDpdA90r2": {
      userId: "1004_33333581",
      callerId: 918031405183
    },
    "ctXSza2BdHV4bLpj1SJtYMwEgpE2": {
      userId: "1005_33333581",
      callerId: 918031405184
    },
    "VziKj8OzyARQNRu5De2meam9UPY2": {
      userId: "1006_33333581",
      callerId: 918031405185
    },
    "hbTdC23dB0ONOdAChhqbhAaI4it1": {
      userId: "1007_33333581",
      callerId: 918031405186
    },
    "aSZnAQiiJCMcY919Brg8YFs51x72": {
      userId: "1010_33333581",
      callerId: 918031405187
    },
    "RQXQ6OHAyCeZCAvUt2BA138edr42": {
      userId: "1011_33333581",
      callerId: 918031405189
    }
  }

}

module.exports = constants

import axios from "axios"
import { useEffect, useState, useRef } from "react"
import dayjs from "dayjs"
import TextArea from "antd/es/input/TextArea"
import constants from "../../constants"
import { useNavigate } from "react-router-dom"
import {
  Button,
  Form,
  Input,
  Radio,
  Row,
  Col,
  Divider,
  DatePicker,
  TimePicker,
  Select,
  Space,
  Card,
  Typography,
  message,
  List,
  Skeleton,
  Table,
  Tooltip,
  Badge,
  Statistic,
  Modal,
  Avatar,
  Switch,
  Tag,
  Popconfirm,
  Tabs,
  Timeline,
  Popover,
} from "antd"
import {
  SearchOutlined,
  EditOutlined,
  WhatsAppOutlined,
  CheckOutlined,
  CloseCircleTwoTone,
  ClockCircleTwoTone,
  ReloadOutlined,
  ScissorOutlined,
  BankOutlined,
  RocketTwoTone,
  StarFilled,
  NodeIndexOutlined,
  PlusOutlined,
  DiffOutlined,
  PercentageOutlined,
  HomeOutlined,
  LoadingOutlined,
  InfoCircleTwoTone,
  UserOutlined,
  MailTwoTone,
  QuestionCircleOutlined
} from "@ant-design/icons"
import { AutoComplete } from "antd"
import Loader from "../../util/loader"
import useView from "../../hooks/useView"
import Meta from "antd/es/card/Meta"

const { Option } = Select
const { Text } = Typography
const Feedback_V1 = () => {
  const { RangePicker } = DatePicker;
  const [data, setData] = useState()
  const [schoolId, setSchoolId] = useState({
    verified:false,
    previousValue:''
  })
  const [loading, setLoading] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [analytics, setAnalytics] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [feedback, setFeeback] = useState()
  const [singleFeedback, setSingleFeeback] = useState()
  const { width } = useView()
  const navigate = useNavigate()
  const searchInput = useRef(null)
  const [messageApi, contextHolder] = message.useMessage()
  const [form] = Form.useForm()
  const [value, setValue] = useState()
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      position: ["topCenter"],
    },
    filters: {},
  })
  const { isMobile } = useView()
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [modalText, setModalText] = useState("Content of the modal")
  const showModal = () => {
    setOpen(true)
  }
  const handleOk = () => {
    setModalText("The modal will be closed after two seconds")
    setConfirmLoading(true)
    setTimeout(() => {
      setOpen(false)
      setConfirmLoading(false)
    }, 2000)
  }
  const handleCancel = () => {
    console.log("Clicked cancel button")
    setOpen(false)
  }


  const fetchFeedback = async () => {
    setTableLoading(true)
    let query = []
    console.log(tableParams)
    Object.keys(tableParams.filters).forEach((k) => {
      if (
        tableParams.filters[k] !== null &&
        tableParams.filters[k].length > 0
      ) {
        if (k === "companyName") {
          query.push(`${k}:sw=${tableParams.filters[k]}`)
        }else {
          query.push(`${k}=${tableParams.filters[k]}`)
        }
      }
    })

    query =
      `page=${tableParams.pagination.current}&limit=${tableParams.pagination.pageSize}&` +
      query.join("&")
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/feedback/getAllTicket?status:ne=CLOSED&${query}`
      const res = await axios.get(url)
      setFeeback(res.data.data.tickets)
      // setTableParams({
      //   ...tableParams,
      //   pagination: {
      //     ...tableParams.pagination,
      //     total: res.data.results,
      //   },
      // })
      setTableLoading(false)
    } catch (error) {
      setTableLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    fetchFeedback()
    fetchAnalytics()
  }, [ tableParams.pagination.current,
    tableParams.pagination.pageSize,
    tableParams.filters,])

  const handleSave = async (values) => {
    console.log(values)
    const orientationDate = new Date(values.date)
    const orientationTime = new Date(values.time)
    orientationDate.setHours(
      orientationTime.getHours(),
      orientationTime.getMinutes(),
      0,
      0
    )
    setLoading(true)
    try {
      const body = {
        schoolId: values.schoolId,
        name: values.name,
        numberOfStudents: values.numberOfStudents,
        notes: values.notes,
        city: values.city,
        refferalCode: values.refferalCode,
        state: values.state,
        orientationDate: orientationDate,
        ...(values.customPrice && { customPrice: values.customPrice }),
        POC: {
          name: values.POC_Name,
          contact: values.POC_Phone,
          email: values.POC_Email,
          designation: values.POC_Designation,
        },
        nextFollowUp: new Date(values.nextFollowUp),
        sessionStartDate : new Date(values.sessionStartDate),
        schoolCoordinator: values.POC_Name,
        inTake: values.inTake,
        mainGroupLink: values.mainGroupLink,
        logoUrl: values.logoUrl,
        leadType: values.leadType,
        POC_V1:values.POC_V1,
        partner:values.partner
      }
      const url = `${constants.NEW_DOMAIN}/api/v1/school/createAndUpdateSchools`
      const res = await axios.post(url, body)
      messageApi.open({
        type: "success",
        content: "Saved successfully",
      })
      // update the record here, so that no explicit refresh is required
      setFeeback((schools) => {
        return schools.map((school) => {
          return school.schoolId === body.schoolId ? body : school
        })
      })
      setOpen(false)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      console.log(error.response.data.message)
      setOpen(false)
      fetchFeedback()
    }
  }
  const handleReset = (clearFilters) => {
    // sessionStorage.setItem('leadcrmb2b','page=1&limit=10&')
    sessionStorage.clear()
    clearFilters()
    fetchFeedback()
  }
  const fetchFeedbackById = async (id) => {
    setIsModalOpen(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/feedback/getTaskDetailsById/${id}`
      const res = await axios.get(url)
      console.log(res)
      setSingleFeeback(res.data.data.ticket)
      console.log(res?.data?.data?.ticket?.enrollmentId?.progress?.metaData?.isInternshipCertificateOnHold);
      form.setFieldValue("isCertificate",res?.data?.data?.ticket?.enrollmentId?.progress?.metaData?.isInternshipCertificateOnHold || false)
      form.setFieldValue("isStarStudent",res?.data?.data?.ticket?.enrollmentId?.progress?.metaData?.isStar || false)
      form.setFieldValue("isStar",res?.data?.data?.ticket?.enrollmentId?.progress?.internship[res.data.data.ticket.weekId]?.isStar || false)
      setModalLoading(false)
    } catch (error) {
      setModalLoading(false)
      console.log(error)
    }
  }

  const fetchAnalytics = async (id) => {
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/feedback/getFeedbackTicketAnalytics`
      const res = await axios.get(url)
      console.log(res)
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.data?.data?.totalTickets,
        },
      })
      setAnalytics(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 }, //reset the page to 1
      filters: {
        [dataIndex]: selectedKeys,
      },
    })
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={
            dataIndex === "nextFollowUp" ? "YYYY-MM-DD" : `Search ${dataIndex}`
          }
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
  })

  const onFinishSendFeedback =async(values,cate)=>{
    console.log(values);
    setLoading(true)
    try {
      const body = {
        weekId:cate,
        isStar: values.isTaskStar,
        feedback: values.feedback,
        isStudentStar : values.isStarStudent, 
        isCertificateHold : values.isCertificate
      }
      const url = `${constants.NEW_DOMAIN}/api/v1/feedback/updateFeedbackTicketById/${singleFeedback._id}`
      const res = await axios.post(url, body)
      messageApi.open({
        type: "success",
        content: "Saved successfully",
      })
      setIsModalOpen(false)
      setLoading(false)
      fetchAnalytics()
      fetchFeedback()
    } catch (error) {
      setLoading(false)
      console.log(error)
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      console.log(error.response.data.message)
      setIsModalOpen(false)
    }
  }


  const CourseTab=({cate})=>{
    
    
    const fetchTask = ()=>{
      const index = singleFeedback.enrollmentId.companyId.tasks[singleFeedback.categoryCode].findIndex((obj) => obj.id == cate.match(/\d+/)[0])
      if (index >=0){
        window.open(singleFeedback.enrollmentId.companyId.tasks[singleFeedback.categoryCode][index].presentationLink,'_blank')
      }
    }
    return <>
        <Space gap="small" align="center">
          <Button type="primary" onClick={fetchTask}>
            Task
          </Button>
          <Button onClick={()=>{
            window.open(singleFeedback.enrollmentId.progress.internship[cate].url)
          }}>Submission</Button>
        </Space>
        <br/>
        <p>Submitted On : {new Date(singleFeedback.enrollmentId.progress.internship[cate].createdAt).toDateString()}</p> <br/>
        { singleFeedback.enrollmentId.progress.internship[cate].feedback.length > -1 &&  <Timeline
    items={Object.entries(singleFeedback.enrollmentId.progress.internship[cate].feedback).map(([key,value])=>{
      return  {
        color: 'green',
        children: <>
        <p>{value?.text}</p>
        <p>{new Date(value?.createdAt).toDateString()}</p>
      </>,
      }
     })}
  /> }
  {!singleFeedback.enrollmentId.progress.internship[cate].isReviewed&& cate === singleFeedback.weekId &&
  <Form
              name="dynamic_form_nest_item"
              form={form}
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              onFinish={(values) => onFinishSendFeedback(values, cate)}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
               <Form.Item
                name="weekId"
                label="WeekId"
                hidden
                initialValue={cate}
                rules={[
                  {
                    required: true,
                    message: "Program is required!",
                  },
                ]}
              >
                <Input  value={cate}  />
              </Form.Item>
              
              <Form.Item
                name="feedback"
                label="Feedback"
                rules={[
                  {
                    required: true,
                    message: "Program is required!",
                  },
                ]}
              >
                <Input.TextArea  />
              </Form.Item>
              <Form.Item name="isTaskStar" label="Star Task"  valuePropName="checked">
                <Switch />
              </Form.Item>
              <Form.Item  name="isCertificate" label="Certificate Hold"  valuePropName="checked">
                <Switch  />
              </Form.Item>
              <Form.Item name="isStarStudent" label="Star Student"  valuePropName="checked">
                <Switch />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 4,
                  span: 16,
                }}
              >
    <Popconfirm
      title="Are you Sure you want to send Email to Student ?"
      description="This action will be irreversible..."
      placement="top"
      onConfirm={()=>{
        form.submit()
      }}
      icon={
        <QuestionCircleOutlined
          style={{
            color: 'red',
          }}
        />

      }
    >
      <Button type="primary" block htmlType="button">
        Give FeedBack
      </Button>
    </Popconfirm>
  </Form.Item>
            </Form>}
    </>
  }
  const onSelect = (data) => {
    console.log("onSelect", data)
  }
  const columns = [
    {
      title: "userId",
      dataIndex: "userId",
      width: 215,
      sorter: false,
      render: (data, record) => {
        return (
          <a>
            {data}
          </a>
        )
      },
      ...getColumnSearchProps("userId"),
    },
    {
      title: "Company",
      dataIndex: "companyName",
      width: 215,
      sorter: false,
      render: (data, record) => {
        return (
          <>
            {data}
          </>
        )
      },
      ...getColumnSearchProps("companyName"),
    },
    {
      title: "Week",
      dataIndex: "weekId",
      width: 120,
      sorter: false,
      //   fixed: "left
      render: (data, record) => (
        <>
          {data === "week1" ? (
            <>
              <Tag color={"volcano"}>{data}</Tag>
            </>
          ) : data === "week2" ? (
            <>
              <Tag color={"orange-inverse"}>{data}</Tag>
            </>
          ) : data === "week3" ? (
            <>
              <Tag color={"green-inverse"}>{data}</Tag>
            </>
          ) : data === "week4" ? (
            <>
              <Tag color={"purple-inverse"}>{data}</Tag>
            </>
          ) : (
            <>{data}</>
          )}
        </>
      ),
      ...getColumnSearchProps("weekId"),
    },
    {
      title: "Category",
      dataIndex: "categoryCode",
      sorter: false,
      width: 150,
      render: (data, record) => (
        <>
        
            <><Tag color={""} style={{font:"bold"}}>{data}</Tag></>
        </>
      ),
      ...getColumnSearchProps("categoryCode"),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: false,
      width: 150,
      render: (data, record) => (
        <>
        
            <><Tag color={"orange"} style={{font:"bold"}}>{data}</Tag></>
        </>
      ),
      ...getColumnSearchProps("status"),
    },
    {
      title: "Task Submission Status",
      dataIndex: "submissionDateOffset",
      sorter: false,
      width: 150,
      render: (data, record) => (
        <>
        
            <>{data > 0 ? <Tag color={"green"} style={{font:"bold"}}>On Time</Tag> : <Tag color={"red"} style={{font:"bold"}}>Delayed</Tag>}</><br/>
            {data > 0 ? <div style={{color:"lightgray",marginTop:"7px"}}> {Math.round(data / (1000 * 60 * 60 * 24))} Days Early</div> : <div style={{color:"lightgray",marginTop:"7px"}}> {Math.round(data / (1000 * 60 * 60 * 24))} Days Late</div>}
             
        </>
      ),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 120,
      render: (_, record) => (
        <>
          <Space direction="horizontal" size={"small"}>
           
           
            {/* <Popconfirm
    title="Are you Sure you want to send Email to School ?"
    description="This action will be irreversible..."
    placement="left"
    onConfirm={()=>{
      sendb2bOnboardingReport(record._id)
    }}
    icon={
      <QuestionCircleOutlined
        style={{
          color: 'red',
        }}
      />
    }
  > */}
            <Tooltip placement="left" title="Send Feedback">
              <Button
                type="text"
                shape="circle"
                onClick={()=>{
                  fetchFeedbackById(record._id)}}
                icon={<NodeIndexOutlined  style={{ color: "#971eff" }} />}
              />
            </Tooltip>
            {/* </Popconfirm> */}
          
          </Space>
        </>
      ),
    },
  ]

  const renderHeader = () => {
    return (
      <Space size={16}>
        <Button
          type="default"
          icon={<ScissorOutlined />}
          onClick={() => {
            sessionStorage.clear();
            setTableParams({ ...tableParams, filters: {} })
          }}
          loading={loading}
        >
          Clear Filters
        </Button>
        <Button
          type="default"
          icon={<ReloadOutlined />}
          onClick={() => fetchFeedback()}
          loading={loading}
        >
          Reload
        </Button>
      </Space>
    )
  }
  const handleTableChange = (pagination, filters, sorter, extra) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    })

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setFeeback([])
    }
  }



  if (loading) {
    return <Loader />
  }


  return (
    <div style={{ backgroundColor: "#fff", padding: "20px" }}>
      {contextHolder}

      {singleFeedback && (
        <Modal
          title={
           'Send FeedBack'
          }
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          okButtonProps={{
            display: false,
          }}
          footer={[]}
          style={{ maxWidth: isMobile ? "350px" : "500px", fontSize: "20px" }}
        >
          {modalLoading ? (
            <LoadingOutlined
              width={"50px"}
              style={{
                marginLeft: "43%",
                fontSize: "30px",
                marginTop: "10%",
              }}
            />
          ) : (
            <>
            <Tabs centered id="internship" 
               defaultActiveKey={singleFeedback.weekId}
    items={Object.entries(singleFeedback?.enrollmentId?.progress?.internship).map(([key,value])=>{
     return  {
      label: key,
      key: key,
      children:  <CourseTab cate={key}/>,
    }
    })} />
            </>
          )}
        </Modal>
      )}

      <Row>
        <Col xs={24} md={16}>
          <div className="text-3xl">Feedback Tickets</div>
          <p className="text-lg-light">
            {new Date().toDateString()} - {new Date().toLocaleTimeString()}
          </p>
        </Col>

        <Col xs={24} md={8}>
        {analytics?.weeklyStats.map((stat, index) => {
          return <Popover placement="leftBottom"  content={<div>
          <h2 style={{color:"#971eff"}}>{stat._id.toString().toUpperCase()}</h2>
        <>
        <Row key={index} gutter={16}>
          <Col span={8}>
            <Card bordered={true}>
              <Statistic
                title={`Total Tickets`}
                value={stat.totalTickets}
                valueStyle={{ color: '#3f8600' }}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={true}>
              <Statistic
                title="Open Tickets"
                value={stat.openTickets}
                valueStyle={{ color: '#3f8600' }}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={true}>
              <Statistic
                title="Reopened Tickets"
                value={stat.resubmittedTickets}
                valueStyle={{ color: '#cf1322' }}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={true}>
              <Statistic
                title="Hold"
                value={stat.certificateHold}
                valueStyle={{ color: '#971eff' }}
              />
            </Card>
          </Col>
        </Row></>
          </div>}>
          <Tag color="green" >{stat._id.toString().toUpperCase()}</Tag>
          </Popover>})}
        <div style={{display:"flex", gap:"10px",marginTop:"10px"}}>
            <Card bordered={true}>
            <Statistic
              title="Total Tickets"
              value={analytics?.totalTickets}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
          <Card bordered={true}>
            <Statistic
              title="Open Tickets"
              value={analytics?.openTickets}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
          <Card bordered={true}>
            <Statistic
              title="Reopened Tickets"
              value={analytics?.resubmittedTickets}
              valueStyle={{ color: '#cf1322' }}
            />
          </Card>
          <Card bordered={true}>
            <Statistic
              title="Hold"
              value={analytics?.certificateHold}
              valueStyle={{ color: '#cf1322' }}
            />
          </Card>
          
          </div>
          
        </Col>
      </Row>

      <Table
        columns={columns}
        loading={tableLoading}
        title={renderHeader}
        size="medium"
        rowClassName="row"
        dataSource={feedback}
        onChange={handleTableChange}
        pagination={tableParams.pagination}
        rowKey={(record) => record._id}
        scroll={{
          x: 900,
          y: 500,
        }}
      />
    </div>
  )
}
export default Feedback_V1

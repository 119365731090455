import React, { useEffect, useState, useRef } from "react"
import {
  Space,
  Table,
  Tag,
  Button,
  Input,
  Row,
  Col,
  Statistic,
  Tooltip,
  Modal,
  Alert,
  message,
  Divider,
  theme,
  Drawer,
  Card,
  Avatar,
  List,
  Typography,
  FloatButton,
} from "antd"
import {
  SearchOutlined,
  ScissorOutlined,
  ReloadOutlined,
  ExportOutlined,
  EditOutlined,
  UserOutlined,
  WhatsAppOutlined,
  ContainerTwoTone,
  StopOutlined,
  StopTwoTone,
} from "@ant-design/icons"
import axios from "axios"
import excelJS from "exceljs"
import constants from "./constants"
import * as FileSaver from "file-saver"
import XLXS from "sheetjs-style"
import { Form, Select } from "antd"
import { useAuth } from "./contexts/authContext"
import useView from "./hooks/useView"
const { Option } = Select
const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 16,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 5,
    span: 16,
  },
}
const OverallUser = () => {
  const [data, setData] = useState()
  const [poc, setPoc] = useState([])
  const [user, setUser] = useState() //enrollments
  const [program, setProgram] = useState() //Program
  const [loading, setLoading] = useState(false)
  const searchInput = useRef(null)
  const { Text } = Typography
  const { isMobile } = useView()
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      position: ["topCenter"],
    },
    filters: {},
  })
  const { currentUser,roles } = useAuth()
  const isAdmin = roles.includes("ADMIN")
  const [link, setLink] = useState("")
  const [messageApi, contextHolder] = message.useMessage()
  const [edit, editParams] = useState({
    id: "",
    name: "test",
    categoryCode: "test",
    batchId: "test",
    enrollId: "test",
    amount: 0,
  })
  const fetchPOC = async () => {
    try {
      // setStatus({ loading: true, error: false, success: false })
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/systemuser`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      console.log(res)
      setPoc(res.data.doc)
      // setStatus({ loading: false, error: false, success: false })
    } catch (error) {
      console.log(error.response)
      // setStatus({
      //   loading: false,
      //   error: "Something went wrong",
      //   success: false,
      // })
    }
  }
  const [error, setError] = useState(false) // for drawer loading
  const [status, setStatus] = useState(false)
  const { Meta } = Card
  const { token } = theme.useToken()
  const [open, setOpen] = useState(false)
  const [childrenDrawer, setChildrenDrawer] = useState(false)
  const showChildrenDrawer = async (id) => {
    try {
      // const url = `${constants.DOMAIN}/api/v1/admin/loadAllEnrollments/${record._id}`
      const url = `${constants.NEW_DOMAIN}/api/v1/user/fullReport/${id}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setProgram(res.data.data)
      // setStatus(false)
      setError(false)
      console.log(res.data)
      // console.log(data);
      // if(res.data.data?.user?.userType==="CLUB_MEMBER"&&res.data.data.user?.profile?.city === undefined){
      //   setError(true)
      // }else{
      //  setError(false)
      // }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      // setStatus(true)
      console.log(error)
    }
    setChildrenDrawer(true)
  }
  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false)
  }
  const showDrawer = async (record) => {
    console.log(record)
    setOpen(true)
    setError(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/loadAllEnrollments/${record._id}`
      // const url = `${constants.DOMAIN}/api/v1/users/fullReport/${record._id}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setUser(res.data.data)
      setStatus(false)
      setError(false)
      console.log(res.data)
      // console.log(data);
      // if(res.data.data?.user?.userType==="CLUB_MEMBER"&&res.data.data.user?.profile?.city === undefined){
      //   setError(true)
      // }else{
      //  setError(false)
      // }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      setStatus(true)
      console.log(error)
    }
  }
  const onClose = () => {
    setOpen(false)
  }
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const [form] = Form.useForm()
  const [form1] = Form.useForm()
  const [form2] = Form.useForm()
  const [form3] = Form.useForm()
  const [form4] = Form.useForm()
  const onReset = () => {
    form.resetFields()
    console.log(form)

    form1.resetFields()
    form2.resetFields()
  }

  const onFinish = async (values) => {
    console.log(values)
    if (link === "link") {
      if (values.Batch == undefined && values.Phone == undefined) {
        alert("please enter some data")
        return
      } else if (values.Batch == "" && values.Phone == "") {
        alert("please enter some data")
        return
      }

      setLoading(true)
      try {
        const body = {}
        if (values.Batch == undefined || values.Batch == "") {
          body.phone = values.Phone
        } else {
          body.batchCode = values.Batch
        }
        console.log(body)

        const url = `${constants.NEW_DOMAIN}/api/v1/batch/sendSignupLinkToBatch`
        const res = await axios.post(url, body, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
          }
        })
        // console.log(res)
        messageApi.open({
          type: "success",
          content: "Message sent successfully",
        })
        setLoading(false)
      } catch (error) {
        setLoading(false)
        messageApi.open({
          type: "error",
          content: error.response.data.message || "Something went wrong",
        })
        //  console.log(error.response.data.message);
      }
    } else {
      setLoading(true)
      try {
        const url = `${constants.NEW_DOMAIN}/api/v1/admin/updateSlotAndCategory`
        const body = {
          userId: edit.enrollId,
          categoryCode:
            values.Category == undefined || values.Category == ""
              ? edit.categoryCode
              : values.Category,
          batchCode:
            values.Batch == undefined || values.Batch == ""
              ? edit.batchId
              : values.Batch,
          amount: values.Amount,
          admin:isAdmin,
          plan: values.plan,
        }
        const res = await axios.post(url, body, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
          }
        })

        // console.log(res)
        messageApi.open({
          type: "success",
          content: "User Updated successfully",
        })
        setLoading(false)
      } catch (error) {
        setLoading(false)
        messageApi.open({
          type: "error",
          content: error.response.data.message || "Something went wrong",
        })
        // console.log(error.response.data.message);
      }
    }
  }
  const onFinishChangeEmail = async (values) => {
    console.log(values)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/reset-user`
      const body = {
        userId: edit.id,
        reset: "email",
        email: values.Email,
      }
      const res = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })

      // console.log(res)
      messageApi.open({
        type: "success",
        content: "Email Updated successfully",
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      console.log(error.response.data.message)
    }
  }
  const onFinishChangePhone = async (values) => {
    console.log(values)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/reset-user`
      const body = {
        userId: edit.id,
        reset: "phone",
        phone: values.Phone,
      }
      const res = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })

      // console.log(res)
      messageApi.open({
        type: "success",
        content: "Phone Updated successfully",
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      console.log(error.response.data.message)
    }
  }
  const onFinishChangePOC = async (values) => {
    console.log(values)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/updatepoc`
      const body = {
        userId: edit.enrollId,
        poc: values.poc,
      }
      const res = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })

      // console.log(res)
      messageApi.open({
        type: "success",
        content: "POC Updated successfully",
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      console.log(error.response.data.message)
    }
  }
  const exportExcel = async (data, categories, code) => {
    const workbookBatch = new excelJS.Workbook();

    for (const category of categories) {
      const worksheet = workbookBatch.addWorksheet(category);
      worksheet.columns = [
        { header: "Name", key: "name", width: 20 },
        { header: "Email", key: "email", width: 30 },
        { header: "Phone", key: "phone", width: 15 },
      ];
  
      const categoryData = data[`user${category}`] || [];
      for (const entry of categoryData) {
        worksheet.addRow({
          name: entry?.userId?.name,
          email: entry?.userId?.email,
          phone: entry?.userId?.phone,
        });
      }
    }
  
    const buffer = await workbookBatch.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
  
    FileSaver.saveAs(blob, `Batch_Users(${code}).xlsx`);
  };

  const onFinishExportUser = async (values) => {
    setLoading(true)
    console.log(values)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/batch/user/${values.batchCode}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      const { data, categories } = res.data;
      exportExcel(data, categories, values.batchCode);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      messageApi.open({
        type: "error",
        content: error.response?.data.message || "Something went wrong",
      })
      console.log(error.response?.data.message)
    }
  }
  const resetTest = async (resetType) => {
    console.log(resetType)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/reset-user`
      const body = {
        userId: edit.enrollId,
        reset: resetType,
      }
      const res = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })

      // console.log(res)
      messageApi.open({
        type: "success",
        content:
          resetType === "toMCQ"
            ? "Test Reset to MCQ Successfully"
            : resetType ==="toPortfolio"?"Test Reset to Portfolio Successfully":
            "Internship Resetted Successfully",
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      console.log(error.response.data.message)
    }
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 }, //reset the page to 1
      filters: {
        [dataIndex]: selectedKeys,
      },
    })
  }

  const handleReset = (clearFilters) => {
    clearFilters()
  }
  const exportData = async () => {
    const docs = await fetchData(true)
    const processData = docs.map((data) => {
      return {
        Id: data?._id,
        Name: data?.name,
        Phone: data?.phone,
        Email: data?.email,
        Slot: data?.batchId?.batchId,
        Category: data?.categoryCode,
      }
    })
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    const fileExtension = ".xlsx"

    const ws = XLXS.utils.json_to_sheet(processData)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLXS.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, "export-data" + fileExtension)
  }

  const renderHeader = () => {
    return (
      <div className="header_responsive">
        <Space size={16}>
          <Button
            type="default"
            icon={<ScissorOutlined />}
            onClick={() => setTableParams({ ...tableParams, filters: {} })}
            loading={loading}
          >
            Clear Filters
          </Button>
          <Button
            type="default"
            icon={<ReloadOutlined />}
            onClick={() => fetchData()}
            loading={loading}
          >
            Reload
          </Button>
          <Button
            type="default"
            icon={<ExportOutlined />}
            onClick={() => exportData(data)}
            loading={loading}
          >
            Export
          </Button>
          <Button
            type="default"
            icon={<WhatsAppOutlined style={{ color: "#45C153" }} />}
            onClick={() => openModel("", "send")}
            loading={loading}
          >
            Send Registration Link
          </Button>
        </Space>
      </div>
    )
  }
  const openModel = (record, type) => {
    console.log(type)
    onReset()
    // setError({
    //   visible: false,
    //   type: "",
    //   message: "",
    // })
    if (type == "general") {
      setLink(type)
      editParams({
        id: record?._id,
        name: record?.name,
        categoryCode: record?.categoryCode,
        batchId: record?.batchId?.batchId,
        enrollId: "",
      })
    } else {
      if (type == "edit") {
        setLink(type)
        editParams({
          id: record?.userId?._id,
          name: record?.userId?.name,
          categoryCode: record?.batchId?.categoryCode,
          batchId: record?.batchId?.batchId,
          enrollId: record._id,
        })
        console.log(edit)
        console.log(record)
      } else {
        setLink("link")
      }
    }
    // console.log(record)
    showModal()
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        hello {confirm}
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
  })
  const renderStatus = (_, record) => {
    if (record?.profile != undefined) return <UserOutlined />
  }

  const columns = [
    {
      title: "",
      width: 50,
      render: (_, record) => {
        return <Space>{renderStatus(_, record)}</Space>
      },
    },
    // {
    //   title: "Payment Status",
    //   dataIndex: "paymentStatus",
    //   sorter: false,
    //   // render: (date) => (
    //   //   <>
    //   //     {new Date(date).toLocaleDateString()}
    //   //     <br />
    //   //     <div style={{ color: "lightgray" }}>
    //   //       {new Date(date).toLocaleTimeString()}
    //   //     </div>
    //   //   </>
    //   // ),
    // },
    {
      title: "User Type",
      dataIndex: "userType",
      sorter: false,
      filters: [
        {
          text: "B2B",
          value: "CLUB_MEMBER",
        },
        {
          text: "B2C",
          value: "SIP",
        },
      ],
      render: (userType) => (
        <>
          {userType === "CLUB_MEMBER" ? (
            <Tag color={"#971eff"}>B2B</Tag>
          ) : (
            <Tag color={"#ae5d04"}>B2C</Tag>
          )}
        </>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      render: (phone) => <a>{phone}</a>,
      ...getColumnSearchProps("phone"),
    },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
    },
    // {
    //   title: "Slot",
    //   dataIndex: "batchId",
    //   render: (batch) => {
    //     return (
    //       <div>
    //         {batch?.batchId}
    //         <br />
    //         <div style={{ color: "lightGray" }}>{batch?._id}</div>
    //       </div>
    //     )
    //   },
    //   ...getColumnSearchProps("batchId"),
    // },
    // {
    //   title: "Category",
    //   dataIndex: "categoryCode",
    //   render: (code) => {
    //     let color =
    //       code === "SMM"
    //         ? "#B2A4FF"
    //         : code === "VE"
    //         ? "#E11299"
    //         : code === "CW"
    //         ? "#867070"
    //         : "#E4D0D0"
    //     return <Tag color={color}>{code}</Tag>
    //   },
    // },
    isMobile
      ? {
          width: 0,
        }
      : {
          title: "DOR",
          dataIndex: "createdAt",
          sorter: false,
          render: (date) => (
            <>
              {new Date(date).toLocaleDateString()}
              <br />
              <div style={{ color: "lightgray" }}>
                {new Date(date).toLocaleTimeString()}
              </div>
            </>
          ),
        },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Space style={{ margin: "30px 0" }}>
          <Tooltip title="edit">
            <Button
              type="link"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => openModel(record, "general")}
            />
          </Tooltip>
          <Tooltip title="Fetch User Details">
            <Button
              type="link"
              shape="circle"
              icon={<ContainerTwoTone />}
              onClick={() => showDrawer(record)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ]

  const fetchData = async (isExport = false) => {
    setLoading(true)
    try {
      // let query = []
      // Object.keys(tableParams.filters).forEach((k) => {
      //   if (
      //     tableParams.filters[k] !== null &&
      //     tableParams.filters[k].length > 0
      //   ) {
      //     query.push(`${k}=${tableParams.filters[k]}`)
      //   }
      // })
      //   name={$regex:^fin,$options:'i'}&
      let query = []
      console.log(tableParams)
      Object.keys(tableParams.filters).forEach((k) => {
        if (
          tableParams.filters[k] !== null &&
          tableParams.filters[k].length > 0
        ) {
          // query.push(`${k}=${tableParams.filters[k]}`)
          if (k === "name") {
            query.push(`${k}:sw=${tableParams.filters[k]}`)
          } else {
            query.push(`${k}=${tableParams.filters[k]}`)
          }
        }
      })
      const preQuery = isExport
        ? `page=${tableParams.pagination.current}&limit=1000&firebaseId=${currentUser._delegate.uid}&`
        : `page=${tableParams.pagination.current}&limit=${tableParams.pagination.pageSize}&firebaseId=${currentUser._delegate.uid}&paymentStatus=PAID&`
      const q = preQuery + query.join("&")

      const url = `${constants.NEW_DOMAIN}/api/v1/admin/paid-users?${q}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      if (isExport) {
        setLoading(false)
        return res.data.data.users
      }
      setData(res.data.data.users)
      setLoading(false)
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.data.results,
        },
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  
  useEffect(() => {
    fetchData()
    fetchPOC()
  }, [JSON.stringify(tableParams)])

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    })

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([])
    }
  }

  return (
    <>
      {contextHolder}
      <div style={{ backgroundColor: "#fff", padding: "20px" }}>
        {/* { error.visible?
      <Alert
      message={error.type}
      description={error.message}
      type={error.type}
      showIcon
      
    />:""} */}
        <Drawer
          title="User Details"
          placement="right"
          onClose={onClose}
          open={open}
          width={420}
        >
          {error ? (
            ""
          ) : (
            <Text ellipsis={true}>
              <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />{" "}
              {user?.user?.name}
            </Text>
          )}
          {status ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "300px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <p>
                  <StopTwoTone
                    style={{ fontSize: "30px" }}
                    twoToneColor={"red"}
                  />
                </p>
                <br />
                <p>NO JOURNEY FOUND</p>
              </div>
            </>
          ) : (
            <>
              {user?.user?.userType != "CLUB_MEMBER" ? (
                <Card
                  style={{
                    marginTop: 16,
                  }}
                  loading={error}
                  type="inner"
                  title="Basic Info"
                >
                  <List
                    size="small"
                    // header={<div>Header</div>}
                    // footer={<div>Footer</div>}
                    // bordered
                    dataSource={[
                      <Text ellipsis={true}>
                        School : {user?.user?.profile?.school}
                      </Text>,
                      <Text ellipsis={true}>
                        City : {user?.user?.profile?.city}
                      </Text>,
                      <Text ellipsis={true}>
                        Resume :{" "}
                        {user?.user?.resume != undefined ? (
                          <Tag color={"green"}>Created</Tag>
                        ) : (
                          <Tag color={"red"}>pending</Tag>
                        )}
                      </Text>,
                    ]}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                  />
                </Card>
              ) : (
                <Card
                  style={{
                    marginTop: 16,
                  }}
                  loading={error}
                  type="inner"
                  title="Basic Info"
                >
                  <List
                    size="small"
                    // header={<div>Header</div>}
                    // footer={<div>Footer</div>}
                    // bordered
                    dataSource={[
                      <Text ellipsis={true}>
                        School : {user?.user?.schoolId?.name}
                      </Text>,
                      <Text ellipsis={true}>
                        City : {user?.user?.schoolId?.city}
                      </Text>,
                      <Text ellipsis={true}>
                        Resume :{" "}
                        {user?.user?.resume != undefined ? (
                          <Tag color={"green"}>Created</Tag>
                        ) : (
                          <Tag color={"red"}>pending</Tag>
                        )}
                      </Text>,
                    ]}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                  />
                </Card>
              )}
              {user?.enrollments.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "300px",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <p>
                    <StopTwoTone
                      style={{ fontSize: "30px" }}
                      twoToneColor={"red"}
                    />
                  </p>
                  <br />
                  <p>No Program Found</p>
                </div>
              ) : (
                <>
                  {user?.enrollments &&
                    user?.enrollments.map((enroll, key) => {
                      return (
                        <Card
                          style={{
                            marginTop: 16,
                          }}
                          loading={error}
                          type="inner"
                          title={"Program " + ++key}
                          key={key}
                        >
                          <List
                            size="small"
                            // header={<div>Header</div>}
                            // footer={<div>Footer</div>}
                            // bordered
                            dataSource={[
                              <Text ellipsis={true}>
                                Category :{" "}
                                {
                                  <Tag
                                    color={
                                      enroll?.batchId?.categoryCode === "SMM"
                                        ? "#B2A4FF"
                                        : enroll?.batchId?.categoryCode === "VE"
                                        ? "#E11299"
                                        : enroll?.batchId?.categoryCode === "CW"
                                        ? "#867070"
                                        : "#E4D0D0"
                                    }
                                  >
                                    {enroll?.batchId?.categoryCode}
                                  </Tag>
                                }
                              </Text>,
                              <Text ellipsis={true}>
                                Batch Id : {enroll?.batchId?.batchId}
                              </Text>,
                               <Text ellipsis={true}>
                                Plan : {enroll?.plan?.split("_")[1] === "RECORDED"?<Tag color={"orange-inverse"}>RECORDED</Tag>:<Tag color={"purple-inverse"}>HYBRID</Tag>}
                              </Text>,
                              <Text ellipsis={true}>
                                <Button
                                  type="dashed"
                                  onClick={() => openModel(enroll, "edit")}
                                >
                                  Edit
                                </Button>
                                <Button
                                  type="dashed"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => showChildrenDrawer(enroll._id)}
                                >
                                  Fetch Program Details
                                </Button>
                              </Text>,
                            ]}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                          />
                        </Card>
                      )
                    })}
                </>
              )}
              <Drawer
                title="Program Details"
                width={420}
                // closable={false}
                onClose={onChildrenDrawerClose}
                open={childrenDrawer}
              >
                {new Date(
                  program?.enroll?.batchId?.trainingStartDate
                ).getTime() < new Date().getTime() ? (
                  <>
                    {" "}
                    <Card
                      style={{
                        marginTop: 16,
                      }}
                      loading={error}
                      type="inner"
                      title="Training & Assessment Info"
                    >
                      <List
                        size="small"
                        // header={<div>Header</div>}
                        // footer={<div>Footer</div>}
                        // bordered
                        dataSource={[
                          <Text ellipsis={true}>
                            Challenge :{" "}
                            {program?.enroll?.progress?.assessment.status ===
                            "DONE" ? (
                              <Tag color={"green"}>Completed</Tag>
                            ) : (
                              <Tag color={"red"}>pending</Tag>
                            )}
                          </Text>,
                          <Text ellipsis={true}>
                            MCQ : {program?.enroll?.progress?.assessment.mcq}
                          </Text>,

                          <Text ellipsis={true}>
                            {program?.enroll?.progress?.assessment
                              ?.portfolio === undefined ? (
                              ""
                            ) : (
                              <a
                                href={
                                  program?.enroll?.progress?.assessment
                                    ?.portfolio
                                }
                                target="blank"
                              >
                                <Button type="dashed">View Portfolio</Button>
                              </a>
                            )}
                            <a
                              href={`https://youvah.com/certificate/training/${program?.enroll?._id}?ADMIN=SHOW`}
                              target="blank"
                            >
                              <Button
                                type="dashed"
                                style={{ marginLeft: "10px" }}
                              >
                                Get Certificate
                              </Button>
                            </a>
                          </Text>,
                        ]}
                        renderItem={(item) => <List.Item>{item}</List.Item>}
                      />
                    </Card>
                    {new Date(
                      program?.enroll?.internshipSlotId?.startDate
                    ).getTime() < new Date().getTime() ? (
                      <Card
                        style={{
                          marginTop: 16,
                        }}
                        loading={error}
                        type="inner"
                        title="Internship Info"
                      >
                        <List
                          size="small"
                          // header={<div>Header</div>}
                          // footer={<div>Footer</div>}
                          // bordered
                          dataSource={[
                            <Text ellipsis={true}>
                              {" "}
                              Internship Slot :{" "}
                              {program?.enroll?.internshipSlotId?.slotId !=
                              undefined ? (
                                `${program?.enroll?.internshipSlotId?.slotId}`
                              ) : (
                                <Tag color={"red"}>Not Selected</Tag>
                              )}{" "}
                            </Text>,
                            <Text ellipsis={true}>
                              Company Name :{" "}
                              {program?.enroll?.companyId?.name != undefined ? (
                                `${program?.enroll?.companyId?.name}`
                              ) : (
                                <Tag color={"red"}>pending</Tag>
                              )}{" "}
                            </Text>,
                            <Text>
                         <a
                              href={`https://youvah.com/certificate/internship/${program?.enroll?._id}?ADMIN=SHOW`}
                              target="blank"
                            >
                              <Button
                                type="dashed"
                                style={{ marginLeft: "10px" }}
                              >
                                Get Intern Certificate
                              </Button>
                            </a>
                            </Text>,
                            <Text ellipsis={true}>
                              Status :{" "}
                              {new Date(
                                program?.enroll?.internshipSlotId?.timeLine[3]?.endDate
                              ).getTime() < new Date().getTime() ? (
                                <Tag color={"green"}>Completed</Tag>
                              ) : (
                                <Tag color={"red"}>pending</Tag>
                              )}
                            </Text>,

                            //  <Text ellipsis={true}>
                            //  <div style={{ display: "flex", flexDirection: "row" }}> Task :
                            //       {program?.enroll?.progress?.internship?.week1 === undefined ? (
                            //         ""
                            //       ) : (
                            //         <a href={program?.enroll?.progress?.internship?.week1} target="blank">
                            //           <Button type="dashed">1</Button>
                            //         </a>
                            //       )}
                            //       {program?.enroll?.progress?.internship?.week2 === undefined ? (
                            //         ""
                            //       ) : (
                            //         <a
                            //           href={program?.enroll?.progress?.internship?.week2}
                            //           style={{ marginLeft: "5px" }}
                            //           target="blank"
                            //         >
                            //           <Button type="dashed">2</Button>
                            //         </a>
                            //       )}
                            //       {program?.enroll?.progress?.internship?.week3 === undefined ? (
                            //         ""
                            //       ) : (
                            //         <a
                            //           href={program?.enroll?.progress?.internship?.week3}
                            //           style={{ marginLeft: "5px" }}
                            //           target="blank"
                            //         >
                            //           <Button type="dashed">3</Button>
                            //         </a>
                            //       )}
                            //       {program?.enroll?.progress?.internship?.week4 === undefined ? (
                            //         ""
                            //       ) : (
                            //         <a
                            //           href={program?.enroll?.progress?.internship?.week4}
                            //           style={{ marginLeft: "5px" }}
                            //           target="blank"
                            //         >
                            //           <Button type="dashed">4</Button>
                            //         </a>
                            //       )}
                            //     </div>
                            //  </Text>,
                            <Text ellipsis={true}>
                              {" "}
                              Task 1 :{" "}
                              {program?.enroll?.progress?.internship?.week1 !==
                              undefined ? (
                                <>
                                  <a
                                    href={
                                      program?.enroll?.progress?.internship
                                        ?.week1.url
                                    }
                                    target="blank"
                                  >
                                    <Button type="dashed">View Task</Button>
                                  </a>
                                  <br></br>
                                  <div
                                    style={{
                                      color: "lightgray",
                                      marginTop: "5px",
                                      marginLeft: "50px",
                                    }}
                                  >
                                    Submitted on :{" "}
                                    {new Date(
                                      program?.enroll?.progress?.internship?.week1.createdAt
                                    ).toDateString()}
                                    <br />
                                    Updated on :{" "}
                                    {new Date(
                                      program?.enroll?.progress?.internship?.week1.createdAt
                                    ).toDateString()}
                                  </div>
                                </>
                              ) : (
                                <Tag color={"red"}>pending</Tag>
                              )}{" "}
                            </Text>,
                            <Text ellipsis={true}>
                              {" "}
                              Task 2 :{" "}
                              {program?.enroll?.progress?.internship?.week2 !==
                              undefined ? (
                                <>
                                  <a
                                    href={
                                      program?.enroll?.progress?.internship
                                        ?.week2.url
                                    }
                                    target="blank"
                                  >
                                    <Button type="dashed">View Task</Button>
                                  </a>
                                  <br></br>
                                  <div
                                    style={{
                                      color: "lightgray",
                                      marginTop: "5px",
                                      marginLeft: "50px",
                                    }}
                                  >
                                    Submitted on :{" "}
                                    {new Date(
                                      program?.enroll?.progress?.internship?.week2.createdAt
                                    ).toDateString()}
                                    <br />
                                    Updated on :{" "}
                                    {new Date(
                                      program?.enroll?.progress?.internship?.week2.createdAt
                                    ).toDateString()}
                                  </div>
                                </>
                              ) : (
                                <Tag color={"red"}>pending</Tag>
                              )}{" "}
                            </Text>,
                            <Text ellipsis={true}>
                              {" "}
                              Task 3 :{" "}
                              {program?.enroll?.progress?.internship?.week3 !==
                              undefined ? (
                                <>
                                  <a
                                    href={
                                      program?.enroll?.progress?.internship
                                        ?.week3.url
                                    }
                                    target="blank"
                                  >
                                    <Button type="dashed">View Task</Button>
                                  </a>
                                  <br></br>
                                  <div
                                    style={{
                                      color: "lightgray",
                                      marginTop: "5px",
                                      marginLeft: "50px",
                                    }}
                                  >
                                    Submitted on :{" "}
                                    {new Date(
                                      program?.enroll?.progress?.internship?.week3.createdAt
                                    ).toDateString()}
                                    <br />
                                    Updated on :{" "}
                                    {new Date(
                                      program?.enroll?.progress?.internship?.week3.createdAt
                                    ).toDateString()}
                                  </div>
                                </>
                              ) : (
                                <Tag color={"red"}>pending</Tag>
                              )}{" "}
                            </Text>,
                            <Text ellipsis={true}>
                              {" "}
                              Task 4 :{" "}
                              {program?.enroll?.progress?.internship?.week4 !==
                              undefined ? (
                                <>
                                  <a
                                    href={
                                      program?.enroll?.progress?.internship
                                        ?.week4.url
                                    }
                                    target="blank"
                                  >
                                    <Button type="dashed">View Task</Button>
                                  </a>
                                  <br></br>
                                  <div
                                    style={{
                                      color: "lightgray",
                                      marginTop: "5px",
                                      marginLeft: "50px",
                                    }}
                                  >
                                    Submitted on :{" "}
                                    {new Date(
                                      program?.enroll?.progress?.internship?.week4.createdAt
                                    ).toDateString()}
                                    <br />
                                    Updated on :{" "}
                                    {new Date(
                                      program?.enroll?.progress?.internship?.week4.createdAt
                                    ).toDateString()}
                                  </div>
                                </>
                              ) : (
                                <Tag color={"red"}>pending</Tag>
                              )}{" "}
                            </Text>,
                            <Text ellipsis={true}>
                              {" "}
                              Feedback :{" "}
                              {program?.enroll?.progress?.internship
                                ?.feedback != undefined ? (
                                `${program?.enroll?.progress?.internship?.feedback}`
                              ) : (
                                <Tag color={"red"}>pending</Tag>
                              )}{" "}
                            </Text>,
                          ]}
                          renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                      </Card>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            height: "300px",
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          <p>
                            <StopTwoTone
                              style={{ fontSize: "30px" }}
                              twoToneColor={"red"}
                            />
                          </p>
                          <br />
                          <p>NO INTERNSHIP DATA FOUND</p>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        height: "300px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <p>
                        <StopTwoTone
                          style={{ fontSize: "30px" }}
                          twoToneColor={"red"}
                        />
                      </p>
                      <br />
                      <p>NO TRANING AND INTERNSHIP DATA FOUND</p>
                    </div>
                  </>
                )}
              </Drawer>
            </>
          )}
        </Drawer>
        <Modal
          title={link ? "Enter Registration Link Details" : "Edit User Details"}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[]}
        >
          {/* 
        <p>{edit.batchId}</p>
        <p>{edit.categoryCode}</p> */}
          {link === "link" ? "" : <p>Name : {edit.name}</p>}
          {link === "general" ? (
            ""
          ) : (
            <Form
              {...layout}
              form={form}
              name="control-hooks"
              onFinish={onFinish}
              style={{
                maxWidth: 600,
              }}
            >
              {link === "link" ? (
                <Form.Item
                  name="Phone"
                  label="Phone"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : (
                <>
                <Form.Item
                  name="Category"
                  label="Category"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input placeholder={edit?.categoryCode} />
                </Form.Item>
                 {isAdmin&&<Form.Item
                  name="Amount"
                  label="Amount"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input  />
                </Form.Item>}
               {isAdmin&& <Form.Item
                  name="plan"
                  label="plan"
                  rules={[
                    {
                      // required: true,
                    },
                  ]}
                >
                  <Select style={{ maxWidth: "200px" }}>
                    <Option value="RECORDED">Basic</Option>
                    <Option value="HYBRID">Premium</Option>
                    <Option value="OFFLINE">Offline (Only for MASTER UNION)</Option>
                  </Select>
                </Form.Item>}
                </>
              )}
              <Form.Item
                name="Batch"
                label="Batch"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input placeholder={edit.batchId} />
              </Form.Item>
             

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  {link === "link" ? "Send" : "Update"}
                </Button>
              </Form.Item>
            </Form>
          )}

          {link === "link" ? (
            ""
          ) : link === "general" ? (
            <>
              <Divider />
              <Form
                form={form1}
                name="control-hooks"
                onFinish={onFinishChangeEmail}
                style={{
                  maxWidth: 600,
                }}
              >
                <Space.Compact block size="middle">
                  <Form.Item
                    name="Email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Update Email
                    </Button>
                  </Form.Item>
                </Space.Compact>
              </Form>
            </>
          ) : (
            ""
          )}
          {link === "link" ? (
            ""
          ) : link === "general" ? (
            <>
              <Divider />
              <Form
                form={form2}
                name="control-hooks"
                onFinish={onFinishChangePhone}
                style={{
                  maxWidth: 600,
                }}
              >
                <Space.Compact block size="middle">
                  <Form.Item
                    name="Phone"
                    label="Phone"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Update Phone
                    </Button>
                  </Form.Item>
                </Space.Compact>
              </Form>
            </>
          ) : (
            ""
          )}
          {link === "link" ? (
            ""
          ) : link === "general" ? (
            ""
          ) : (
            <>
              <Divider />
              <Space
                direction="horizontal"
                size="large"
                style={{
                  display: "flex",
                }}
              >
                <Button type="dashed" onClick={() => resetTest("toMCQ")}>
                  Reset Test to MCQ
                </Button>
                <Button type="dashed" onClick={() => resetTest("toPortfolio")}>
                  Reset Test to Portfolio
                </Button>
                <Button type="dashed" onClick={() => resetTest("toInternshipSlot")}>
                  Reset Internship
                </Button>
              </Space>
              <Divider />
              {isAdmin&&<Form
                form={form4}
                name="control-hooks"
                onFinish={onFinishChangePOC}
                style={{
                  maxWidth: 600,
                }}
              >
                <Space.Compact block size="middle">
                <Form.Item
                  name="poc"
                  label="POC"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    style={{ width: 150 }}
                    placeholder="POC"
                    onFocus={!poc.length && fetchPOC}
                    notFoundContent={<a>loading...</a>}
                  >
                    {poc?.map((data) => {
                      return <Option value={data._id}>{data.name}</Option>
                    })}
                  </Select>
                </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Update POC
                    </Button>
                  </Form.Item>
                </Space.Compact>
              </Form>}
            </>
          )}
        </Modal>

        <Row>
          <Col xs={24} md={20}>
            <div className="text-3xl">Paid Users</div>
            <p className="text-lg-light">
              {new Date().toDateString()} - {new Date().toLocaleTimeString()}
            </p>
          </Col>

          <Col xs={24} md={4}>
            {/* <Statistic
              title="Total Paid User"
              loading={loading}
              value={tableParams.pagination?.total}
              precision={0}
              valueStyle={{
                color: "blue",
              }}
              // prefix={<ArrowUpOutlined />}
              suffix="users"
            /> */}
          </Col>
        </Row>

        <Table
          columns={columns}
          loading={loading}
          title={renderHeader}
          dataSource={data}
          onChange={handleTableChange}
          pagination={tableParams.pagination}
          rowKey={(record) => record._id}
          scroll={{
            x: 900,
            y: 500,
          }}
        />
      </div>
      <FloatButton
        tooltip={
          <>
            <div>
              <h4>Export Batch User</h4>
              <Form
                form={form3}
                name="control-hooks"
                onFinish={onFinishExportUser}
                style={{
                  // maxWidth: 600,
                  color: "white",
                  background: "white",
                  borderRadius: "2px",
                  // width:"250px"
                }}
              >
                <Space.Compact block size="middle">
                  <Form.Item
                    name="batchCode"
                    label="batchCode"
                    style={{ marginTop: "10px" }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item style={{ marginTop: "10px" }}>
                    <Button type="primary" htmlType="submit">
                      Export
                    </Button>
                  </Form.Item>
                </Space.Compact>
              </Form>
            </div>
          </>
        }
      />
    </>
  )
}

export default OverallUser

import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Skeleton,
  Space,
  Switch,
  TimePicker,
  Tooltip,
  Typography,
  Select
} from "antd"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import axios from "axios"
import constants from "../../constants"
import dayjs from "dayjs"
import Loader from "../../util/loader"
import useView from "../../hooks/useView"
const { Text } = Typography
const { Option } = Select
const Batch = () => {
  const [liveDays, setLiveDays] = useState()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const { width } = useView();
  const fetchData = async () => {
    setLoading(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/batch`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setData(res.data.data.docs)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const fetchBatchById = async (id) => {
    form.resetFields()
    setLoading(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/batch/${id}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setLiveDays(res.data.data.liveDays)
      console.log(res.data?.data?.doc?.liveMetaData)
      if (res.data?.data?.doc?.liveMetaData)
        res.data.data.doc.liveMetaData = res.data.data.doc.liveMetaData.map(
          (obj) => {
            return {
              ...obj,
              date: dayjs(obj.date),
            }
          }
        )
      console.log(res.data?.data?.doc?.liveMetaData)
      form.setFieldsValue(res.data.data.doc)
      form.setFieldValue(
        "trainingStartDate",
        dayjs(res.data.data.doc.trainingStartDate)
      )
      // setBatch(res.data.data.doc)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
    form.setFieldValue("price", 999)
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    const url = `${constants.NEW_DOMAIN}/api/v1/batch/createAndUpdate`
    try {
      // const token = await getToken()
      // const headers = {
      //   // authorization: `bearer ${token}`,
      // }

      const res = await axios.post(url, values, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      // form.resetFields()
      setData((prevData) => {
        const newArr = prevData.map((category) => {
          if (category._id === res.data.data.doc._id) {
            return res.data.data.doc
          } else {
            return category
          }
        })
        return newArr
      })
      setLoading(false)
    } catch (error) {
      if (error.response) {
        setLoading(false)
        console.log(error.response.data)
        console.log(error.response.status)
      }
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div style={{ margin: "0 10px" }}>
      <Row gutter={[16, 24]}>
        <Col xs={24} md={8}>
          <Card
            title="Batches"
            extra={
              <Button onClick={() => form.resetFields()}>Add New +</Button>
            }
            style={{ maxHeight: "80vh", overflowY: "scroll" }}
          >
            {width > 800 ? <>     {data.map((c, i) => {
              return (
                <Card
                  style={{
                    marginTop: 16,
                    ...(!c.active && { backgroundColor: "beige" }),
                  }}
                  key={i}
                  type="inner"
                  title={c.batchId}
                  extra={
                    <Button
                      type="link"
                      onClick={() => {
                        console.log(c.batchId, c._id)
                        fetchBatchById(c._id)
                      }}
                    >
                      Edit
                    </Button>
                  }
                >
                  <Text ellipsis={true}>{c.description}</Text>
                </Card>
              )
            })}</> : <>
              <Select
                showSearch
                style={{
                  width: 200,
                }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={(c) => {
                  //   school.map((data,i)=>{
                  //     if(c === data.schoolId){
                  //       form.setFieldsValue(data)
                  //     }
                  // })
                  fetchBatchById(c)
                  // console.log(c);
                }}
                options={(data || []).map((d) => ({
                  value: d._id,
                  data: d,
                  label: d.batchId + " : " + d.active,
                }))}
              />
            </>}
          </Card>
        </Col>
        <Col xs={24} md={16} style={{ maxHeight: "80vh", overflowY: "scroll" }}>
          <Card title="Edit Batch">
            <Form
              name="dynamic_form_nest_item"
              form={form}
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 16,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="batchId"
                label="Batch Id"
                rules={[
                  {
                    required: true,
                    message: "batchId is required!",
                  },
                ]}
              >
                <Input value={form.getFieldValue("batchId")} />
              </Form.Item>

              <Form.Item
                name="categoryCode"
                label="Category Code"
                rules={[
                  {
                    required: true,
                    message: "categoryCode is required!",
                  },
                ]}
              >
                <Input value={form.getFieldValue("categoryCode")} />
              </Form.Item>

              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "Please provide description!",
                  },
                ]}
              >
                <Input.TextArea placeholder="description" />
              </Form.Item>

              <Form.Item
                name="trainingStartDate"
                label="Start Date"
                rules={[
                  {
                    required: true,
                    message: "Please provide training start date!",
                  },
                ]}
              >
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                // style={{
                //   width: "100%",
                // }}
                />
              </Form.Item>
              <Form.Item
                name="trainingEndDate"
                label="🔚End Date"
                rules={[
                  {
                    required: true,
                    message: "Please provide training end date!",
                  },
                ]}
              >
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                // style={{
                //   width: "100%",
                // }}
                />
              </Form.Item>
              <Form.Item name="active" label="Active?" valuePropName="checked">
                <Switch />
              </Form.Item>
              <Form.Item
                name="groupLink"
                label="WhatsApp Link"
                rules={[
                  {
                    type: "url",
                    warningOnly: true,
                  },
                ]}
              >
                <input />
              </Form.Item>
              {liveDays && (
                <Space style={{ margin: "10px 0" }}>
                  {" "}
                  Live Day IDs :{" "}
                  {liveDays.map((ld) => {
                    return <Badge count={"ID : " + ld.id} />
                  })}
                </Space>
              )}

              <Form.List name="liveMetaData">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, i) => (
                      <div key={i}>
                        <h1 style={{ textAlign: "center" }}>
                          Day {form.getFieldValue("liveMetaData")[i]?.id}
                          <span>
                            <MinusCircleOutlined
                              style={{ color: "red", marginLeft: "20px" }}
                              onClick={() => remove(name)}
                            />
                          </span>
                        </h1>
                        <Form.Item
                          {...restField}
                          name={[name, "id"]}
                          label="ID"
                          rules={[
                            {
                              required: true,
                              message: "id is required",
                            },
                          ]}
                        >
                          <Input placeholder="ex : 1" />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "url"]}
                          label="url"
                          rules={[
                            {
                              type: "url",
                              warningOnly: true,
                            },
                          ]}
                        >
                          <Input placeholder="Live class URL" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "recordedLecture"]}
                          label="recordedLecture"
                          rules={[
                            {
                              type: "recordedLecture",
                              warningOnly: true,
                            },
                          ]}
                        >
                          <Input placeholder="Recorded class URL" />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "venue"]}
                          label="venue"
                          rules={[
                            {
                              required: true,
                              message: "Please provide venue",
                            },
                          ]}
                        >
                          <Input placeholder="ex : Google Meet" />
                        </Form.Item>
                        <Form.Item
                          label="Date"
                          rules={[
                            {
                              required: true,
                              message: "Please provide Date",
                            },
                          ]}
                        >
                          <Space direction="verticle">
                            <Form.Item
                              {...restField}
                              name={[name, "date"]}
                            // label="Date"
                            >
                              <DatePicker
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                placeholder="Live class start date and time"
                              />
                            </Form.Item>

                            <Tooltip title="2023-04-14 21:00:00">
                              <Typography.Link href="#API">
                                Need Help on format?
                              </Typography.Link>
                            </Tooltip>
                          </Space>
                        </Form.Item>
                      </div>
                    ))}
                    <Form.Item
                      wrapperCol={{
                        offset: 4,
                        span: 5,
                      }}
                      style={{ backgroundColor: "ghostwhite" }}
                    >
                      <Button
                        // type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add New Live
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Form.Item
                wrapperCol={{
                  offset: 4,
                  span: 16,
                }}
              >
                <Button type="primary" block htmlType="submit">
                  Create Batch
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Batch

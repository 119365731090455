import React from "react"

const Loader = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",

        position: "fixed",
        top: "0",
        left: "0",
        zIndex: 9999,
        letterSpacing: "3px",
        fontSize: "1.5rem",
        color: "white",
        backgroundColor: "#191919",
        transition: "opacity 0.2s",
        fontFamily: "primaryMedium",
      }}
    >
      <h1
        style={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%)",
          display:"flex",
          fontSize:"20px",
          fontFamily:"sans-serif",
          flexDirection:"column",
          textAlign:"center",
          alignItems:"center"
        }}
      >
      <div className="loader"> <img src="https://i.ibb.co/FDPNV9T/You-Vah-Logo-with-strokes-1.png" width={50}></img></div><br></br>
      <p style={{width:"280px"}}>Launching into the depths of the universe. Patience, please</p>
      </h1>
    </div>
  )
}

export default Loader

import axios from "axios"
import { useEffect, useState, useRef } from "react"
import dayjs from "dayjs"
import TextArea from "antd/es/input/TextArea"
import constants from "../../constants"
import { useNavigate } from "react-router-dom"
import {
  Button,
  Form,
  Input,
  Radio,
  Row,
  Col,
  Divider,
  DatePicker,
  TimePicker,
  Select,
  Space,
  Card,
  Typography,
  message,
  List,
  Skeleton,
  Table,
  Tooltip,
  Badge,
  Statistic,
  Modal,
  Avatar,
  Switch,
  Tag,
  Tabs
} from "antd"
import {
  SearchOutlined,
  EditOutlined,
  WhatsAppOutlined,
  CheckOutlined,
  CloseCircleTwoTone,
  ClockCircleTwoTone,
  ReloadOutlined,
  ScissorOutlined,
  BankOutlined,
  RocketTwoTone,
  StarFilled,
  HistoryOutlined,
  NodeIndexOutlined,
  PlusOutlined,
  DiffOutlined,
  PercentageOutlined,
  HomeOutlined,
  LoadingOutlined,
  InfoCircleTwoTone,
  UserOutlined,
  HeatMapOutlined
} from "@ant-design/icons"
import { AutoComplete } from "antd"
import Loader from "../../util/loader"
import useView from "../../hooks/useView"
import Meta from "antd/es/card/Meta"

const { Option } = Select
const { Text } = Typography

const AlphaInternship = () => {
  
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [total, settotal] = useState(0)
  const [analytics, setAnalytics] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [company, setCompany] = useState()
  const [school, setSchool] = useState()
  const { width } = useView()
  const navigate = useNavigate()
  const searchInput = useRef(null)
  const [messageApi, contextHolder] = message.useMessage()
  const [form] = Form.useForm()
  const [value, setValue] = useState()
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      position: ["topCenter"],
    },
    filters: {},
  })
  const { isMobile } = useView()
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [modalText, setModalText] = useState("Content of the modal")
  const showModal = () => {
    setOpen(true)
  }
  const handleOk = () => {
    setModalText("The modal will be closed after two seconds")
    setConfirmLoading(true)
    setTimeout(() => {
      setOpen(false)
      setConfirmLoading(false)
    }, 2000)
  }
  const handleCancel = () => {
    console.log("Clicked cancel button")
    setOpen(false)
  }

  const getPanelValue = async (searchText) => {
    if (!searchText) return []
    setOptions([{ value: "loading ...." }])
    console.log("seach text ", searchText)
    try {
      const where = JSON.stringify({
        ascii_name: {
          $regex: "^" + searchText,
          $options: "i",
        },
      })
      const url = `https://parseapi.back4app.com/classes/Indiacities_india_cities_database?limit=10&keys=ascii_name,latitude,longitude&where=${where}`
      const header = {
        // "X-Parse-Application-Id": process.env.REACT_APP_CITY_API_ID,
        // "X-Parse-REST-API-Key": process.env.REACT_APP_CITY_API_KEY,
        "X-Parse-Application-Id": "QGbWT4XShAT2mbLgskFhhR8IsKlUwIzbg60sPofs",
        "X-Parse-REST-API-Key": "W3nwydEEB9VswTJY46aVZDdnd6QTZW1tiiaCcDce",
        "Authorization": `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
      }
      const res = await axios.get(url, {
        headers: header,
      })

      if (res.status !== 200) return []

      return res?.data?.results.map((city) => {
        return { value: city.ascii_name, key: city.objectId }
      })
    } catch (error) {
      setLoading(false)
      console.log(error.response)
      return []
    }
  }
  const fetchSchool = async () => {
    setTableLoading(true)
    let query = []
    console.log(tableParams)
    Object.keys(tableParams.filters).forEach((k) => {
      if (
        tableParams.filters[k] !== null &&
        tableParams.filters[k].length > 0
      ) {
        query.push(`${k}=${tableParams.filters[k]}`)
      }
    })
    //   name={$regex:^fin,$options:'i'}&

    query =
      `page=${tableParams.pagination.current}&limit=${tableParams.pagination.pageSize}&` +
      query.join("&")
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/company?type=ALPHA&${query}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      console.log('school',res);
      setSchool(res.data.data)
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.data.results,
        },
      })
      setTableLoading(false)
    } catch (error) {
      setTableLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    fetchSchool()
  }, [JSON.stringify(tableParams)])

  const handleSave = async (values) => {
    console.log(values)
    const interviewDate = new Date(values.date)
    const interviewTime = new Date(values.time)
    interviewDate.setHours(
      interviewTime.getHours(),
      interviewTime.getMinutes(),
      0,
      0
    )
    let companyId =
      form.getFieldValue("companyId") ||
      values.name.split(" ")[0] + "_" + Math.round(Math.random() * 1000)
    setLoading(true)
    try {
      const body = {
        companyId: companyId,
        name: values.name,
        description:values.description,
        companyUrl:values.companyUrl,
        tentativeStartDate:values.tentativeStartDate,
        interviewDate,
        type:values.type,
        duration:values.duration,
        hiring:values.hiring,
        activeHiringDates:{
          startDate:values.startDate,
          endDate:values.endDate
        },
        POC_V1: {
          name: values.POC_Name,
          contact: values.POC_Phone,
          email: values.POC_Email,
          designation: values.POC_Designation,
        },
        POC: values.POC_Name,
        designation: values.POC_Designation,
        mainGroupLink: values.mainGroupLink,
        logoUrl: values.logoUrl,
        requiredFields:{
        SMM :values.SMM === undefined ? company?.requiredFields?.SMM:values.SMM,
        GD : values.GD === undefined ? company?.requiredFields?.GD:values.GD,
        VE: values.VE === undefined ? company?.requiredFields?.VE:values.VE,
        CW : values.CW === undefined ? company?.requiredFields?.CW:values.CW,
        WD : values.WD === undefined ? company?.requiredFields?.WD:values.WD,
        GDPRO : values.GDPRO === undefined ? company?.requiredFields?.GDPRO:values.GDPRO,
        DM : values.DM === undefined ? company?.requiredFields?.DM:values.DM
        }
      }
      const url = `${constants.NEW_DOMAIN}/api/v1/company/`
      const res = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      messageApi.open({
        type: "success",
        content: "Saved successfully",
      })
      // update the record here, so that no explicit refresh is required
      // setSchool((schools) => {
      //   return schools.map((school) => {
      //     return school.schoolId === body.schoolId ? body : school
      //   })
      // })
      setOpen(false)
      setLoading(false)
      fetchSchool()
    } catch (error) {
      setLoading(false)
      console.log(error)
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      console.log(error.response.data.message)
      setOpen(false)
      fetchSchool()
    }
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    fetchSchool()
  }
  const fetchSchoolId = async (id) => {
    setIsModalOpen(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/users/application/analytics/${id}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      console.log(res)
      setAnalytics(res.data.data)
      let vount = 0
      let add = res.data.data.results.map((num, sum = 0) => {
        vount = vount + num.count
      })
      settotal(vount)
      console.log(res.data)
      setModalLoading(false)
    } catch (error) {
      setModalLoading(false)
      console.log(error)
    }
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 }, //reset the page to 1
      filters: {
        [dataIndex]: selectedKeys,
      },
    })
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={
            dataIndex === "nextFollowUp" ? "YYYY-MM-DD" : `Search ${dataIndex}`
          }
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
  })

  const onSelect = (data) => {
    console.log("onSelect", data)
  }
  const columns = [
    {
      title: "",
      width: 50,
      render: (_, record) => {
        return <Space>{renderStatus(_, record)}</Space>
      },
    },
    {
      title: "Company Name",
      dataIndex: "name",
      //   fixed: "left",
      width: 195,
      sorter: false,
      render: (_, record) => {
        return (
          <a
            onClick={() => {
              // console.log(form)
              setCompany(record)
              form.resetFields()
              form.setFieldsValue(record)
              if (record.interviewDate) {
                const date = dayjs(record.interviewDate)
                form.setFieldValue("date", date)
                form.setFieldValue("time", date)
              }
              if (record.tentativeStartDate) {
                const date = dayjs(record.tentativeStartDate)
                form.setFieldValue("tentativeStartDate", date)
              }
              if (record.activeHiringDates) {
                const date1 = dayjs(record.activeHiringDates.startDate)
                const date2 = dayjs(record.activeHiringDates.endDate)
                form.setFieldValue("startDate", date1)
                form.setFieldValue("endDate", date2)
                // form.setFieldValue("time", date)
              }
              if (record.POC_V1) {
                form.setFieldValue("POC_Name", record.POC_V1.name)
                form.setFieldValue("POC_Phone", record.POC_V1.contact)
                form.setFieldValue("POC_Email", record.POC_V1.email)
                form.setFieldValue("POC_Designation", record.POC_V1.designation)
              }
              form.setFieldValue(["SMM","isActive"],record?.requiredFields?.SMM?.isActive)
              form.setFieldValue(["SMM","totalInternRequired"],record?.requiredFields?.SMM?.totalInternRequired)

              form.setFieldValue(["GD","isActive"],record?.requiredFields?.GD?.isActive)
              form.setFieldValue(["GD","totalInternRequired"],record?.requiredFields?.GD?.totalInternRequired)

              form.setFieldValue(["VE","isActive"],record?.requiredFields?.VE?.isActive)
              form.setFieldValue(["VE","totalInternRequired"],record?.requiredFields?.VE?.totalInternRequired)

              form.setFieldValue(["CW","isActive"],record?.requiredFields?.CW?.isActive)
              form.setFieldValue(["CW","totalInternRequired"],record?.requiredFields?.CW?.totalInternRequired)

              form.setFieldValue(["WD","isActive"],record?.requiredFields?.WD?.isActive)
              form.setFieldValue(["WD","totalInternRequired"],record?.requiredFields?.WD?.totalInternRequired)

              
              form.setFieldValue(["GDPRO","isActive"],record?.requiredFields?.GDPRO?.isActive)
              form.setFieldValue(["GDPRO","totalInternRequired"],record?.requiredFields?.GDPRO?.totalInternRequired)

              
              form.setFieldValue(["DM","isActive"],record?.requiredFields?.DM?.isActive)
              form.setFieldValue(["DM","totalInternRequired"],record?.requiredFields?.DM?.totalInternRequired)
              showModal()
            }}
          >
            {record.name}
          </a>
        )
      },
      ...getColumnSearchProps("name"),
    },
    // {
    //   title: "City",
    //   dataIndex: "city",
    //   width: 120,
    //   sorter: false,
    //   //   fixed: "left",
    //   ...getColumnSearchProps("city"),
    // },
    {
      title: "POC Details",
      dataIndex: "POC_V1",
      sorter: false,
      width: 150,
      render: (data) =>
        data ? (
          <Tooltip
            color="white"
            title={
              <Card
                bordered={false}
                size="small"
                style={{
                  minWidth: 150,
                }}
              >
                <Meta
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: "#971eff",
                      }}
                      icon={<UserOutlined />}
                    />
                  }
                  title={data.name}
                  description={`Designation : ${data.designation || "UNKOWN"}`}
                />
                <p>✉️ : {data.email || "N/A"}</p>
                <p>📞 : {data.contact || "N/A"}</p>
              </Card>
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                size={32}
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "gray",
                  marginRight: "10px",
                }}
              >
                {data.name ? data.name[0] : <UserOutlined />}
              </Avatar>
              <p>{data.name}</p>
            </div>
          </Tooltip>
        ) : (
          "N/A"
        ),
    },
    // isMobile
    //   ? {
    //       width: 0,
    //     }
    //   : {
    //       title: "DOR (MM/DD/YY)",
    //       dataIndex: "createdAt",
    //       sorter: false,
    //       render: (date) => (
    //         <>
    //           {new Date(date).toLocaleDateString()}
    //           <br />
    //           <div style={{ color: "lightgray" }}>
    //             {new Date(date).toLocaleTimeString()}
    //           </div>
    //         </>
    //       ),
    //     },
    // {
    //   title: "Call Connected",
    //   dataIndex: "callConnected",
    //   filters: [
    //     {
    //       text: "Yes",
    //       value: "YES",
    //     },
    //     {
    //       text: "No",
    //       value: "NO",
    //     },
    //   ],
    //   render: (callConnected, record) => {
    //     return (
    //       <Select
    //         size="small"
    //         defaultValue={callConnected || ""}
    //         style={{
    //           width: 60,
    //         }}
    //         onChange={(e) => {
    //           record.callConnected = e
    //           console.log(record)
    //         }}
    //         options={[
    //           {
    //             value: "YES",
    //             label: "Yes",
    //           },
    //           {
    //             value: "NO",
    //             label: "No",
    //           },
    //         ]}
    //       />
    //     )
    //   },
    // },
    // {
    //   title: "Lead Type",
    //   dataIndex: "leadType",
    //   width: 120,
    //   filters: [
    //     {
    //       text: "HOT",
    //       value: "HOT",
    //     },
    //     {
    //       text: "COLD",
    //       value: "COLD",
    //     },
    //     {
    //       text: "CONVERTED",
    //       value: "CONVERTED",
    //     },
    //     {
    //       text: "DIRECT",
    //       value: "DIRECT",
    //     },
    //   ],
    //   render: (leadType) => {
    //     let color = {
    //       HOT: "geekblue",
    //       COLD: "volcano",
    //       CONVERTED: "green",
    //     }
    //     return <Tag color={color[leadType]}>{leadType}</Tag>
    //   },
      // render: (leadType, record) => {
      //   return (
      //     <Select
      //       size="small"
      //       defaultValue={leadType || ""}
      //       style={{
      //         width: 100,
      //       }}
      //       onChange={(e) => {
      //         record.leadType = e
      //       }}
      //       options={[
      //         {
      //           value: "HOT",
      //           label: "Hot",
      //         },
      //         {
      //           value: "COLD",
      //           label: "Cold",
      //         },
      //         {
      //           value: "CONVERTED",
      //           label: "Converted",
      //         },
      //         {
      //           value: "DIRECT",
      //           label: "DIRECT",
      //         },
      //       ]}
      //     />
      //   )
      // },
    // },
    {
      title: "Internship Start Date",
      dataIndex: "tentativeStartDate",
      sorter: false,
      width: 150,

      render: (date) =>
        date ? (
          <p style={{ color: "gray" }}>{new Date(date).toDateString()}</p>
        ) : (
          <p>N/A</p>
        ),
      // ...getColumnSearchProps("nextFollowUp"),
    },
    {
      title: "Hiring Dates",
      dataIndex: "activeHiringDates",
      sorter: false,
      width: 250,
      render: (date, record) =>
        date ? (
          <>
           Start Date :  {new Date(date.startDate).toDateString()}
            <div style={{ color: "lightgray" }}>
           End Date : {new Date(date.endDate).toDateString()}
            </div>
          </>
        ) : (
          "N/A"
        ),
      // ...getColumnSearchProps("nextFollowUp"),
    },
    {
      title: "Interview Date",
      dataIndex: "interviewDate",
      sorter: false,
      width: 150,
      render: (orientationDate, record) =>
        orientationDate ? (
          <>
            {new Date(orientationDate).toDateString()}
            <div style={{ color: "lightgray" }}>
              {new Date(orientationDate).toLocaleTimeString()}
            </div>
          </>
        ) : (
          "N/A"
        ),
      // ...getColumnSearchProps("nextFollowUp"),
    },
    {
      title: "Requirements",
      dataIndex: "reqiredFields",
      width: 255,
      render: (notes,record) => {
        return <div style={{ color: "lightgray" }}>
         {record.requiredFields?.GD !== undefined && <> 
         <p><Tag color="green">GD</Tag> <br></br> Count : {record.requiredFields.GD?.totalInternRequired}</p>
         </>}
         {record.requiredFields?.SMM !== undefined && <> 
         <p><Tag color="purple">SMM</Tag> <br></br> Count : {record.requiredFields.SMM?.totalInternRequired}</p>
         </>}
         {record.requiredFields?.VE !== undefined && <> 
         <p><Tag color="brown">VE</Tag> <br></br> Count : {record.requiredFields.VE?.totalInternRequired}</p>
         </>}
         {record.requiredFields?.CW !== undefined && <> 
         <p><Tag color="blue">CW</Tag> <br></br> Count : {record.requiredFields.CW?.totalInternRequired}</p>
         </>}
         {record.requiredFields?.WD !== undefined && <> 
         <p><Tag color="red">WD</Tag> <br></br> Count : {record.requiredFields.WD?.totalInternRequired}</p>
         </>}

         {record.requiredFields?.GDPRO !== undefined && <> 
         <p><Tag color="red">GDPRO</Tag> <br></br> Count : {record.requiredFields.GDPRO?.totalInternRequired}</p>
         </>}

         {record.requiredFields?.DM !== undefined && <> 
         <p><Tag color="red">DM</Tag> <br></br> Count : {record.requiredFields.DM?.totalInternRequired}</p>
         </>}

        </div>
      },
    },
    {
        title: "Duration",
        dataIndex: "duration",
        width: 150,
        render: (notes,record) => {
          return <div >
           {notes} Months
          </div>
        },
      },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 80,
      render: (_, record) => (
        <>
          <Space direction="vertical" size={"small"}>
            <Tooltip title="Edit">
              <Button
                type="link"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => {
                  setCompany(record)
                  form.resetFields()
                  form.setFieldsValue(record)
                  if (record.interviewDate) {
                    const date = dayjs(record.interviewDate)
                    form.setFieldValue("date", date)
                    form.setFieldValue("time", date)
                  }
                  if (record.tentativeStartDate) {
                    const date = dayjs(record.tentativeStartDate)
                    form.setFieldValue("tentativeStartDate", date)
                  }
                  if (record.activeHiringDates) {
                    const date1 = dayjs(record.activeHiringDates.startDate)
                    const date2 = dayjs(record.activeHiringDates.endDate)
                    form.setFieldValue("startDate", date1)
                    form.setFieldValue("endDate", date2)
                    // form.setFieldValue("time", date)
                  }
                  if (record.POC_V1) {
                    form.setFieldValue("POC_Name", record.POC_V1.name)
                    form.setFieldValue("POC_Phone", record.POC_V1.contact)
                    form.setFieldValue("POC_Email", record.POC_V1.email)
                    form.setFieldValue("POC_Designation", record.POC_V1.designation)
                  }
                  form.setFieldValue(["SMM","isActive"],record?.requiredFields?.SMM?.isActive)
                  form.setFieldValue(["SMM","totalInternRequired"],record?.requiredFields?.SMM?.totalInternRequired)

                  form.setFieldValue(["GD","isActive"],record?.requiredFields?.GD?.isActive)
                  form.setFieldValue(["GD","totalInternRequired"],record?.requiredFields?.GD?.totalInternRequired)

                  form.setFieldValue(["VE","isActive"],record?.requiredFields?.VE?.isActive)
                  form.setFieldValue(["VE","totalInternRequired"],record?.requiredFields?.VE?.totalInternRequired)

                  form.setFieldValue(["CW","isActive"],record?.requiredFields?.CW?.isActive)
                  form.setFieldValue(["CW","totalInternRequired"],record?.requiredFields?.CW?.totalInternRequired)

                  form.setFieldValue(["WD","isActive"],record?.requiredFields?.WD?.isActive)
                  form.setFieldValue(["WD","totalInternRequired"],record?.requiredFields?.WD?.totalInternRequired)

                  
              form.setFieldValue(["GDPRO","isActive"],record?.requiredFields?.GDPRO?.isActive)
              form.setFieldValue(["GDPRO","totalInternRequired"],record?.requiredFields?.GDPRO?.totalInternRequired)

              
              form.setFieldValue(["DM","isActive"],record?.requiredFields?.DM?.isActive)
              form.setFieldValue(["DM","totalInternRequired"],record?.requiredFields?.DM?.totalInternRequired)
                  showModal()
                }}
              />
            </Tooltip>
            <Tooltip title="Get Analytics">
              <Button
                type="text"
                shape="circle"
                onClick={() => {
                  setData(record)
                  setModalLoading(true)
                  fetchSchoolId(record._id)
                }}
                icon={<PercentageOutlined style={{ color: "#45C153" }} />}
              />
            </Tooltip>
            <Tooltip title="Fetch Students">
              <Button
                type="text"
                shape="circle"
                onClick={() => navigate(`/internship/alpha/application/${record._id}`)}
                icon={<NodeIndexOutlined style={{ color: "#971eff" }} />}
              />
            </Tooltip>
            {/* <Tooltip title="Fetch Report">
              <Button
                type="text"
                shape="circle"
                onClick={() => navigate(`/b2b/school/report/${record._id}`)}
                icon={<DiffOutlined style={{ color: "red" }} />}
              />
            </Tooltip> */}
          </Space>
        </>
      ),
    },
  ]

  const renderHeader = () => {
    return (
      <Space size={16}>
        <Button
          type="default"
          icon={<PlusOutlined />}
          onClick={() => {
            form.resetFields()
            showModal()
          }}
          loading={loading}
          style={{ backgroundColor: "#971eff", color: "white" }}
        >
          Add Company
        </Button>
        <Button
          type="default"
          icon={<ScissorOutlined />}
          onClick={() => setTableParams({ ...tableParams, filters: {} })}
          loading={loading}
        >
          Clear Filters
        </Button>
        <Button
          type="default"
          icon={<ReloadOutlined />}
          onClick={() => fetchSchool()}
          loading={loading}
        >
          Reload
        </Button>
      </Space>
    )
  }
  const handleTableChange = (pagination, filters, sorter, extra) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    })

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setSchool([])
    }
  }
  const CourseTab=({cate})=>{
    return <>
      <Form.Item
              name={[cate,"isActive"]}
              label="Accept Response"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item name={[cate,"totalInternRequired"]} label="No of Interns">
                <Input placeholder="5"/>
              </Form.Item>
    
    </>
  }
  const renderStatus = (_, record) => {
    const today = new Date().getTime()

    if(today<new Date(record.activeHiringDates.startDate).getTime())
    return <HeatMapOutlined style={{color:"red",fontSize:"20px"}}/>

    if(new Date(record.activeHiringDates.startDate).getTime()< today && today < new Date(record.activeHiringDates.endDate).getTime())
    return <HeatMapOutlined style={{color:"green",fontSize:"20px"}}/>

    if(today < new Date(record.interviewDate).getTime())
    return <HeatMapOutlined style={{color:"orange",fontSize:"20px"}}/>

    if(today < new Date(record.tentativeStartDate).getTime())
    return <HeatMapOutlined style={{color:"purple",fontSize:"20px"}}/>
    // if (record.leadType === "DIRECT") {
    //   return <RocketTwoTone twoToneColor="green" style={{ fontSize: "25px" }} />
    // }
    // if (record.leadType === "CONVERTED")
    //   return (
    //     <CheckOutlined
    //       twoToneColor="lightgreen"
    //       style={{ color: "lightGreen", fontSize: "25px" }}
    //     />
    //   )
    // if (record.profile) {
    //   return <BankOutlined />
    // }

    // if (record.userInterested === "NO") {
    //   return (
    //     <CloseCircleTwoTone twoToneColor="red" style={{ fontSize: "20px" }} />
    //   )
    // }

    // if (record.new) {
    //   if (record.oldUser) {
    //     return (
    //       <>
    //         <Badge color="blue" />
    //         <Tooltip title="Repeating user :)">
    //           <StarFilled style={{ color: "green" }} />
    //         </Tooltip>
    //       </>
    //     )
    //   } else {
    //     return <Badge color="blue" />
    //   }
    // }

    // if (
    //   record.nextFollowUp &&
    //   new Date(record.nextFollowUp).toLocaleDateString() ===
    //     new Date().toLocaleDateString()
    // )
    //   return (
    //     <ClockCircleTwoTone
    //       twoToneColor="orange"
    //       style={{ fontSize: "25px" }}
    //     />
    //   )
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div style={{ backgroundColor: "#fff", padding: "20px" }}>
      {contextHolder}
      <Modal
        title="Add/Update Tab"
        open={open}
        // onOk={handleOk}
        // confirmLoading={confirmLoading}
        footer={""}
        style={{ height: "400px" }}
        scroll={true}
        onCancel={handleCancel}
      >
        <Card title="Company Details">
          <Form
            layout="horizontal"
            form={form}
            size="medium"
            onFinish={handleSave}
            // autoComplete="true"
            style={{
              maxWidth: 600,
              marginTop: "40px",
            }}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 14,
            }}
          >
             <Form.Item
              label="Company ID"
              name="companyId"
              hidden
              rules={[
                {
                  // required: true,
                  message: "Company name is required",
                },
              ]}
            >
              <Input placeholder="Enter ID" />
            </Form.Item>
            <Form.Item
              label="Company Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Company name is required",
                },
              ]}
            >
              <Input placeholder="Enter Company" />
            </Form.Item>
            <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "Please provide description!",
                  },
                ]}
              >
                <Input.TextArea placeholder="description" />
              </Form.Item>
              <Form.Item
              label="Internship Duration"
              name="duration"
              rules={[
                {
                  required: true,
                  message: "Duration is required",
                },
              ]}
            >
              <Input placeholder="2 Months" />
            </Form.Item>
              <Form.Item
                name="companyUrl"
                label="Website Link"
                rules={[
                  {
                    type: "url",
                    required: true,
                    message: "Please provide company website link",
                  },
               
                ]}
              >
                <Input placeholder="Enter Company Website Link" />
              </Form.Item>
            <Form.Item label="🔗 Hiring Group Link" name="mainGroupLink">
              <Input placeholder="Link" />
            </Form.Item>
            <Form.Item
              label="🔗 Logo URL"
              name="logoUrl"
              rules={[
                {
                  type: "url",
                  warningOnly: true,
                  message: "Please Enter valid URL",
                },
              ]}
            >
              <Input placeholder="Logo URL" />
            </Form.Item>
            <Form.Item label="⌚Tentative Start Date">
              <Form.Item name="tentativeStartDate">
                <DatePicker
                  placement="topLeft"
                  format="YYYY-MM-DD"
                  // style={{
                  //   width: "100%",
                  // }}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item name="type" label="Company Type">
              <Select style={{ maxWidth: "200px" }}>
                <Option value="ALPHA">ALPHA</Option>
                <Option value="BETA">BETA</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="hiring"
              label="Hiring Status"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Divider plain orientation="left" style={{ color: "gray" }}>
              Interview details
            </Divider>

            <Form.Item label="⏰Date & Time">
              <Space>
                <Form.Item name="date">
                  <DatePicker
                    placement="topLeft"
                    format="YYYY-MM-DD"
                    // style={{
                    //   width: "100%",
                    // }}
                  />
                </Form.Item>
                <Form.Item name="time">
                  <TimePicker showNow placement="topLeft" />
                </Form.Item>
              </Space>
            </Form.Item>
            <Divider plain orientation="left" style={{ color: "gray" }}>
              Add POC details
            </Divider>
            <Form.Item label="Name" name="POC_Name">
              <Input placeholder="enter name" />
            </Form.Item>
            <Form.Item label="Designation" name="POC_Designation">
              <Input placeholder="eg : Founder" />
            </Form.Item>
            <Form.Item
              label="📞 Phone"
              name="POC_Phone"
              rules={[
                { min: 10, message: "Please check phone number" },
                { max: 10, message: "Please check phone number" },
              ]}
            >
              <Input placeholder="phone number" type="tel" />
            </Form.Item>
            <Form.Item
              label="✉️ Email"
              name="POC_Email"
              rules={[{ type: "email", message: "Please check email" }]}
            >
              <Input placeholder="email@gmail.com" type="email" />
            </Form.Item>


            <Divider plain orientation="left" style={{ color: "gray" }}>
              Hiriing Dates details
            </Divider>
            <Form.Item label="Start Date">
              <Form.Item name="startDate">
                <DatePicker
                  placement="topLeft"
                  format="YYYY-MM-DD"
                  // style={{
                  //   width: "100%",
                  // }}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item label="End date">
              <Form.Item name="endDate">
                <DatePicker
                  placement="topLeft"
                  format="YYYY-MM-DD"
                  // style={{
                  //   width: "100%",
                  // }}
                />
              </Form.Item>
            </Form.Item>
            

            <Divider plain orientation="left" style={{ color: "gray" }}>
              Required Fields
            </Divider>
           
               <Tabs centered id="tasks" 
               defaultActiveKey="1"
    items={[
      {
        label: 'SMM',
        key: '1',
        children: <CourseTab cate="SMM"/>,
      },
       {
        label: 'GD',
        key: '2',
        children: <CourseTab cate="GD"/>,
      },
       {
        label: 'CW',
        key: '3',
        children: <CourseTab cate="CW"/>,
      },
       {
        label: 'VE',
        key: '4',
        children: <CourseTab cate="VE"/>,
      },
      {
        label: 'WD',
        key: '5',
        children: <CourseTab cate="WD"/>,
      },
      {
        label: 'GDPRO',
        key: '6',
        children: <CourseTab cate="GDPRO"/>,
      },
      {
        label: 'DM',
        key: '7',
        children: <CourseTab cate="DM"/>,
      },
      
    ]}
               
              />
          

            <Form.Item
              wrapperCol={
                width > 800
                  ? {
                      offset: 8,
                    }
                  : {}
              }
            >
              <Button
                type="primary"
                style={{ width: "200px" }}
                htmlType="submit"
              >
                📂 Save
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
      {/* analytics */}
      {data && (
        <Modal
          // title={data.name + ", " + (data.city || "")}
          title={
            modalLoading ? (
              ""
            ) : (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Avatar
                  size={data.logoUrl ? 64 : 32}
                  src={data.logoUrl}
                  icon={!data.logoUrl && <HomeOutlined />}
                  style={!data.logoUrl && { opacity: "0.3" }}
                />
                <p>
                  {modalLoading ? "" : data.name + ", " + (data.city || "")}
                </p>
              </div>
            )
          }
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          okButtonProps={{
            display: false,
          }}
          footer={[]}
          style={{ maxWidth: isMobile ? "350px" : "500px", fontSize: "20px" }}
        >
          {modalLoading ? (
            <LoadingOutlined
              width={"50px"}
              style={{
                marginLeft: "43%",
                fontSize: "30px",
                marginTop: "10%",
              }}
            />
          ) : (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                Company Id :
                <p style={{ fontWeight: "bold", marginLeft: "4px" }}>
                  {data.companyId}
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                Hiring Group Link :{" "}
                <a
                  style={{ fontWeight: "bold", marginLeft: "4px" }}
                  href={"https://bit.ly/" + data.mainGroupLink}
                  target="_blank"
                >
                  {data.mainGroupLink
                    ? "https://bit.ly/" + data.mainGroupLink
                    : "Not Available"}
                </a>
              </div>
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                    POC Name :
                    <p style={{ fontWeight: "bold", marginLeft: "4px" }}>
                      {data.POC?.name || "N/A"}
                    </p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    POC Email :
                    <p style={{ fontWeight: "bold", marginLeft: "4px" }}>
                      {data.POC?.email || "N/A"}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    POC Contact :
                    <p
                      style={{
                        fontWeight: "bold",
                        marginLeft: "4px",
                      }}
                    >
                      {data.POC?.contact || "N/A"}
                    </p>
                  </div>
                  {data.notes && (
                    <p style={{ color: "gray" }}>Notes : {data.notes}</p>
                  )} */}
              <Divider>Application Status</Divider>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: isMobile
                    ? "135px 125px"
                    : "135px 125px 125px",
                  gridColumnGap: "20px",
                  gridRowGap: "20px",
                }}
              >
                <Card bordered={false}>
                  <Statistic
                    title={"TOTAL"}
                    value={total}
                    // precision={2}
                    valueStyle={{ color: "black", fontSize: "20px" }}
                    // prefix={<ArrowDownOutlined />}
                  />
                </Card>
                {(analytics?.results || []).map((c, i) => {
                  return (
                    <Card bordered={false} key={i}>
                      <Statistic
                        title={c._id}
                        value={c.count}
                        // precision={2}
                        valueStyle={{ color: "black", fontSize: "20px" }}
                        // prefix={<ArrowDownOutlined />}
                      />
                    </Card>
                  )
                })}
              </div>
            </>
          )}
        </Modal>
      )}

      <Row>
        <Col xs={24} md={20}>
          <div className="text-3xl">Alpha Company</div>
          <p className="text-lg-light">
            {new Date().toDateString()} - {new Date().toLocaleTimeString()}
          </p>
        </Col>

        <Col xs={24} md={4}>
          <Statistic
            title="Total Company Leads"
            loading={loading}
            value={school?.length}
            precision={0}
            valueStyle={{
              color: "blue",
            }}
            // prefix={<ArrowUpOutlined />}
            suffix="Company"
          />
        </Col>
      </Row>

      <Table
        columns={columns}
        loading={tableLoading}
        title={renderHeader}
        size="medium"
        rowClassName="row"
        dataSource={school}
        onChange={handleTableChange}
        pagination={tableParams.pagination}
        rowKey={(record) => record._id}
        scroll={{
          x: 900,
          y: 500,
        }}
      />
    </div>
  )
}
export default AlphaInternship

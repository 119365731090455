import React, { useEffect, useState, useRef } from "react"
import {
  Space,
  Table,
  Tag,
  Button,
  Input,
  Row,
  Col,
  Statistic,
  Tooltip,
  Modal,
  Alert,
  message,
  Divider,
  Drawer,
  List,
  Avatar,
  Card,
  Typography,
} from "antd"
import {
  SearchOutlined,
  ScissorOutlined,
  ReloadOutlined,
  ExportOutlined,
  EditOutlined,
  UserOutlined,
  WhatsAppOutlined,
  ContainerTwoTone,
  StopTwoTone,
} from "@ant-design/icons"
import axios from "axios"
import constants from "../../constants"
import * as FileSaver from "file-saver"
import XLXS from "sheetjs-style"
import { Form, Select } from "antd"
import { useAuth } from "../../contexts/authContext"
import { socket } from "../../util/socket"
const { Option } = Select
const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 16,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 5,
    span: 16,
  },
}
const PaidUser = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const searchInput = useRef(null)
  const { Text } = Typography
  const [open, setOpen] = useState(false)
  const [status, setStatus] = useState(false)
  const [user, setUser] = useState()
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      position: ["topCenter"],
    },
    filters: {},
  })
  const { currentUser } = useAuth()
  const [link, setLink] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()
  const [edit, editParams] = useState({
    id: "",
    name: "name",
    categoryCode: "",
    batchId: "",
  })
  const [error, setError] = useState({
    visible: false,
    type: "",
    message: "",
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const [form] = Form.useForm()
  const [form1] = Form.useForm()
  const [form2] = Form.useForm()
  const onReset = () => {
    form.resetFields()
    console.log(form)

    form1.resetFields()
    form2.resetFields()
  }
  const onClose = () => {
    setOpen(false)
  }

  const onFinish = async (values) => {
    console.log(values)
    if (link) {
      if (values.Batch == undefined && values.Phone == undefined) {
        alert("please enter some data")
        return
      } else if (values.Batch == "" && values.Phone == "") {
        alert("please enter some data")
        return
      }

      setLoading(true)
      try {
        const body = {}
        if (values.Batch == undefined || values.Batch == "") {
          body.phone = values.Phone
        } else {
          body.batchCode = values.Batch
        }
        console.log(body)

        const url = `${constants.NEW_DOMAIN}/api/v1/batch/sendSignupLinkToBatch`
        const res = await axios.post(url, body, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
          }
        })
        // console.log(res)
        messageApi.open({
          type: "success",
          content: "Message sent successfully",
        })
        setLoading(false)
      } catch (error) {
        setLoading(false)
        messageApi.open({
          type: "error",
          content: error.response.data.message || "Something went wrong",
        })
        //  console.log(error.response.data.message);
      }
    } else {
      setLoading(true)
      try {
        const url = `${constants.NEW_DOMAIN}/api/v1/admin/updateSlotAndCategory`
        const body = {
          userId: edit.id,
          categoryCode:
            values.Category == undefined || values.Category == ""
              ? edit.categoryCode
              : values.Category,
          batchCode:
            values.Batch == undefined || values.Batch == ""
              ? edit.batchId
              : values.Batch,
        }
        const res = await axios.post(url, body, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
          }
        })

        // console.log(res)
        messageApi.open({
          type: "success",
          content: "User Updated successfully",
        })
        setLoading(false)
      } catch (error) {
        setLoading(false)
        messageApi.open({
          type: "error",
          content: error.response.data.message || "Something went wrong",
        })
        // console.log(error.response.data.message);
      }
    }
  }
  const onFinishChangeEmail = async (values) => {
    console.log(values)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/reset-user`
      const body = {
        userId: edit.id,
        reset: "email",
        email: values.Email,
      }
      const res = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })

      // console.log(res)
      messageApi.open({
        type: "success",
        content: "Email Updated successfully",
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      console.log(error.response.data.message)
    }
  }
  const onFinishChangePhone = async (values) => {
    console.log(values)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/reset-user`
      const body = {
        userId: edit.id,
        reset: "phone",
        phone: values.Phone,
      }
      const res = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })

      // console.log(res)
      messageApi.open({
        type: "success",
        content: "Phone Updated successfully",
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      console.log(error.response.data.message)
    }
  }
  const resetTest = async (resetType) => {
    console.log(resetType)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/reset-user`
      const body = {
        userId: edit.id,
        reset: resetType,
      }
      const res = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })

      // console.log(res)
      messageApi.open({
        type: "success",
        content:
          resetType === "toMCQ"
            ? "Test Reset to MCQ Successfully"
            : "Test Reset to Portfolio Successfully",
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      console.log(error.response.data.message)
    }
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 }, //reset the page to 1
      filters: {
        [dataIndex]: selectedKeys,
      },
    })
  }

  const handleReset = (clearFilters) => {
    clearFilters()
  }
  const exportData = async () => {
    const docs = await fetchData(true)
    const processData = docs.map((data) => {
      return {
        Id: data?._id,
        Name: data?.name,
        Phone: data?.phone,
        Email: data?.email,
        Slot: data?.batchId?.batchId,
        Category: data?.categoryCode,
      }
    })
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    const fileExtension = ".xlsx"

    const ws = XLXS.utils.json_to_sheet(processData)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLXS.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, "export-data" + fileExtension)
  }

  const renderHeader = () => {
    return (
      <Space size={16}>
        <Button
          type="default"
          icon={<ScissorOutlined />}
          onClick={() => setTableParams({ ...tableParams, filters: {} })}
          loading={loading}
        >
          Clear Filters
        </Button>
        <Button
          type="default"
          icon={<ReloadOutlined />}
          onClick={() => fetchData()}
          loading={loading}
        >
          Reload
        </Button>
        {/* <Button
          type="default"
          icon={<ExportOutlined />}
          onClick={() => exportData(data)}
          loading={loading}
        >
          Export
        </Button> */}
        {/* <Button
          type="default"
          icon={<WhatsAppOutlined style={{ color: "#45C153" }} />}
          onClick={() => openModel("", "send")}
          loading={loading}
        >
          Send Registration Link
        </Button> */}
      </Space>
    )
  }
  const openModel = (record, type) => {
    console.log(type)
    onReset()
    setError({
      visible: false,
      type: "",
      message: "",
    })
    if (type == "edit") {
      setLink(false)
      editParams({
        id: record.userId._id,
        name: record.userId.name,
        // categoryCode: record?.categoryCode,
        // batchId: record?.batchId?.batchId,
      })
    } else {
      setLink(true)
    }
    // console.log(record)
    showModal()
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
  })
  const renderStatus = (_, record) => {
    if (record?.profile != undefined) return <UserOutlined />
  }

  const columns = [
    {
      title: "",
      width: 50,
      render: (_, record) => {
        return <Space>{renderStatus(_, record)}</Space>
      },
    },

    {
      title: "Name",
      dataIndex: "name",
      render: (_, record) => <p>{record?.userId?.name}</p>,
      ...getColumnSearchProps("name"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      render: (_, record) => (
        <a href={`Tel: ${record?.userId?.phone}`}>{record?.userId?.phone}</a>
      ),
      ...getColumnSearchProps("phone"),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (_, record) => <p>{record?.userId?.email}</p>,
      ...getColumnSearchProps("email"),
    },
    {
      title: "Sale (₹)",
      dataIndex: "batchId",
      render: (_, record) => <a>&#8377; {record.amount}</a>,
    },
    {
      title: "Payment ID",
      dataIndex: "paymentId",
      render: (_, record) => <p>{record.paymentId}</p>,
      ...getColumnSearchProps("paymentId"),
    },

    {
      title: "Date",
      dataIndex: "createdAt",
      sorter: false,
      render: (date) => (
        <>
          {new Date(date).toDateString()}
          <br />
          <div style={{ color: "lightgray" }}>
            {new Date(date).toLocaleTimeString()}
          </div>
        </>
      ),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Tooltip title="Fetch User Details">
          <Button
            type="link"
            shape="circle"
            icon={<ContainerTwoTone />}
            onClick={() => showDrawer(record)}
          />
        </Tooltip>
      ),
    },
  ]

  const showDrawer = async (record) => {
    console.log(record)
    setOpen(true)
    setError(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/loadAllEnrollments/${record.userId._id}`
      // const url = `${constants.DOMAIN}/api/v1/users/fullReport/${record._id}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setUser(res.data.data)
      setStatus(false)
      setError(false)
      // console.log(res.data)
      // console.log(data);
      // if(res.data.data?.user?.userType==="CLUB_MEMBER"&&res.data.data.user?.profile?.city === undefined){
      //   setError(true)
      // }else{
      //  setError(false)
      // }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      setStatus(true)
      console.log(error)
    }
  }

  const fetchData = async (isExport = false) => {
    setLoading(true)
    try {
      let query = []
      Object.keys(tableParams.filters).forEach((k) => {
        if (
          tableParams.filters[k] !== null &&
          tableParams.filters[k].length > 0
        ) {
          query.push(`${k}=${tableParams.filters[k]}`)
        }
      })
      console.log('query-', query)
      //   name={$regex:^fin,$options:'i'}&

      const preQuery = `page=${tableParams.pagination.current}&limit=${tableParams.pagination.pageSize}&firebaseId=${currentUser._delegate.uid}&`
      const q = preQuery + query.join("&")

      const url = `${constants.NEW_DOMAIN}/api/v1/admin/coversions?${q}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      if (isExport) {
        setLoading(false)
        return res.data.data.users
      }
      setData(res.data.data.users)
      setLoading(false)
      let numberOfUsers = res.data?.data?.users?.length || 0
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total:
            numberOfUsers < tableParams.pagination.pageSize
              ? (tableParams.pagination.current - 1) * 10 + 1
              : tableParams.pagination.current * 10 + 1,
        },
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [JSON.stringify(tableParams)])
  useEffect(() => {
    socket.on("payment_successful", (raw) => {
      //updated Table Data
      setData((oldArray) => {
        // updateArr()
        oldArray.pop()
        return [raw.lead, ...oldArray]
      })
    })
  }, [socket])

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    })

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([])
    }
  }

  return (
    <>
      {contextHolder}
      <div style={{ backgroundColor: "#fff", padding: "20px" }}>
        {/* { error.visible?
      <Alert
      message={error.type}
      description={error.message}
      type={error.type}
      showIcon
      
    />:""} */}
        <Drawer
          title="Program Enrollments"
          placement="right"
          onClose={onClose}
          open={open}
          width={420}
        >
          {error ? (
            ""
          ) : (
            <Text ellipsis={true}>
              <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />{" "}
              {user?.user?.name}
            </Text>
          )}
          {status ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "300px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <p>
                  <StopTwoTone
                    style={{ fontSize: "30px" }}
                    twoToneColor={"red"}
                  />
                </p>
                <br />
                <p>NO JOURNEY FOUND</p>
              </div>
            </>
          ) : (
            <>
              {user?.enrollments.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "300px",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <p>
                    <StopTwoTone
                      style={{ fontSize: "30px" }}
                      twoToneColor={"red"}
                    />
                  </p>
                  <br />
                  <p>No Program Found</p>
                </div>
              ) : (
                <>
                  {user?.enrollments &&
                    user?.enrollments.map((enroll, key) => {
                      return (
                        <Card
                          style={{
                            marginTop: 16,
                          }}
                          loading={error}
                          type="inner"
                          title={"Program " + ++key}
                          key={key}
                        >
                          <List
                            size="small"
                            // header={<div>Header</div>}
                            // footer={<div>Footer</div>}
                            // bordered
                            dataSource={[
                              <Text ellipsis={true}>
                                Category :{" "}
                                {
                                  <Tag
                                    color={
                                      enroll?.batchId?.categoryCode === "SMM"
                                        ? "#B2A4FF"
                                        : enroll?.batchId?.categoryCode === "VE"
                                        ? "#E11299"
                                        : enroll?.batchId?.categoryCode === "CW"
                                        ? "#867070"
                                        : "#E4D0D0"
                                    }
                                  >
                                    {enroll?.batchId?.categoryCode}
                                  </Tag>
                                }
                              </Text>,
                              <Text ellipsis={true}>
                                Batch Id : {enroll?.batchId?.batchId}
                              </Text>,
                              // <Text ellipsis={true}>
                              //   <Button
                              //     type="dashed"
                              //     onClick={() => openModel(enroll, "edit")}
                              //   >
                              //     Edit
                              //   </Button>
                              //   <Button
                              //     type="dashed"
                              //     style={{ marginLeft: "10px" }}
                              //     onClick={() => showChildrenDrawer(enroll._id)}
                              //   >
                              //     Fetch Program Details
                              //   </Button>
                              // </Text>,
                            ]}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                          />
                        </Card>
                      )
                    })}
                </>
              )}
            </>
          )}
        </Drawer>
        <Modal
          title={link ? "Enter Registration Link Details" : "Edit User Details"}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[]}
        >
          {/* 
        <p>{edit.batchId}</p>
        <p>{edit.categoryCode}</p> */}
          {link ? "" : <p>Name : {edit.name}</p>}
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            style={{
              maxWidth: 600,
            }}
          >
            {link ? (
              <Form.Item
                name="Phone"
                label="Phone"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            ) : (
              <Form.Item
                name="Category"
                label="Category"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input placeholder={edit?.categoryCode} />
              </Form.Item>
            )}
            <Form.Item
              name="Batch"
              label="Batch"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input placeholder={edit.batchId} />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                {link ? "Send" : "Update"}
              </Button>
            </Form.Item>
          </Form>

          {link ? (
            ""
          ) : (
            <>
              <Divider />
              <Form
                form={form1}
                name="control-hooks"
                onFinish={onFinishChangeEmail}
                style={{
                  maxWidth: 600,
                }}
              >
                <Space.Compact block size="middle">
                  <Form.Item
                    name="Email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Update Email
                    </Button>
                  </Form.Item>
                </Space.Compact>
              </Form>
            </>
          )}
          {link ? (
            ""
          ) : (
            <>
              <Divider />
              <Form
                form={form2}
                name="control-hooks"
                onFinish={onFinishChangePhone}
                style={{
                  maxWidth: 600,
                }}
              >
                <Space.Compact block size="middle">
                  <Form.Item
                    name="Phone"
                    label="Phone"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Update Phone
                    </Button>
                  </Form.Item>
                </Space.Compact>
              </Form>
            </>
          )}
          {link ? (
            ""
          ) : (
            <>
              <Divider />
              <Space
                direction="horizontal"
                size="large"
                style={{
                  display: "flex",
                }}
              >
                <Button type="dashed" onClick={() => resetTest("toMCQ")}>
                  Reset Test to MCQ
                </Button>
                <Button type="dashed" onClick={() => resetTest("toPortfolio")}>
                  Reset Test to Portfolio
                </Button>
              </Space>
            </>
          )}
        </Modal>

        <Row>
          <Col xs={24} md={20}>
            <div className="text-3xl">Conversions</div>
            <p className="text-lg-light">
              {new Date().toDateString()} - {new Date().toLocaleTimeString()}
            </p>
          </Col>

          <Col xs={24} md={4}>
            {/* <Statistic
              title="Total Paid User"
              loading={loading}
              value={tableParams.pagination?.total}
              precision={0}
              valueStyle={{
                color: "blue",
              }}
              // prefix={<ArrowUpOutlined />}
              suffix="users"
            /> */}
          </Col>
        </Row>

        <Table
          columns={columns}
          loading={loading}
          title={renderHeader}
          dataSource={data}
          onChange={handleTableChange}
          pagination={tableParams.pagination}
          rowKey={(record) => record._id}
          scroll={{
            x: 900,
            // y: 500,
          }}
        />
      </div>
    </>
  )
}

export default PaidUser

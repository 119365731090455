import React, { useContext, useState, useEffect } from "react"
import { auth } from "../firebase"

// import {
//     GoogleAuthProvider,
//     signInWithPopup,
//     signInWithRedirect,
//     signOut,
//     onAuthStateChanged,
//     getAdditionalUserInfo,
//     signInWithCustomToken,
// } from "firebase/compat/auth"

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)
  const [roles, setRoles] = useState("")

  // function googleSignIn() {
  //     const provider = new GoogleAuthProvider()
  //     return signInWithPopup(auth, provider)
  // }

  function getToken() {
    return currentUser.getIdToken(true)
  }

  // function signInCustomToken(token) {
  //     return signInWithCustomToken(auth, token)
  // }

  // function getAdditionalUserInfoGoogle(result) {
  //     return getAdditionalUserInfo(result)
  // }

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }

  function logout() {
    return auth.signOut()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const roles = (await user.getIdTokenResult())?.claims?.roles
        setRoles(roles)
      }
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    roles,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    // googleSignIn,
    // getAdditionalUserInfoGoogle,
    // signInCustomToken,
    getToken,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

import React, { useEffect, useState } from "react"
import { Button, Card, Col, Collapse, Row, Space, Statistic, Table,Divider } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { Column, Pie } from "@ant-design/plots"
import axios from "axios"
import constants from "../../constants"
import { useAuth } from "../../contexts/authContext"
import { socket } from "../../util/socket"
import Loader from "../../util/loader"
import { DateRangePicker } from "react-date-range"
import { addDays } from "date-fns"

const CatalysAnalytics = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const { currentUser, roles } = useAuth()
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(new Date().setHours(0, 0, 0)),
      endDate: new Date(new Date().setHours(0, 0, 0)),
      key: "selection",
    },
  ])

  const isAdmin =
    roles.includes("ADMIN") ||
    roles.includes("ANALYTICS") ||
    roles.includes("SALES_ADMIN") ||
    roles.includes("CATALYS")

  const fetchData = async () => {
    let startDate = dateState[0].startDate
    let endDate = dateState[0].endDate
    let sd = new Date(startDate.getTime()).toISOString()
    let ed = new Date(endDate.getTime())
    ed = addDays(ed.setHours(0, 0, 0), 1).toISOString()

    try {
      setLoading(true)
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/thridPartyAnalytics?firebaseId=${currentUser._delegate.uid}&startDate=${sd}&endDate=${ed}`
      const res = await axios.get(url,{
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      const responseData = res.data?.data
      if (!responseData) {
        return <h1>Error!</h1>
      }

      let revenueData = []
      for (let i = 0; i < responseData.todaysRevenuePerPOC?.length || 0; i++) {
        console.log(i)
        revenueData.push({
          poc: constants.idToPOC[responseData.todaysRevenuePerPOC[i]._id],
          count:
            i < responseData.todaysPaymentPerPOC?.length
              ? responseData.todaysPaymentPerPOC[i].count
              : 0,
          revenue: responseData.todaysRevenuePerPOC[i].amount,
        })
      }

      responseData.revenueData = revenueData

      setData(responseData)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    socket.on("new_lead", (raw) => {
      //updateLeadCount
      if (!isAdmin) {
        setData((oldData) => {
          let today = oldData?.todaysLeadForPOC + 1
          let total = oldData?.totalLeadCount + 1
          return { ...oldData, todaysLeadForPOC: today, totalLeadCount: total }
        })
      } else {
        setData((oldData) => {
          let today = oldData?.todaysLead + 1
          let total = oldData?.overAllLead + 1
          return { ...oldData, todaysLead: today, overAllLead: total }
        })
      }
    })
    //payment
    socket.on("payment_successful", (raw) => {
      // if(!isAdmin){
      //   setData(oldData=>{
      //   let todayPayment = oldData?.todaysPaymentForPOC +1
      //   // let muConversions = oldData?.totalPaidUser +1
      //   return {...oldData,todaysLeadForPOC:todayPayment}
      //   })
      // }else{
      //   setData(oldData=>{
      //     let todayPayment = oldData?.todaysPayment +1
      //     let totalRZ = oldData?.data?.todaysPayment_RZPY +1
      //     return {...oldData,todaysPayment:todayPayment,todaysPayment_RZPY:totalRZ}
      //     })
      // }
      fetchData()
    })
  }, [socket])

  useEffect(() => {
    fetchData()
  }, [])

  const configBarGraph = (index, alias) => {
    return {
      loading: loading || !data || !data[index] || data[index].length == 0,
      data: data ? data[index] : [],
      xField: "_id",
      yField: "count",
      label: {
        position: "middle",

        style: {
          fill: "#FFFFFF",
          // opacity: 0.6,
        },
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      meta: {
        _id: {
          alias: alias.x,
        },
        count: {
          alias: alias.y,
        },
      },

      minColumnWidth: 20,
      maxColumnWidth: 50,
    }
  }
  const configPieChart = (index) => {
    const arr = data ? data[index] : []
    const newArr = arr.map((v) => {
      // console.log("*****", v._id)
      v._id = constants.idToPOC[v._id] || v._id
      return v
    })

    return {
      loading: loading || !data || !data[index] || data[index].length == 0,
      data: newArr,
      appendPadding: 10,
      angleField: "count",
      colorField: "_id",
      radius: 1,
      innerRadius: 0.6,
      label: {
        type: "inner",
        offset: "-50%",
        content: "{value}",
        style: {
          textAlign: "center",
          fontSize: 14,
        },
      },
      interactions: [
        {
          type: "element-selected",
        },
        {
          type: "element-active",
        },
      ],
    }
  }

  const columns = [
    {
      title: "POC",
      dataIndex: "poc",
      key: "poc",
      render: (text) => <a>{text}</a>,
    },
    {
      title: (
        <>
          Payment Count
          <br />
          <small style={{ color: "gray" }}>
            {dateState[0].startDate.toLocaleDateString()} -{" "}
            {dateState[0].endDate.toLocaleDateString()}
          </small>
        </>
      ),
      dataIndex: "count",
      key: "count",
      render: (text) => <p>{text || 0}</p>,
      align: "center",
    },
    {
      title: (
        <>
          Sale
          <br />
          <small style={{ color: "gray" }}>
            {dateState[0].startDate.toLocaleDateString()} -{" "}
            {dateState[0].endDate.toLocaleDateString()}
          </small>
        </>
      ),
      dataIndex: "revenue",
      key: "revenue",
      render: (text) => <p>&#8377; {text || 0}</p>,
    },
  ]

  if (!data) return <Loader />

  return (
    <div style={{ margin: "0 10px" }}>
      <p className="text-3xl" style={{ margin: "10px" }}>
        Analytics
      </p>
      <p
        className="text-lg-light"
        style={{ margin: "10px", marginBottom: "30px" }}
      >
        last updated : {new Date().toLocaleDateString()}
      </p>

      <Row gutter={[16, 24]}>
        <Col xs={22}>
          <DateRangePicker
            onChange={(item) => setDateState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={dateState}
            direction="horizontal"
          />
          <Button
            type="primary"
            style={{ margin: "0 20px" }}
            onClick={fetchData}
          >
            Apply
          </Button>
        </Col>
        <Divider orientation="left" style={{  borderColor: '#ffffff' }}>Lead & Paid User Data</Divider>
        <>
          <Col xs={12} md={4}>
            <Card>
              <Statistic
                title={
                  <>
                    Lead Count
                    <br />
                    <small>
                      {dateState[0].startDate.toLocaleDateString()} -{" "}
                      {dateState[0].endDate.toLocaleDateString()}
                    </small>
                  </>
                }
                loading={loading}
                value={data?.todaysLead}
                precision={0}
                valueStyle={{
                  color: "#3f8600",
                }}
                // prefix={<ArrowUpOutlined />}
              />
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card style={{ backgroundColor: "beige" }}>
              <Statistic
                title={
                  <>
                    Pro Leads Count
                    <br />
                    <small>
                      {dateState[0].startDate.toLocaleDateString()} -{" "}
                      {dateState[0].endDate.toLocaleDateString()}
                    </small>
                  </>
                }
                loading={loading}
                value={data?.todaysProLeads}
                precision={0}
                valueStyle={{
                  color: "#3f8600",
                }}
                // prefix={<ArrowUpOutlined />}
              />
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card>
              <Statistic
                title={
                  <>
                    OverAll Leads
                    <br />
                    <small>
                     Starting 2023
                    </small>
                  </>
                }
                loading={loading}
                value={data?.overAllLead}
                precision={0}
                valueStyle={{
                  color: "#3f8600",
                }}
                // prefix={<ArrowUpOutlined />}
              />
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card>
              <Statistic
                title={
                  <>
                    OverAll Paid Users
                    <br />
                    <small>
                     Starting 2023
                    </small>
                  </>
                }
                loading={loading}
                value={data?.overAllPaidUser}
                precision={0}
                valueStyle={{
                  color: "#3f8600",
                }}
                // prefix={<ArrowUpOutlined />}
              />
            </Card>
          </Col>
          <Divider style={{  borderColor: '#ffffff' }} orientation="left">Transaction Data ( OverAll )</Divider>
          <Col xs={12} md={4}>
            <Card>
              <Statistic
                // title="Todays Payment Count"
                title={
                  <>
                    Total Payment Count
                    <br />
                    <small>
                      {dateState[0].startDate.toLocaleDateString()} -{" "}
                      {dateState[0].endDate.toLocaleDateString()}
                    </small>
                  </>
                }
                loading={loading}
                value={data?.todaysPayment}
                precision={0}
                valueStyle={{
                  color: "#3f8600",
                }}
                // prefix={<ArrowUpOutlined />}
              />
            </Card>
          </Col>
          {/* <Col xs={12} md={4}>
            <Card>
              <Statistic
                title="Todays Payment RZPY"
                loading={loading}
                value={data?.todaysPayment_RZPY}
                precision={0}
                valueStyle={{
                  color: "#3f8600",
                }}
                // prefix={<ArrowUpOutlined />}
              />
            </Card>
          </Col> */}
          <Col xs={12} md={4}>
            <Card>
              <Statistic
                title={
                  <>
                    Total Sale
                    <br />
                    <small>
                      {dateState[0].startDate.toLocaleDateString()} -{" "}
                      {dateState[0].endDate.toLocaleDateString()}
                    </small>
                  </>
                }
                loading={loading}
                prefix="₹"
                value={data?.todaysSale}
                precision={0}
                valueStyle={{
                  color: "#3f8600",
                }}
                // prefix={<ArrowUpOutlined />}
              />
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card>
              <Statistic
                title={
                  <>
                    Total Sale (B2B)
                    <br />
                    <small>
                      {dateState[0].startDate.toLocaleDateString()} -{" "}
                      {dateState[0].endDate.toLocaleDateString()}
                    </small>
                  </>
                }
                loading={loading}
                prefix="₹"
                value={data?.todaysSale_B2B}
                precision={0}
                valueStyle={{
                  color: "#3f8600",
                }}
                // prefix={<ArrowUpOutlined />}
              />
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card>
              <Statistic
                title={
                  <>
                    Total Sale (B2C)
                    <br />
                    <small>
                      {dateState[0].startDate.toLocaleDateString()} -{" "}
                      {dateState[0].endDate.toLocaleDateString()}
                    </small>
                  </>
                }
                loading={loading}
                prefix="₹"
                value={data?.todaysSale_B2C}
                precision={0}
                valueStyle={{
                  color: "#3f8600",
                }}
                // prefix={<ArrowUpOutlined />}
              />
            </Card>
          </Col>
          
          
          {data?.totalPaymentByOrderType && <Divider style={{  borderColor: '#ffffff' }} orientation="left">Transaction Data ( Program Wise )</Divider>}

          {data?.totalPaymentByOrderType && data?.totalPaymentByOrderType?.map((elem,index)=>{
            return <Col xs={12} md={4} key={index}>
           <Card>
  <Statistic
    title={
      <>
        Program ({elem._id?.type})
        <br />
        <small>
          {dateState[0].startDate.toLocaleDateString()} -{" "}
          {dateState[0].endDate.toLocaleDateString()}
        </small>
      </>
    }
    loading={loading}
    value={elem?.count}  // Original amount before conversion
    precision={0}
    valueStyle={{ color: "#3f8600" }}  // Styling for the value
  />
  <>
        <h3>₹{elem?.totalAmount} </h3> 
      </>
</Card>
          </Col>
          })}
           {data?.totalPaymentByCategoryType && <Divider style={{  borderColor: '#ffffff' }} orientation="left">Transaction Data ( Category Type Wise )</Divider>}

{data?.totalPaymentByCategoryType && data?.totalPaymentByCategoryType?.map((elem,index)=>{
  return <Col xs={12} md={4} key={index}>
 <Card>
<Statistic
title={
<>
{elem._id}
<br />
<small>
{dateState[0].startDate.toLocaleDateString()} -{" "}
{dateState[0].endDate.toLocaleDateString()}
</small>
</>
}
loading={loading}
value={elem?.count}  // Original amount before conversion
precision={0}
valueStyle={{ color: "#3f8600" }}  // Styling for the value
/>
<>
<h3>₹{elem?.totalAmount} </h3> 
</>
</Card>
</Col>
})}
{data?.currencyConversion &&<Divider style={{  borderColor: '#ffffff' }} orientation="left">Transaction Data ( Currency )</Divider>}

{data?.currencyConversion && data.currencyConversion.map((elem)=>{
  return <Col xs={12} md={4} key={elem._id}>
 <Card>
<Statistic
title={
<>
Total Payments ({elem._id})
<br />
<small>
{dateState[0].startDate.toLocaleDateString()} -{" "}
{dateState[0].endDate.toLocaleDateString()}
</small>
</>
}
loading={loading}
prefix={elem._id}  // Currency symbol or ID as prefix
value={elem?.totalAmount}  // Original amount before conversion
precision={0}
valueStyle={{ color: "#3f8600" }}  // Styling for the value
/>
<>
<h3>₹{elem?.convertedToINR} </h3> 
</>
</Card>
</Col>
})}

{data?.lastFiveB2B &&<Divider style={{  borderColor: '#ffffff' }} orientation="left">Last 5 B2B Payment ( {new Date().toDateString()} )</Divider>}

{data?.lastFiveB2B && data?.lastFiveB2B?.map((elem)=>{
  return <Col xs={12} md={4} key={elem._id}>
 <Card>
<Statistic
title={
<>
Partner
<br />
<small>
{elem?.userId?.schoolId?.name}
</small>
</>
}
loading={loading}
prefix='INR'
value={elem?.amount}  // Original amount before conversion
precision={0}
valueStyle={{ color: "#3f8600" }}  // Styling for the value
/>
<>
<h3>code : {elem?.userId?.schoolId?.schoolId} </h3> 
</>
</Card>
</Col>
})}

{data?.databyLeadType &&<Divider style={{  borderColor: '#ffffff' }} orientation="left">Marketing Data ( lead Type )</Divider>}

{data?.databyLeadType && data.databyLeadType.map((elem)=>{
  return <Col xs={12} md={4} key={elem._id}>
 <Card>
<Statistic
title={
<>
{elem._id ===""?"New":elem._id}
<br />
<small>
{dateState[0].startDate.toLocaleDateString()} -{" "}
{dateState[0].endDate.toLocaleDateString()}
</small>
</>
}
loading={loading}
value={elem?.count}  // Original amount before conversion
precision={0}
valueStyle={{ color: "#3f8600" }}  // Styling for the value
/>
</Card>
</Col>
})}
         

          <Col xs={24} md={24}>
            <Card
              title={
                <>
                  Leads Per POC
                  <br />
                  <small style={{ color: "gray" }}>
                    {dateState[0].startDate.toLocaleDateString()} -{" "}
                    {dateState[0].endDate.toLocaleDateString()}
                  </small>
                </>
              }
            >
              <Pie {...configPieChart("todaysLeadPerPOC")} />
            </Card>
          </Col>
          <Col xs={24} md={24}>
            <Table
              title={() => <h3>Revenue Per POC</h3>}
              pagination={{
                hideOnSinglePage: true,
                responsive: true,
                pageSize: 50,
              }}
              columns={columns}
              dataSource={data.revenueData}
            />
          </Col>
          {/* <Col xs={24} md={24}>
              <Card title="Today's Payment per POC">
                <Pie {...configPieChart("todaysPaymentPerPOC")} />
              </Card>
            </Col> */}
          {/* <Col xs={24} md={24}>
              <Card title="OverAll Leads per POC">
                <Pie {...configPieChart("totalLeadPerPOC")} />
              </Card>
            </Col> */}
          <Col xs={24} md={24}>
            <Card title="Total Leads per Category">
              <Pie {...configPieChart("totalLeadPerCategory")} />
            </Card>
          </Col>
          <Col xs={24} md={24}>
            <Card title="This month Leads summary">
              <Column
                {...configBarGraph("leadsPerDay", {
                  x: "dates",
                  y: "Leads",
                })}
                loading={loading}
              />
            </Card>
          </Col>
          <Col xs={24} md={24}>
            <Card title="This month Payment summary">
              <Column
                {...configBarGraph("paymentPerDay", {
                  x: "dates",
                  y: "payments",
                })}
                loading={loading}
              />
            </Card>
          </Col>
        </>
      </Row>
    </div>
  )
}

export default CatalysAnalytics

import React from "react"
import {
  SoundTwoTone,
  HomeTwoTone,
  VideoCameraTwoTone,
  TeamOutlined,
  DollarCircleTwoTone,
  AreaChartOutlined,
  UserAddOutlined,
  QuestionCircleOutlined,
  NodeIndexOutlined,
  RocketOutlined,
  VideoCameraOutlined,
  GoldTwoTone,
  ArrowRightOutlined,
  PlusCircleOutlined,
  ExportOutlined,
  SettingTwoTone,
  FileSyncOutlined,
  BuildOutlined,
  MessageOutlined
} from "@ant-design/icons"



const navList = (roles) => {
  if (roles.includes("ADMIN"))
    return [
      {
        key: "sip",
        icon: React.createElement(SoundTwoTone),
        label: "B2C",
        children: [
          {
            key: "/b2c/marketing",
            label: "Leads CRM",
            icon: React.createElement(UserAddOutlined),
          },
          // {
          //   key: "/b2c/paid-users",
          //   label: "Paid Users",
          //   icon: React.createElement(DollarCircleTwoTone),
          // },
          {
            key: "/analytics",
            label: "Analytics",
            icon: React.createElement(AreaChartOutlined),
          },
          // {
          //   key: "/analytics/fam",
          //   label: "Analytics:FamPay",
          //   icon: React.createElement(AreaChartOutlined),
          // },
        ],
      },
      {
        key: "/businessanalytics",
        icon: React.createElement(FileSyncOutlined),
      label: "Business Analytics(POC)",
      },
      {
        key: "school",
        icon: React.createElement(HomeTwoTone),
        label: "B2B",
        children: [
          {
            key: "/b2b/addLead",
            icon: React.createElement(PlusCircleOutlined),
            label: "Leads",
          },
          // {
          //   key: "/b2b/school",
          //   icon: React.createElement(PlusCircleOutlined),
          //   label: "School",
          // },
        ],
      },
      {
        key: "foreign",
        icon: React.createElement(SoundTwoTone),
        label: "Foreign Program",
        children: [
          {
            key: "/foreign/marketing",
            label: "Leads CRM",
            icon: React.createElement(UserAddOutlined),
          },
          // {
          //   key: "/b2c/paid-users",
          //   label: "Paid Users",
          //   icon: React.createElement(DollarCircleTwoTone),
          // },
          // {
          //   key: "/b2c/analytics",
          //   label: "Analytics",
          //   icon: React.createElement(AreaChartOutlined),
          // },
        ],
      },
      {
        key: "/users",
        icon: React.createElement(TeamOutlined),
        label: "Overall Paid Users",
      },
      {
        key: "/courses",
        icon: React.createElement(RocketOutlined),
        label: "Courses",
        children: [
          {
            key: "/courses/videos",
            label: "Videos",
            icon: React.createElement(VideoCameraOutlined),
          },
          {
            key: "/courses/assessment",
            label: "Assessment",
            icon: React.createElement(QuestionCircleOutlined),
          },
        ],
      },
      {
        key: "/batch",
        icon: React.createElement(TeamOutlined),
        label: "Batches",
        children: [
          {
            key: "/batch/modify",
            label: "Craft Batches",
            icon: React.createElement(ArrowRightOutlined),
          },
          {
            key: "/batch/analytics",
            label: "Active Batch Insights",
            icon: React.createElement(ArrowRightOutlined),
          },
        ],
      },
      {
        key: "/internship",
        icon: React.createElement(NodeIndexOutlined),
        label: "Internship",
        children: [
          {
            key: "/internship/slots",
            label: "Internship Slots",
            icon: React.createElement(ArrowRightOutlined),
          },
          {
            key: "/internship/user",
            label: "Assign Company",
            icon: React.createElement(ArrowRightOutlined),
          },
          {
            key: "/internship/feedback",
            label: "Feedback",
            icon: React.createElement(ArrowRightOutlined),
          },
        ],
      },
      {
        key: "/company",
        icon: React.createElement(GoldTwoTone),
        label: "Company",
      },
      {
        key: "/internship/alpha",
        icon: React.createElement(ArrowRightOutlined),
        label: "Alpha Internship",
      },
      {
        key: "/export",
        icon: React.createElement(ExportOutlined),
        label: "Miscellaneous",
      },
      {
        key: "/config",
        icon: React.createElement(SettingTwoTone),
      label: "Agent Configuration",
      },
      {
        key: "/cms/json",
        icon: React.createElement(FileSyncOutlined),
      label: "JSON EDITOR (CMS)",
      },
      {
        key: "/messanger",
        icon: React.createElement(MessageOutlined),
      label: "Messanger",
      },
    ]

    if (roles.includes("OPS_LIVE"))
      return [
        {
          key: "/batch",
          icon: React.createElement(TeamOutlined),
          label: "Batches",
          children: [
            {
              key: "/live/batch/modify",
              label: "Craft Batches",
              icon: React.createElement(ArrowRightOutlined),
            },
            {
              key: "/live/batch/analytics",
              label: "Active Batch Insights",
              icon: React.createElement(ArrowRightOutlined),
            },
          ],
        },
     
      ]

    if (roles.includes("SUPPORT"))
    return [
      {
        key: "sip",
        icon: React.createElement(SoundTwoTone),
        label: "B2C",
        children: [
          {
            key: "/b2c/marketing",
            label: "Leads CRM",
            icon: React.createElement(UserAddOutlined),
          },
          
        ],
      },
      
      {
        key: "/users",
        icon: React.createElement(TeamOutlined),
        label: "Overall Paid Users",
      },
    
    ]

  if(roles.includes("OPERATIONS"))
  return [
    {
      key: "sip",
      icon: React.createElement(SoundTwoTone),
      label: "B2C",
      children: [
        {
          key: "/b2c/marketing",
          label: "Leads CRM",
          icon: React.createElement(UserAddOutlined),
        },
        
      ],
    },
    {
      key: "school",
      icon: React.createElement(HomeTwoTone),
      label: "B2B",
      children: [
        {
          key: "/b2b/addLead",
          icon: React.createElement(PlusCircleOutlined),
          label: "Leads",
        },
        // {
        //   key: "/b2b/school",
        //   icon: React.createElement(PlusCircleOutlined),
        //   label: "School",
        // },
      ],
    },
    {
      key: "/users",
      icon: React.createElement(TeamOutlined),
      label: "Overall Paid Users",
    },
    {
      key: "/courses",
      icon: React.createElement(RocketOutlined),
      label: "Courses",
      children: [
        {
          key: "/courses/videos",
          label: "Videos",
          icon: React.createElement(VideoCameraOutlined),
        },
        {
          key: "/courses/assessment",
          label: "Assessment",
          icon: React.createElement(QuestionCircleOutlined),
        },
      ],
    },
    {
      key: "/batch",
      icon: React.createElement(TeamOutlined),
      label: "Batches",
      children: [
        {
          key: "/batch/modify",
          label: "Craft Batches",
          icon: React.createElement(ArrowRightOutlined),
        },
        {
          key: "/batch/analytics",
          label: "Active Batch Insights",
          icon: React.createElement(ArrowRightOutlined),
        },
      ],
    },
    {
      key: "/internship",
      icon: React.createElement(NodeIndexOutlined),
      label: "Internship",
      children: [
        {
          key: "/internship/slots",
          label: "Internship Slots",
          icon: React.createElement(ArrowRightOutlined),
        },
        {
          key: "/internship/user",
          label: "Assign Company",
          icon: React.createElement(ArrowRightOutlined),
        },
        {
          key: "/internship/feedback",
          label: "Feedback",
          icon: React.createElement(ArrowRightOutlined),
        },
      ],
    },
    {
      key: "/company",
      icon: React.createElement(GoldTwoTone),
      label: "Company",
    },
    {
      key: "/internship/alpha",
      icon: React.createElement(ArrowRightOutlined),
      label: "Alpha Internship",
    },
    {
      key: "/export",
      icon: React.createElement(ExportOutlined),
      label: "Miscellaneous",
    },
  
  ]

  if (roles.includes("SALES_B2C") && roles.includes("SALES_B2B"))
    return [
      {
        key: "sip",
        icon: React.createElement(SoundTwoTone),
        label: "B2C",
        children: [
          {
            key: "/b2c/marketing",
            label: "Leads CRM",
            icon: React.createElement(UserAddOutlined),
          },
          {
            key: "/b2c/paid-users",
            label: "Paid Users",
            icon: React.createElement(DollarCircleTwoTone),
          },
          {
            key: "/b2c/analytics",
            label: "Analytics",
            icon: React.createElement(AreaChartOutlined),
          },
          {
            key: "/b2c/pocanalytics",
            label: "Poc Analytics",
            icon: React.createElement(FileSyncOutlined),
          },
        ],
      },
      {
        key: "/b2b/addLead",
        icon: React.createElement(PlusCircleOutlined),
        label: "Add New Lead",
      }
    ]

  if (roles.includes("SALES_B2C") || roles.includes("SALES_ADMIN"))
    return [
      {
        key: "sip",
        icon: React.createElement(SoundTwoTone),
        label: "B2C",
        children: [
          {
            key: "/b2c/marketing",
            label: "Leads CRM",
            icon: React.createElement(UserAddOutlined),
          },
          {
            key: "/b2c/paid-users",
            label: "Paid Users",
            icon: React.createElement(DollarCircleTwoTone),
          },
          {
            key: "/b2c/analytics",
            label: "Analytics",
            icon: React.createElement(AreaChartOutlined),
          },
          {
            key: "/b2c/pocanalytics",
            label: "Poc Analytics",
            icon: React.createElement(FileSyncOutlined),
          },
        ],
      },
    ]

    if (roles.includes("FOREIGN_B2C"))
    return [
      {
        key: "foreign",
        icon: React.createElement(SoundTwoTone),
        label: "Foreign Program",
        children: [
          {
            key: "/foreign/marketing",
            label: "Leads CRM",
            icon: React.createElement(UserAddOutlined),
          },
          // {
          //   key: "/b2c/paid-users",
          //   label: "Paid Users",
          //   icon: React.createElement(DollarCircleTwoTone),
          // },
          // {
          //   key: "/b2c/analytics",
          //   label: "Analytics",
          //   icon: React.createElement(AreaChartOutlined),
          // },
        ],
      },
    ]

  if (roles.includes("SALES_B2B"))
    return [
      // {
      //   key: "/b2b/school",
      //   icon: React.createElement(HomeTwoTone),
      //   label: "School",
      // },
      {
        key: "/b2b/addLead",
        icon: React.createElement(PlusCircleOutlined),
        label: "Add New Lead",
      },
    ]
  if (roles.includes("MENTOR"))
    return [
      {
        key: "/internship/feedback",
        icon: React.createElement(HomeTwoTone),
        label: "Feedback",
      },
    ]

    if (roles.includes("AFS"))
      return [
        {
          key: "/b2b/addLead",
          icon: React.createElement(BuildOutlined),
          label: "AFS Schools",
        },
      ]
    if (roles.includes("GLENTREE"))
      return [
        {
          key: "/b2b/addLead",
          icon: React.createElement(BuildOutlined),
          label: "GLENTREE Schools",
        },
      ]

  if (roles.includes("ANALYTICS"))
    return [
      {
        key: "/analytics",
        label: "Analytics",
        icon: React.createElement(AreaChartOutlined),
      },
    ]

  if (roles.includes("FAMPAY"))
    return [
      {
        key: "/analytics/fam",
        label: "Analytics",
        icon: React.createElement(AreaChartOutlined),
      },
    ]
  if (roles.includes("CATALYS"))
      return [
        {
          key: "/crm/leads",
          label: "Leads",
          icon: React.createElement(UserAddOutlined),
        },
        {
          key: "/crm/analytics",
          label: "Analytics",
          icon: React.createElement(AreaChartOutlined),
        },
        {
          key: "/crm/utmanalytics",
          label: "UTM Analytics",
          icon: React.createElement(AreaChartOutlined),
        },
      ]
}

export default navList

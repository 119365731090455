import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useAuth } from "../contexts/authContext"

const Authorized = ({ allowedRoles }) => {
  const { currentUser, roles } = useAuth()
  const location = useLocation()

  console.log(roles)
  //   return allowedRoles.includes(role) ? (
  return allowedRoles.find((role) => roles?.includes(role)) ? (
    <Outlet />
  ) : currentUser ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  )
}

export default Authorized

import { Card } from "antd"
import React from "react"
import { StopTwoTone } from "@ant-design/icons"
import { Link } from "react-router-dom"

const UnAuthorized = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <StopTwoTone style={{ fontSize: "50px" }} twoToneColor={"red"} />
      <h1>Un-Authorized</h1>
      <p>
        Please{" "}
        <span>
          <Link to="/signin">Login</Link>
        </span>{" "}
        again.
      </p>
    </div>
  )
}

export default UnAuthorized

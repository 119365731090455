import React, { useEffect, useState } from "react"
import { Card, Col, Row, Space, Statistic } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { Column, Pie } from "@ant-design/plots"
import axios from "axios"
import constants from "../../constants"
import { useAuth } from "../../contexts/authContext"
import youvahLogo from "../../images/logo_white_bg.png"
import famLogo from "../../images/famFullLogo.svg"
import useView from "../../hooks/useView"

const ThirdPartyAnalytics = ({ partner }) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const { currentUser, roles } = useAuth()
  const { isMobile } = useView()
  const isAdmin = roles.includes("ADMIN") || roles.includes("ANALYTICS")
  const fetchData = async () => {
    setLoading(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/analytics?firebaseId=${currentUser._delegate.uid}`
      const res = await axios.get(url,{
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setData(res.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const configBarGraph = (index, alias) => {
    return {
      loading: loading || !data || !data[index] || data[index].length == 0,
      data: data ? data[index] : [],
      xField: "_id",
      yField: "count",
      label: {
        position: "middle",

        style: {
          fill: "#FFFFFF",
          // opacity: 0.6,
        },
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      meta: {
        _id: {
          alias: alias.x,
        },
        count: {
          alias: alias.y,
        },
      },

      minColumnWidth: 20,
      maxColumnWidth: 50,
    }
  }

  const configPieChart = (index) => {
    return {
      loading: loading || !data || !data[index] || data[index].length == 0,
      data: data ? data[index] : [],
      appendPadding: 10,
      angleField: "count",
      colorField: "_id",
      radius: 1,
      innerRadius: 0.6,
      label: {
        type: "inner",
        offset: "-50%",
        content: "{value}",
        style: {
          textAlign: "center",
          fontSize: 14,
        },
      },
      interactions: [
        {
          type: "element-selected",
        },
        {
          type: "element-active",
        },
      ],
    }
  }

  return (
    <div style={{ margin: "0 10px" }}>
      <div
        style={{
          display: "flex",
          gap: "20px",
          alignItems: isMobile ? "center" : "self-end",
        }}
      >
        <img src={youvahLogo} width={isMobile ? "150px" : "180px"} /> <h1>X</h1>
        <img src={famLogo} width={isMobile ? "80px" : "150px"} />
      </div>
      <p
        className="text-lg-light"
        style={{ margin: "10px", marginBottom: "30px" }}
      >
        Report : {new Date().toDateString()}
      </p>
      <Row gutter={[16, 24]}>
        <Col xs={12} md={6}>
          <Card>
            <Statistic
              title="Todays Lead Count"
              loading={loading}
              value={data?.todaysLead}
              precision={0}
              valueStyle={{
                color: "#3f8600",
              }}
              // prefix={<ArrowUpOutlined />}
            />
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card>
            <Statistic
              title="Todays Payment Count"
              loading={loading}
              value={data?.todaysPayment}
              precision={0}
              valueStyle={{
                color: "#3f8600",
              }}
            />
          </Card>
        </Col>

        <Col xs={12} md={6}>
          <Card>
            <Statistic
              title="Overall Leads"
              loading={loading}
              value={data?.overAllLead}
              precision={0}
              valueStyle={{
                color: "#3f8600",
              }}
              // prefix={<ArrowUpOutlined />}
            />
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card>
            <Statistic
              title="Overall PAID User"
              loading={loading}
              value={data?.overAllPaidUser}
              precision={0}
              valueStyle={{
                color: "#3f8600",
              }}
              // prefix={<ArrowUpOutlined />}
            />
          </Card>
        </Col>

        <Col xs={24} md={24}>
          <Card title="This month Leads summary">
            <Column
              {...configBarGraph("leadsPerDay", {
                x: "dates",
                y: "Leads",
              })}
              loading={loading}
            />
          </Card>
        </Col>
        <Col xs={24} md={24}>
          <Card title="This month Payment summary">
            <Column
              {...configBarGraph("paymentPerDay", {
                x: "dates",
                y: "payments",
              })}
              loading={loading}
            />
          </Card>
        </Col>
        <Col xs={24} md={24}>
          <Card title="Total Leads per Category">
            <Pie {...configPieChart("totalLeadPerCategory")} />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default ThirdPartyAnalytics

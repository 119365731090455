import firebase from "firebase/compat/app"
import "firebase/compat/auth"
// import 'firebase/compat/firestore';

let app

// console.log(process.env.REACT_APP_FIREBASE_API_KEY_DEV) ;
// console.log(process.env.NODE_ENV) ;

if (process.env.NODE_ENV === "development" || true) {
  app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY_DEV,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_DEV,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_DEV,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_DEV,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_DEV,
    appId: process.env.REACT_APP_FIREBASE_APP_ID_DEV,
  })
} else {
  app = firebase.initializeApp({
    // apiKey: process.env.REACT_APP_FIREBASE_API_KEY_PROD,
    // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_PROD,
    // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_PROD,
    // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PROD,
    // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_PROD,
    // appId: process.env.REACT_APP_FIREBASE_APP_ID_PROD,
    // apiKey: "AIzaSyAOynkTZ_MTC2nEwZ4BkUqOTcD4Xvkb_n8",
    // authDomain: "youvah2023.firebaseapp.com",
    // projectId: "youvah2023",
    // storageBucket: "youvah2023.appspot.com",
    // messagingSenderId: "1038558568344",
    // appId: "1:1038558568344:web:79aeb414cea54f9510d5ed",
  })
}

export const auth = app.auth()
export default app

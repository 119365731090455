import React, { useEffect, useState, useRef } from "react"
import {
  Space,
  Table,
  Tag,
  Button,
  Input,
  Row,
  Col,
  Statistic,
  Tooltip,
  Modal,
  Alert,
  message,
  Divider,
} from "antd"
import {
  SearchOutlined,
  ScissorOutlined,
  ReloadOutlined,
  ExportOutlined,
  EditOutlined,
  UserOutlined,
  WhatsAppOutlined,
  TagTwoTone,
} from "@ant-design/icons"
import axios from "axios"
import constants from "../../constants"
import * as FileSaver from "file-saver"
import XLXS from "sheetjs-style"
import { Form, Select } from "antd"
import Company from "../Company"
import useView from "../../hooks/useView"
const { Option } = Select
const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 16,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 5,
    span: 16,
  },
}
const AssignCompany = () => {
  const [data, setData] = useState()
  const [slot, setSlot] = useState()
  const [company, setCompany] = useState()
  const [category, setCategory] = useState()
  const [loading, setLoading] = useState(false)
  const searchInput = useRef(null)
  const {isMobile}= useView()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      position: ["topCenter"],
    },
    filters: {},
  })
  const [messageApi, contextHolder] = message.useMessage()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const onReset = () => {
    form.resetFields()
  }
  const fetchSlot = async () => {
    setLoading(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/slot/?allSlot=true`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setSlot(res.data.data)
      console.log(res)
      // console.log(data);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  const fetchCompany = async () => {
    setLoading(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/company/?type=BETA&limit=30`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setCompany(res.data.data)
      console.log(res)
      // console.log(data);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  const fetchCategory = async () => {
    setLoading(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/category`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setCategory(res.data.data.docs)
      console.log(res)
      // console.log(data);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let uid = []
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      )
      selectedRows.map((row, key) => {
        uid.push({
          id: row?._id,
          name: row?.userId?.name,
          phone: row?.userId?.phone,
          countryCode:row?.userId?.countryCode
        })
      })
      console.log(uid)
      setSelectedRowKeys(uid)
    },
  }
  const [form] = Form.useForm()
  const [form1] = Form.useForm()

  const onFinishCompany = async (values) => {
    console.log(selectedRowKeys)
    setLoading(true)
    try {
      const body = {
        users: selectedRowKeys,
        companyId: values.Company,
        slotId: form1.getFieldValue("Slot"),
      }
      const url = `${constants.NEW_DOMAIN}/api/v1/user/company/assign`
      const res = await axios.put(url, body, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      // console.log(res)
      messageApi.open({
        type: "success",
        content: "Company Assigned successfully",
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      //  console.log(error.response.data.message);
    }
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 }, //reset the page to 1
      filters: {
        [dataIndex]: selectedKeys,
      },
    })
  }
  const onFinishSearch = async (values) => {
    console.log(values)
    fetchData(values.Slot, values.Category)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
  }
  const exportData = async () => {
    const docs = await fetchData(true)
    const processData = docs.map((data) => {
      return {
        Id: data?._id,
        Name: data?.name,
        Phone: data?.phone,
        Email: data?.email,
        Slot: data?.batchId?.batchId,
        Category: data?.categoryCode,
      }
    })
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    const fileExtension = ".xlsx"

    const ws = XLXS.utils.json_to_sheet(processData)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLXS.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, "export-data" + fileExtension)
  }

  const renderHeader = () => {
    return (
      <Space size={16}>
        <Button
          type="default"
          icon={<ScissorOutlined />}
          onClick={() => setTableParams({ ...tableParams, filters: {} })}
          loading={loading}
        >
          Clear Filters
        </Button>
        <Button
          type="default"
          disabled
          icon={<ReloadOutlined />}
          onClick={() => fetchData()}
          loading={loading}
        >
          Reload
        </Button>
        <Button
          type="default"
          disabled
          icon={<ExportOutlined />}
          onClick={() => exportData(data)}
          loading={loading}
        >
          Export
        </Button>
        <Button
          type="default"
          icon={<TagTwoTone />}
          onClick={() => openModel()}
          loading={loading}
        >
          Assign Company
        </Button>
      </Space>
    )
  }
  const openModel = () => {
    showModal()
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        hello {confirm}
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
  })
  const renderStatus = (_, record) => {
    if (record?.profile != undefined) return <UserOutlined />
  }

  const columns = [
    {
      title: "",
      width: 50,
      render: (_, record) => {
        return <Space>{renderStatus(_, record)}</Space>
      },
    },
    {
      title: "Name",
      dataIndex: "userId",
      width:180,
      render: (user) => {
        return <div>{user?.name}</div>
      },
      ...getColumnSearchProps("name"),
    },
    {
      title: "Phone",
      dataIndex: "userId",
      width:180,
      render: (user) => {
        return (
          <div>
            <a>{user?.phone}</a>
          </div>
        )
      },
      ...getColumnSearchProps("phone"),
    },
    {
      title: "Email",
      dataIndex: "userId",
      width:180,
      render: (user) => {
        return <div>{user?.email}</div>
      },
      ...getColumnSearchProps("email"),
    },
    {
      title: "Company",
      dataIndex: "companyId",
      width:180,
      render: (company) => {
        return (
          <div>
            {company?.name}
            <br />
            <div style={{ color: "lightGray" }}>{company?._id}</div>
          </div>
        )
      },
      ...getColumnSearchProps("batchId"),
    },
    {
      title: "Category",
      width:100,
      dataIndex: "categoryId",
      render: (code) => {
        let color =
          code?.code === "SMM"
            ? "#B2A4FF"
            : code?.code === "VE"
            ? "#E11299"
            : code?.code === "CW"
            ? "#867070"
            : "#E4D0D0"
        return <Tag color={color}>{code?.code}</Tag>
      },
      ...getColumnSearchProps("batchId"),
    },
    {
      title: "Portfolio",
      dataIndex: "progress",
      width:180,
      render: (progress) => {
        return (
          <a href={progress?.assessment?.portfolio} target="blank">
            <Button type="dashed">View Portfolio</Button>
          </a>
        )
      },
      ...getColumnSearchProps("batchId"),
    },
    {
      title :"Intern.. Task 1",
      dataIndex:"progress",
      width:210,
      render:(progress)=>{
       return (<>
       {
         progress?.internship?.week1 === undefined ?<Tag color="red">PENDING</Tag>:
         <div>
           <a href={progress?.internship?.week1.url} target="blank"><Button type="dashed">View</Button></a><br/>
           <div style={{ color: "lightgray",marginTop:"5px" }}>
           Submitted on : {new Date(progress?.internship?.week1.createdAt).toLocaleDateString()}<br/>
           {/* Updated on : {new Date(progress?.internship?.week1.updatedAt).toLocaleDateString()} */}
           </div>
         </div>
       }
       </>)
      }
         },
         {
           title :"Intern.. Task 2",
           dataIndex:"progress",
           width:210,
           render:(progress)=>{
            return (<>
            {
              progress?.internship?.week2 === undefined ?<Tag color="red">PENDING</Tag>:
              <div>
                <a href={progress?.internship?.week2.url} target="blank"><Button type="dashed">View</Button></a><br/>
                <div style={{ color: "lightgray",marginTop:"5px" }}>
                Submitted on : {new Date(progress?.internship?.week2.createdAt).toLocaleDateString()}<br/>
                {/* Updated on : {new Date(progress?.internship?.week2.updatedAt).toLocaleDateString()} */}
                </div>
              </div>
            }
            </>)
           }
              },
              {
               title :"Intern.. Task 3",
               dataIndex:"progress",
               width:210,
               render:(progress)=>{
                return (<>
                {
                  progress?.internship?.week3 === undefined ?<Tag color="red">PENDING</Tag>:
                  <div>
                     <a href={progress?.internship?.week3.url} target="blank"><Button type="dashed">View</Button></a><br/>
                    <div style={{ color: "lightgray",marginTop:"5px" }}>
                    Submitted on : {new Date(progress?.internship?.week3.createdAt).toLocaleDateString()}<br/>
                    {/* Updated on : {new Date(progress?.internship?.week3.updatedAt).toLocaleDateString()} */}
                    </div>
                  </div>
                }
                </>)
               }
                  },
                  {
                   title :"Intern.. Task 4",
                   dataIndex:"progress",
                   width:210,
                   render:(progress)=>{
                    return (<>
                    {
                      progress?.internship?.week4 === undefined ?<Tag color="red">PENDING</Tag>:
                      <div>
                        <a href={progress?.internship?.week4.url} target="blank"><Button type="dashed">View</Button></a><br/>
                        <div style={{ color: "lightgray",marginTop:"5px" }}>
                        Submitted on : {new Date(progress?.internship?.week4.createdAt).toLocaleDateString()}<br/>
                        {/* Updated on : {new Date(progress?.internship?.week4.updatedAt).toLocaleDateString()} */}
                        </div>
                      </div>
                    }
                    </>)
                   }
                      },
    // {
    //   title: "Week Task",
    //   dataIndex: "progress",
    //   render: (progress) => {
    //     // console.log(progress?.internship?.week1);
    //     return (
    //       <div style={{ display: "flex", flexDirection: "row" }}>
    //         {progress?.internship?.week1 === undefined ? (
    //           ""
    //         ) : (
    //           <a href={progress?.internship?.week1} target="blank">
    //             <Button type="dashed">1</Button>
    //           </a>
    //         )}
    //         {progress?.internship?.week2 === undefined ? (
    //           ""
    //         ) : (
    //           <a
    //             href={progress?.internship?.week2}
    //             style={{ marginLeft: "5px" }}
    //             target="blank"
    //           >
    //             <Button type="dashed">2</Button>
    //           </a>
    //         )}
    //         {progress?.internship?.week3 === undefined ? (
    //           ""
    //         ) : (
    //           <a
    //             href={progress?.internship?.week3}
    //             style={{ marginLeft: "5px" }}
    //             target="blank"
    //           >
    //             <Button type="dashed">3</Button>
    //           </a>
    //         )}
    //         {progress?.internship?.week4 === undefined ? (
    //           ""
    //         ) : (
    //           <a
    //             href={progress?.internship?.week4}
    //             style={{ marginLeft: "5px" }}
    //             target="blank"
    //           >
    //             <Button type="dashed">4</Button>
    //           </a>
    //         )}
    //       </div>
    //     )
    //   },
    //   ...getColumnSearchProps("batchId"),
    // },

    // {
    //   title: "Action",
    //   key: "operation",
    //   fixed: "right",
    //   width: 100,
    //   render: (_, record) => (

    //       <Tooltip title="edit">
    //         <Button
    //           type="link"
    //           shape="circle"
    //           icon={<EditOutlined />}
    //           onClick={() => openModel(record,"edit")}
    //         />
    //       </Tooltip>

    //   ),
    // },
  ]

  const fetchData = async (id, categoryId) => {
    console.log(id)
    setLoading(true)
    try {
      //   let query = []
      //   Object.keys(tableParams.filters).forEach((k) => {
      //     if (
      //       tableParams.filters[k] !== null &&
      //       tableParams.filters[k].length > 0
      //     ) {
      //       query.push(`${k}=${tableParams.filters[k]}`)
      //     }
      //   })
      //   //   name={$regex:^fin,$options:'i'}&

      //   const preQuery =  isExport?`page=${tableParams.pagination.current}&limit=1000&`:`page=${tableParams.pagination.current}&limit=${tableParams.pagination.pageSize}&`
      //   const q = preQuery
      //      +
      //     query.join("&")

      const url = `${constants.NEW_DOMAIN}/api/v1/user/findForSlot/${id}?categoryId=${categoryId}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      //   if(isExport) {
      //     setLoading(false)
      //     return res.data.data.users
      //   }
      console.log(res.data.data)
      setData(res.data.data)
      setLoading(false)
      //   setTableParams({
      //     ...tableParams,
      //     pagination: {
      //       ...tableParams.pagination,
      //       total: res.data.results,
      //     },
      //   })
    } catch (error) {
      setLoading(false)
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      console.log(error)
    }
  }

  useEffect(() => {
    // if(JSON.stringify(tableParams)){
    //     fetchData()
    // }

    fetchSlot()
    fetchCompany()
    fetchCategory()
  }, [])

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    })

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([])
    }
  }

  return (
    <>
      {contextHolder}
      <div style={{ backgroundColor: "#fff", padding: "20px" }}>
        <Modal
          title="Assign Company"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[]}
        >
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinishCompany}
            style={{
              maxWidth: 600,
            }}
          >
            <Form.Item name="Company" label="Company">
              <Select placeholder="Choose Company" label="Company">
                {company &&
                  company.map((slot, key) => {
                    return (
                      <Option key={key} value={slot._id}>
                        {slot.name}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Assign Company
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <Row>
          <Col xs={24} md={20}>
            <div className="text-3xl">Assign Company</div>
            <p className="text-lg-light">
              {new Date().toLocaleDateString()} -{" "}
              {new Date().toLocaleTimeString()}
            </p>
          </Col>

          <Col xs={24} md={4}>
            <Statistic
              title="Total  User"
              loading={loading}
              value={data?.length}
              precision={0}
              valueStyle={{
                color: "blue",
              }}
              // prefix={<ArrowUpOutlined />}
              suffix="users"
            />
          </Col>
        </Row>
        <Divider></Divider>
        <div>
        <Form
          {...layout}
          form={form1}
          name="control-hooks"
          onFinish={onFinishSearch}
          style={{
            maxWidth: 600,
            display: isMobile?"":"flex",
          }}
        >
          <Form.Item name="Slot" label="Slot">
            <Select
            style={{width:200}}
              placeholder="Choose Internship Slot"
              //  label="Slot"
              //  onChange={(e)=>fetchData(e)}
            >
              {slot &&
                slot.map((slot, key) => {
                  return (
                    <Option key={key} value={slot._id}>
                      {slot.description}
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>

          <Form.Item
             style={{ width:isMobile?"200px": "300px",marginLeft:isMobile?"":"30px" }}
            name="Category"
            label="Category"
          >
            <Select style={{ marginLeft: isMobile?"":"5px" }} placeholder="Choose Category">
              {category &&
                category.map((slot, key) => {
                  return (
                    <Option key={key} value={slot._id}>
                      {slot?.code}
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Form>
        </div>
        <Divider></Divider>
        <Table
          columns={columns}
          loading={loading}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          title={renderHeader}
          dataSource={data}
          onChange={handleTableChange}
          pagination={tableParams.pagination}
          rowKey={(record) => record._id}
          scroll={{
            x: 900,
            // y: 500,
          }}
        />
      </div>
    </>
  )
}

export default AssignCompany

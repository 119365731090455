import React, { useEffect, useState } from 'react';
import { Select, Spin, Typography, Card, Input, Button, Upload, message, Form, Modal, Progress } from 'antd';
import { MessageOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import ExcelJS from 'exceljs'; // Import ExcelJS
import constants from '../../constants';
import { socket } from "../../util/socket"

const { Option } = Select;
const { Title, Text } = Typography;

const Messanger = () => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [uploadedExcelFile, setUploadedExcelFile] = useState(null); // State to hold uploaded Excel file
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
  const [testValues, setTestValues] = useState({}); // State to hold test values from modal
  const [percent, setPercent] = useState({
    count:0,
    percentage:0
  })

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  // Replace with your S3 JSON URL
  const S3_URL = 'https://youvah-cms.s3.ap-south-1.amazonaws.com/templates.json';

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get(S3_URL);
        setTemplates(response.data.templates);
      } catch (error) {
        console.error('Error fetching templates:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  const handleSelectChange = (value) => {
    const selectedTemplateObject = templates.find(template => template.templateCode === value);
    setSelectedTemplate(selectedTemplateObject);
    setFormValues({});
    setUploadedExcelFile(null); // Reset uploaded file when a new template is selected
    setTestValues({}); // Reset test values
  };

  const handleInputChange = (field, value) => {
    setFormValues(prev => ({ ...prev, [field]: value }));
  };

  const handleTestInputChange = (field, value) => {
    setTestValues(prev => ({ ...prev, [field]: value }));
  };

  const handleExcelUpload = (file) => {
    setUploadedExcelFile(file); // Store the uploaded file
    message.success(`${file.name} file uploaded successfully.`);
    return false; // Prevent upload default behavior
  };

  const handleFormSubmit = async () => {
    console.log('Submitted data:', formValues);
    
    // Perform additional actions if needed
  };

  const generateSampleExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sample Data');

    // Define columns based on the selected template
    const columns = [
      { header: 'countryCode', key: 'countryCode', width: 15 },
      { header: 'phone', key: 'phone', width: 20 },
    ];

    // Add variable columns
    if (selectedTemplate) {
      selectedTemplate.variables.forEach(variable => {
        columns.push({ header: variable, key: variable, width: 25 });
      });

      // Add header file URL and name fields if applicable
      if (selectedTemplate.headerFileUrl) {
        columns.push({ header: 'headerFileUrl', key: 'headerFileUrl', width: 30 });
        columns.push({ header: 'headerFileName', key: 'headerFileName', width: 30 });
      }

      // Add URL parameters if applicable
      if (selectedTemplate.urlParameters) {
        columns.push({ header: 'urlParameters', key: 'urlParameters', width: 25 });
      }
    }

    worksheet.columns = columns;

    // Add a sample row (can be modified to fit requirements)
    const sampleRow = {
      countryCode: '',
      phone: '',
      ...selectedTemplate?.variables.reduce((acc, variable) => {
        acc[variable] = '';
        return acc;
      }, {}),
      headerFileUrl: selectedTemplate?.headerFileUrl ? '' : undefined,
      headerFileName: selectedTemplate?.headerFileUrl ? '' : undefined,
      urlParameters: selectedTemplate?.urlParameters ? '' : undefined,
    }

    worksheet.addRow(sampleRow);

    // Create a download link and trigger download
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Sample_${selectedTemplate?.templateName}.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    });
  };

  const handleSubmit = async () => {
    const finalInput = {
      templateCode: selectedTemplate?.templateCode,
      formValues,
      testValues,
      uploadedExcelFile,
    };

    console.log('Final input for submission:', finalInput); // Log final input

    try {
      const formData = new FormData();
      formData.append('template',  JSON.stringify(selectedTemplate));
      formData.append('data', JSON.stringify(formValues));
      if (uploadedExcelFile) {
        formData.append('excelFile', uploadedExcelFile); // Append the uploaded Excel file
      }
      showModal()
      // Send data to the backend API
      const response = await axios.post(`${constants.NEW_DOMAIN}/api/v1/messanger/bulkMessaging`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
        },
      });
      // handleCancel()
      message.success('Data submitted successfully!, Check Kwiqreply for log.');
      console.log('Response from server:', response.data);
    } catch (error) {
      handleCancel()
      console.error('Error submitting data:', error);
      message.error(error.response.data.message);
    }
  };

    // Cloudinary Upload Function
    const uploadToCloudinary = async (file) => {
      console.log(file)
      const formData = new FormData();
      // formData.append('file', file);
      // formData.append('upload_preset', 'your_upload_preset'); // Your Cloudinary upload preset
      formData.append("file", file?.originFileObj)
      formData.append("upload_preset", "kgqhhoge");
      formData.append("public_id", new Date().getTime().toString())
      formData.append("folder", "dashboard")
  
      try {
        const response = await axios.post('https://api.cloudinary.com/v1_1/youvah/upload', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: {
            resource_type: 'auto', // This will automatically handle various file types (video, image, pdf)
            expiry: 3600, // Expiration time set to 60 minutes (3600 seconds)
          },
        });
  
        message.success(`File uploaded successfully!`);
        return response.data; // Return Cloudinary response
      } catch (error) {
        console.error('Upload error:', error);
        message.error('Failed to upload file.');
        return null;
      }
    };

    useEffect(() => {
      socket.on("progressMessanger", (raw) => {
        //updated Percent
        console.log(raw);
        
        setPercent(raw)
      })
    }, [socket])
  
    const handleHeaderFileUpload = async ({ file }) => {

      if(file.name === formValues.headerFileName) return
    
      const allowedFileTypes = ['image/png', 'image/jpeg', 'application/pdf', 'video/mp4'];
      if (!allowedFileTypes.includes(file.type)) {
        message.error('Invalid file type. Allowed types: MP4, PDF, PNG, JPG');
        return false;
      }
  
      if (file.size / 1024 / 1024 > 15) {
        message.error('File is too large. Max size allowed is 15MB.');
        return false;
      }
  
      const result = await uploadToCloudinary(file);
      if (result) {

        await handleInputChange('headerFileUrl', result.url)
        await handleInputChange('headerFileName', file.name)
        await handleTestInputChange('headerFileUrl', result.url)
        await handleTestInputChange('headerFileName', file.name)
                // setHeaderFile({
        //   url: result.secure_url,
        //   name: file.name,
        // });
      }
      return false; // Prevent the default upload behavior
    };

  // Function to show modal for testing functionality
  const showTestModal = () => {
    setIsModalVisible(true);
  };

  // Function to handle modal submission for test
  const handleModalTestSubmit = async () => {
    const testInput = {
      template: selectedTemplate,
      data : testValues,
    };

    console.log('Test input for submission:', testInput); // Log final input for testing

    try {
      // Send test data to the backend API (create a separate endpoint if needed)
    const url = `${constants.NEW_DOMAIN}/api/v1/messanger/testTemplate`;
    const res = await axios.post(url, testInput,{
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
      },
    });   
      message.success('Test data submitted successfully!, Check Kwiqreply for logs');
      console.log('Response from server (test):', testInput);
    } catch (error) {
      console.error('Error submitting test data:', error.message);
      message.error(error.response.data.message);
    } finally {
      // setIsModalVisible(false); // Close the modal after submission
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: '#f0f2f5' }}>
      <Card 
        style={{ width: 500, borderRadius: '10px', boxShadow: '0 4px 12px rgba(0,0,0,0.1)' }}
        bodyStyle={{ padding: '30px' }}
      >
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <MessageOutlined style={{ fontSize: '32px', color: '#1890ff' }} />
          <Title level={3} style={{ margin: '10px 0' }}>Messanger</Title>
          <Text type="secondary">Select a message template from the list below</Text>
        </div>

        {loading ? (
          <div style={{ textAlign: 'center' }}>
            <Spin size="large" />
          </div>
        ) : (
          <Select
            style={{ width: '100%', marginBottom: '20px' }}
            placeholder="Select a template"
            onChange={handleSelectChange}
          >
            {templates.map((template, index) => (
              <Option key={index} value={template.templateCode}>
                {template.displayName}
              </Option>
            ))}
          </Select>
        )}

        {selectedTemplate && (
          <>
            <Text type="success">
              You have selected: <strong>{selectedTemplate.displayName}</strong>
            </Text>

            <div style={{ marginTop: '20px' }}>
              <Form layout="vertical">
                {selectedTemplate?.variables.map((variable, index) => (
                  <Form.Item key={index} label={`Enter ${variable} (variable)`}>
                    <Input
                      placeholder={`Enter ${variable}`}
                      value={formValues[variable] || ''}
                      onChange={e => handleInputChange(variable, e.target.value)}
                    />
                  </Form.Item>
                ))}

                {selectedTemplate?.urlParameters && (
                  <Form.Item label="URL Parameters">
                    <Input
                      placeholder="Enter URL parameters"
                      value={formValues.urlParameters || ''}
                      onChange={e => handleInputChange('urlParameters', e.target.value)}
                    />
                  </Form.Item>
                )}

                {selectedTemplate?.headerFileUrl && (
                  <>
                    <Form.Item label={`Header File Public URL (${selectedTemplate.contentType})`}>
                      <Input
                        placeholder="Enter public URL"
                        value={formValues.headerFileUrl || ''}
                        onChange={e => handleInputChange('headerFileUrl', e.target.value)}
                      />
                    </Form.Item>
                    <p>OR</p>
                    <Upload onChange={handleHeaderFileUpload} showUploadList={false}>
                      <Button icon={<UploadOutlined />}>Upload Header</Button>
                    </Upload>
                    <Form.Item label={`Header File Name (${selectedTemplate.contentType})`} style={{marginTop:"10px"}}>
                      <Input
                        placeholder="Enter file name"
                        value={formValues.headerFileName || ''}
                        onChange={e => handleInputChange('headerFileName', e.target.value)}
                      />
                    </Form.Item>
                  </>
                )}

                <Form.Item label="Upload Excel File">
                  <Upload
                    beforeUpload={handleExcelUpload}
                    showUploadList={true}
                  >
                    <Button icon={<UploadOutlined />}>Upload Excel</Button>
                  </Upload>
                </Form.Item>

                <Button type="primary" onClick={showTestModal}>
                  Test Template
                </Button>
                <Button 
                  type="primary" 
                  style={{ marginLeft: '10px' }} 
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
                <Button 
                  type="default" 
                  style={{ marginLeft: '10px' }} 
                  onClick={generateSampleExcel}
                >
                  Download Sample Excel
                </Button>
              </Form>
            </div>
          </>
        )}
      </Card>

      <Modal
        title="Test Template Submission"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleModalTestSubmit}>
            Submit Test
          </Button>,
        ]}
      >
        <p>Please confirm to submit the test with the selected template data.</p>
        <div>
          <strong>Template Name:</strong> {selectedTemplate?.displayName}
        </div>
        <div>
          {selectedTemplate?.variables.map((variable, index) => (
            <div key={index}>
              <strong>{variable}:</strong>
              <Input
                required
                placeholder={`Enter ${variable}`}
                onChange={e => handleTestInputChange(variable, e.target.value)}
              />
            </div>
          ))}
        </div>
        <div >
                  <strong>Phone :</strong>
                      <Input
                      required
                        placeholder="Enter phone"
                        value={testValues.phone || ''}
                        onChange={e => handleTestInputChange('phone', e.target.value)}
                      />
                   </div>
                    <div >
                  <strong>Country code :</strong>
                      <Input
                      required
                        placeholder="Enter public URL"
                        value={testValues.countryCode || ''}
                        onChange={e => handleTestInputChange('countryCode', e.target.value)}
                      />
                   </div>
        <div>
        {selectedTemplate?.urlParameters && (
                <div >
              <strong>URL Parameters :</strong>
                    <Input
                    required
                      placeholder="Enter URL parameters"
                      value={testValues.urlParameters || ''}
                      onChange={e => handleTestInputChange('urlParameters', e.target.value)}
                    />
                  </div>
                )}

                {selectedTemplate?.headerFileUrl && (
                  <>
                  <div >
                  <strong>Header File Public URL :</strong>
                      <Input
                      required
                        placeholder="Enter public URL"
                        value={testValues.headerFileUrl || ''}
                        onChange={e => handleTestInputChange('headerFileUrl', e.target.value)}
                      />
                   </div>
                   <div >
                   <strong>Header File Name :</strong>
                      <Input
                      required
                        placeholder="Enter file name"
                        value={testValues.headerFileName || ''}
                        onChange={e => handleTestInputChange('headerFileName', e.target.value)}
                      />
                    </div>
                  </>
                )}
  
        </div>
      </Modal>

      <Modal title="Sending Messages" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} >
      <p style={{marginBottom:'10px'}}>Please Wait while we are generating your report</p>
    <Progress percent={ Math.round(percent.percentage)} />
    {/* <p style={{marginBottom:'10px'}}>{percent.count} Message Sent</p> */}
    <p><b style={{color:'red'}}>NOTE :</b> Please do not refresh or close the window </p>
      </Modal>

    </div>
  );
};

export default Messanger;

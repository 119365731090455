import React, { useEffect, useState } from 'react'
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  SearchOutlined,
  PlusOutlined,
  MinusCircleOutlined
} from '@ant-design/icons';
import {
  Breadcrumb, Layout, Menu, theme, Form, Input, Button, Row, Col, Card, List, Typography, Space, Badge, Divider, Tag, Statistic,
  Timeline, Empty, Modal, DatePicker, Switch, Tooltip, message
} from 'antd';
import axios from 'axios';
import dayjs from "dayjs"
import constants from '../../constants';
import useView from '../../hooks/useView';
import Loader from '../../util/loader';
import { useNavigate, useSearchParams } from "react-router-dom"
const { Header, Content, Footer, Sider } = Layout;


function BatchModify() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { isMobile } = useView()
  const [form] = Form.useForm();
  const Navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const [form1] = Form.useForm();
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [dataCategory, setCategoryData] = useState()
  const [analytics, setAnalytics] = useState()
  const [isModalAnalyticsOpen, setIsModalAnalyticsOpen] = useState(false);
  const [clientReady, setClientReady] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams()
  const batch = searchParams.get("batch")
  // console.log(data)
  const { Text } = Typography
  useEffect(() => {
    fetchCategoriesData()
    setClientReady(true);
    if (batch) {
      onFinish({
        Batch: batch
      })
    }
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const fetchCategoriesData = async () => {
    setLoading(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/category`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setCategoryData(res.data.data.docs)
      // console.log(res.data.data.docs)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  const onFinish = async (values) => {
    setLoading(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/batch/${values.Batch}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      console.log(res.data.data);
      setLoading(false)
      setData(res.data.data)
      messageApi.open({
        type: "success",
        content: "Batch Found Successfully",
      })

    } catch (error) {
      setLoading(false)

      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      console.log(error)
    }
  }
  const onFinishModal = async (values) => {
    setLoading(true)
    const url = `${constants.NEW_DOMAIN}/api/v1/batch/createAndUpdate`
    try {
      // const token = await getToken()
      // const headers = {
      //   // authorization: `bearer ${token}`,
      // }

      const res = await axios.post(url, values, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      // form.resetFields()
      // setData((prevData) => {
      //   const newArr = prevData.doc.map((category) => {
      //     if (category._id === res.data.data.doc._id) {
      //       return res.data.data.doc
      //     } else {
      //       return category
      //     }
      //   })
      //   return newArr
      // })
      onFinish({
        Batch: values.batchId
      })
      setLoading(false)
      messageApi.open({
        type: "success",
        content: "Batch Updated Successfully",
      })
      handleCancel()
    } catch (error) {
      if (error.response) {
        setLoading(false)
        messageApi.open({
          type: "error",
          content: error.response.data.message || "Something went wrong",
        })
        console.log(error.response.data)
        console.log(error.response.status)
      }
    }
  }
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo)
  }
  const getAnalytics = async () => {
    setLoading(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/batch/analytics/${data.doc.batchId}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      console.log(res.data.data.result);
      setAnalytics(res.data.data.result)
      setLoading(false)
      messageApi.open({
        type: "success",
        content: "Analytics Found Successfully",
      })
    } catch (error) {
      setLoading(false)
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      console.log(error)
    }
  }



  return (
    <>
      {contextHolder}
      {loading ? <Loader /> : <>
        <Modal title={`Analytics ${data?.doc?.batchId}`} open={isModalAnalyticsOpen} onOk={handleOk} footer={""} onCancel={() => setIsModalAnalyticsOpen(false)}>
          <Divider>Registrations</Divider>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "155px 155px",
              // : "135px 125px 125px",
              gridColumnGap: "20px",
              gridRowGap: "20px",
            }}
          >
            <Card bordered={false}>
              <Statistic
                title={"Total User"}
                value={analytics?.totalStudents}
                // precision={2}
                valueStyle={{ color: "black", fontSize: "20px" }}
              // prefix={<ArrowDownOutlined />}
              />
            </Card>
            <Card bordered={false}>
              <Statistic
                title={"Training Started"}
                value={analytics?.trainingStarted}
                // precision={2}
                valueStyle={{ color: "black", fontSize: "20px" }}
              // prefix={<ArrowDownOutlined />}
              />
            </Card>
          </div>
          <Divider>Assessment</Divider>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "155px 155px",
              // : "135px 125px 125px",
              gridColumnGap: "20px",
              gridRowGap: "20px",
            }}
          > <Card bordered={false}>
              <Statistic
                title={"MCQ Given"}
                value={analytics?.assessment.mcq}
                // precision={2}
                valueStyle={{ color: "black", fontSize: "20px" }}
              // prefix={<ArrowDownOutlined />}
              />
            </Card>
            <Card bordered={false}>
              <Statistic
                title={"Portfolio Submitted"}
                value={analytics?.assessment.portfolio}
                // precision={2}
                valueStyle={{ color: "black", fontSize: "20px" }}
              // prefix={<ArrowDownOutlined />}
              />
            </Card>

          </div>
          <Divider>Internship</Divider>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: isMobile
                ? "135px 125px"
                : "135px 125px 125px",
              gridColumnGap: "20px",
              gridRowGap: "20px",
            }}
          >
            <Card bordered={false}>
              <Statistic
                title={"Slot Seleted"}
                value={analytics?.internship?.slotBooked}
                // precision={2}
                valueStyle={{ color: "black", fontSize: "20px" }}
              // prefix={<ArrowDownOutlined />}
              />
            </Card>
            <Card bordered={false}>
              <Statistic
                title={"Company Assigned"}
                value={analytics?.internship?.companyAssigned}
                // precision={2}
                valueStyle={{ color: "black", fontSize: "20px" }}
              // prefix={<ArrowDownOutlined />}
              />
            </Card>
            <Card bordered={false}>
              <Statistic
                title={"Week 1 Submitted"}
                value={analytics?.internship?.week1}
                // precision={2}
                valueStyle={{ color: "black", fontSize: "20px" }}
              // prefix={<ArrowDownOutlined />}
              />
            </Card>
            <Card bordered={false}>
              <Statistic
                title={"Week 2 Submitted"}
                value={analytics?.internship?.week2}
                // precision={2}
                valueStyle={{ color: "black", fontSize: "20px" }}
              // prefix={<ArrowDownOutlined />}
              />
            </Card>
            <Card bordered={false}>
              <Statistic
                title={"Week 3 Submitted"}
                value={analytics?.internship?.week3}
                // precision={2}
                valueStyle={{ color: "black", fontSize: "20px" }}
              // prefix={<ArrowDownOutlined />}
              />
            </Card>
            <Card bordered={false}>
              <Statistic
                title={"Week 4 Submitted"}
                value={analytics?.internship?.week4}
                // precision={2}
                valueStyle={{ color: "black", fontSize: "20px" }}
              // prefix={<ArrowDownOutlined />}
              />
            </Card>

          </div>
        </Modal>
        <Modal title="Create / Update Batch" open={isModalOpen} footer={""} onOk={handleOk} onCancel={handleCancel} width={700} >
          <Form
            name="dynamic_form_nest_item"
            form={form1}
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 16,
            }}
            onFinish={onFinishModal}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="batchId"
              label="Batch Id"
              rules={[
                {
                  required: true,
                  message: "batchId is required!",
                },
              ]}
            >
              <Input value={form.getFieldValue("batchId")} />
            </Form.Item>

            <Form.Item
              name="categoryCode"
              label="Category Code"
              rules={[
                {
                  required: true,
                  message: "categoryCode is required!",
                },
              ]}
            >
              <Input value={form.getFieldValue("categoryCode")} />
            </Form.Item>

            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Please provide description!",
                },
              ]}
            >
              <Input.TextArea placeholder="description" />
            </Form.Item>

            <Form.Item
              name="trainingStartDate"
              label="🗓️Start Date"
              rules={[
                {
                  required: true,
                  message: "Please provide training start date!",
                },
              ]}

            >
              <DatePicker
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                onChange={(e) => {
                  console.log(e)
                  const d = new Date(e)
                  if (form1.getFieldValue("categoryCode") !== undefined) {
                    let index = 0
                    let reviewArray = dataCategory || []
                    index = reviewArray.findIndex((obj) => obj.code == form1.getFieldValue("categoryCode"))
                    if (index >= 0) {
                      d.setDate(d.getDate() + reviewArray[index].videos.length - 1)
                    }
                    form1.setFieldValue(
                      "trainingEndDate",
                      dayjs(d)
                    )
                  }
                  // if(form1.getFieldValue("categoryCode") === "FM"){
                  //   d.setDate(d.getDate() + 17)  
                  // }
                  // else if(form1.getFieldValue("categoryCode") === "WD"){
                  //   d.setDate(d.getDate() + 19)  
                  // }
                  // else{
                  //   d.setDate(d.getDate() + 14)  
                  // }


                }}
              // style={{
              //   width: "100%",
              // }}
              />
            </Form.Item>
            <Form.Item
              name="trainingEndDate"
              label="🔚End Date"
              rules={[
                {
                  required: true,
                  message: "Please provide training end date!",
                },
              ]}
            >
              <DatePicker
                showTime
                format="YYYY-MM-DD HH:mm:ss"
              // style={{
              //   width: "100%",
              // }}
              />
            </Form.Item>
            <Form.Item name="active" label="Active?" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item
              name="groupLink"
              label="🖇️WhatsApp Link"
              rules={[
                {
                  type: "url",
                  warningOnly: true,
                },
              ]}
            >
              <input />
            </Form.Item>
            {data?.liveDays && (
              <Space style={{ margin: "10px 0" }}>
                {" "}
                Live Day IDs :{" "}
                {data?.liveDays.map((ld) => {
                  return <Badge count={"ID : " + ld.id} />
                })}
              </Space>
            )}

            <Form.List name="liveMetaData">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, i) => (
                    <div key={i}>
                      <h1 style={{ textAlign: "center", fontSize: "14px" }}>
                        Day {form1.getFieldValue("liveMetaData")[i]?.id}
                        <span>
                          <MinusCircleOutlined
                            style={{ color: "red", marginLeft: "20px" }}
                            onClick={() => remove(name)}
                          />
                        </span>
                      </h1>
                      <Form.Item
                        {...restField}
                        name={[name, "id"]}
                        label="ID"
                        rules={[
                          {
                            required: true,
                            message: "id is required",
                          },
                        ]}
                      >
                        <Input placeholder="ex : 1" />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, "url"]}
                        label="🔗url"
                        rules={[
                          {
                            type: "url",
                            warningOnly: true,
                          },
                        ]}
                      >
                        <Input placeholder="Live class URL" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "recordedLecture"]}
                        label="⏺️recordedLecture"
                        rules={[
                          {
                            type: "recordedLecture",
                            warningOnly: true,
                          },
                        ]}
                      >
                        <Input placeholder="Recorded class URL" />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, "venue"]}
                        label="💻venue"
                        rules={[
                          {
                            required: true,
                            message: "Please provide venue",
                          },
                        ]}
                      >
                        <Input placeholder="ex : Google Meet" />
                      </Form.Item>
                      <Form.Item
                        label="📅Date"
                        rules={[
                          {
                            required: true,
                            message: "Please provide Date",
                          },
                        ]}
                      >
                        <Space direction="verticle">
                          <Form.Item
                            {...restField}
                            name={[name, "date"]}
                          // label="Date"
                          >
                            <DatePicker
                              showTime
                              format="YYYY-MM-DD HH:mm:ss"
                              placeholder="Live class start date and time"
                            />
                          </Form.Item>

                          <Tooltip title="2023-04-14 21:00:00">
                            <Typography.Link href="#API">
                              Need Help on format?
                            </Typography.Link>
                          </Tooltip>
                        </Space>
                      </Form.Item>
                    </div>
                  ))}
                  <Form.Item
                    wrapperCol={{
                      offset: 4,
                      span: 5,
                    }}
                    style={{ backgroundColor: "ghostwhite" }}
                  >
                    <Button
                      // type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add New Live
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 16,
              }}
            >
              <Button type="primary" block htmlType="submit">
                📂 Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <Layout
          style={{
            minHeight: '100vh',
          }}
        >
          <Layout>
            <Header
              style={{
                height: "fit-content",
                padding: 0,
                background: colorBgContainer,
              }}>
              <h1 style={{ color: "black", width: "100%", textAlign: "center", fontSize: "20px" }}>Create / Update Batch</h1>
            </Header>
            <Content
              style={{
                margin: '0 16px',
              }}
            >
              {/* <Breadcrumb
            style={{
              margin: '16px 0',
            }}
          >
            <Breadcrumb.Item>User</Breadcrumb.Item>
            <Breadcrumb.Item>Bill</Breadcrumb.Item>
          </Breadcrumb> */}
              <div style={{ width: "100%", height: "80px", display: "flex", justifyContent: "center", paddingTop: "25px" }}>
                <Form form={form} name="horizontal" layout="inline" onFinish={onFinish}>
                  <Form.Item
                    name="Batch"
                    rules={[
                      {
                        required: true,
                        message: 'Please input Batch Code!',
                      },
                    ]}
                  >
                    <Input prefix={<SearchOutlined className="site-form-item-icon" />} placeholder="SMM_04_10" />
                  </Form.Item>
                  <Form.Item shouldUpdate>
                    {() => (
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={
                          !clientReady ||
                          !form.isFieldsTouched(true) ||
                          !!form.getFieldsError().filter(({ errors }) => errors.length).length
                        }
                      >
                        Find
                      </Button>
                    )}
                  </Form.Item>
                </Form>
                <Button
                  type="default"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    form1.resetFields()
                    showModal()
                  }}
                  //   loading={loading}
                  style={{ backgroundColor: "#971eff", color: "white" }}
                >
                  Create Batch
                </Button>
              </div>

              {data && (<Card
                title={"batch"}
                extra={
                  <>
                    {
                      batch && (
                        <>
                          <Button onClick={() => {
                            Navigate(`/batch/analytics`)
                          }}>Back</Button>
                        </>
                      )
                    }
                    <Button

                      onClick={() => {
                        getAnalytics()
                        setIsModalAnalyticsOpen(true)
                      }}>Fetch Analytics</Button>
                    <Button
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        if (data?.doc?.liveMetaData)
                          data.doc.liveMetaData = data.doc.liveMetaData.map(
                            (obj) => {
                              return {
                                ...obj,
                                date: dayjs(obj?.date),
                              }
                            }
                          )
                        form1.setFieldValue(
                          "trainingStartDate",
                          dayjs(data?.doc?.trainingStartDate)
                        )
                        form1.setFieldValue(
                          "trainingEndDate",
                          dayjs(data?.doc?.trainingEndDate)
                        )
                        form1.setFieldValue(
                          "liveMetaData",
                          data?.doc?.liveMetaData
                        )
                        form1.setFieldValue(
                          "batchId",
                          data?.doc?.batchId
                        )
                        form1.setFieldValue(
                          "categoryCode",
                          data?.doc?.categoryCode
                        )
                        form1.setFieldValue(
                          "description",
                          data?.doc?.description
                        )
                        form1.setFieldValue(
                          "active",
                          data?.doc?.active
                        )
                        form1.setFieldValue(
                          "groupLink",
                          data?.doc?.groupLink
                        )
                        //  form1.setFieldsValue(data?.doc)


                        console.log(data)
                        showModal()
                      }}>Update Batch</Button>
                  </>
                }
                style={{ maxHeight: "60vh", overflowY: "scroll" }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ width: "400px" }}>
                    <List
                      //   size="small"
                      header={<div><b>Basic Info</b></div>}
                      contentWidth={200}
                      // footer={<div>Footer</div>}
                      bordered
                      dataSource={[
                        <Text ellipsis={true}>🎯Batch Code : {data?.doc?.batchId}</Text>,
                        <Text ellipsis={true}>Category Code : {data?.doc?.categoryCode}</Text>,
                        <Text ellipsis={true}>Description  : {data?.doc?.description}</Text>,
                        <Text ellipsis={true}>Is Slot Active :  {data?.doc?.active ? <Tag color={"green"}>Yes</Tag> : <Tag color={"red"}>No</Tag>}</Text>,
                        <Text ellipsis={true}>🗓️Training Start Date  : {new Date(data?.doc?.trainingStartDate).toLocaleString()}</Text>,
                        <Text ellipsis={true}>🗓️Training End Date  : {new Date(data?.doc?.trainingEndDate).toLocaleString()}</Text>,
                        <Text ellipsis={true}>🔗Group Link  :
                          <a
                            style={{ fontWeight: "bold", marginLeft: "4px" }}
                            href={"https://bit.ly/" + data?.doc?.groupLink}
                            target="_blank"
                          >
                            {data?.doc?.groupLink
                              ? "https://bit.ly/" + data?.doc?.groupLink
                              : "Not Available"}
                          </a>
                        </Text>,

                      ]}
                      renderItem={(item) => <List.Item>{item}</List.Item>}
                    />
                  </div>
                  <div style={{ marginLeft: "5%", width: "70%" }}>
                    <List
                      //   size="small"
                      header={<div><b>Live Class MetaData</b></div>}
                      //   contentWidth={200}
                      // footer={<div>Footer</div>}
                      bordered
                      dataSource={[
                        <Text ellipsis={true}>{data?.liveDays && (
                          <Space style={{ margin: "10px 0" }}>
                            {" "}
                            Live Day IDs :{" "}
                            {data?.liveDays.map((ld) => {
                              return <Badge count={"ID : " + ld.id} />
                            })}
                          </Space>)
                        }</Text>,
                        <Text ellipsis={true} style={{ display: "flex", justifyContent: "center", width: "100%", overflowY: "scroll", height: "300px" }}> {data?.doc?.liveMetaData ? (
                          <Timeline
                            mode="alternate"
                            style={{ width: "500px" }}
                            items={data?.doc?.liveMetaData?.map((data) => {
                              return {
                                children: (
                                  <>
                                    <p> Id :{data?.id} </p>
                                    <p>Meet URL : <Text
                                      style={{ width: 100 }}
                                      ellipsis={{ tooltip: `${data?.url}` }}
                                      copyable={data?.url}
                                    // ellipsis={ellipsis ? { tooltip: 'I am ellipsis now!' } : false}
                                    >
                                      {data?.url}
                                    </Text></p>
                                    <p>Recorded URL : <Text
                                      style={{ width: 100 }}
                                      ellipsis={{ tooltip: `${data?.recordedLecture}` }}
                                      copyable={data?.recordedLecture}
                                    // ellipsis={ellipsis ? { tooltip: 'I am ellipsis now!' } : false}
                                    >
                                      {data?.recordedLecture}
                                    </Text></p>
                                    <p>Date : {new Date(data?.date).toLocaleString()} </p>
                                  </>
                                ),
                              }
                            })}
                          />
                        ) : (
                          <Empty description="No history found" />
                        )}</Text>,
                      ]}
                      renderItem={(item) => <List.Item>{item}</List.Item>}
                    />
                  </div>
                </div>

              </Card>)}

            </Content>
          </Layout>
        </Layout>
      </>}


    </>
  )
}

export default BatchModify
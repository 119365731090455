import React, { useState ,useEffect} from "react"
import { MenuOutlined,CheckCircleFilled } from "@ant-design/icons"
import { Empty, Layout, Menu, theme, Avatar, Dropdown,notification } from "antd"
import Logo from "../../images/logo.svg"
import Sip from "../SIP/LeadsCRM"
import Analytics from "../SIP/Analytics"
import PaidUser from "../SIP/PaidUser"
import Category from "../Category"
import Batch from "../Batch/Batch"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../contexts/authContext"
// import items from "../SideNavList"
import navList from "../SideNavList"
import useView from "../../hooks/useView"
import { ConnectRoom } from "../../util/socket"
import { socket } from "../../util/socket"
import notify from '../../Sounds/notification.mp3'
import constants from "../../constants"
import { Howl } from 'howler';



const { Header, Content, Footer, Sider } = Layout

export default function Dashboard({ component }) {
  const [collapsed, setCollapsed] = useState(true)
  const { currentUser, roles, logout } = useAuth()
  const [NotifyApi, contextNofify] = notification.useNotification();
  const isAdmin = roles.includes("ADMIN")  || roles.includes("SALES_ADMIN") || roles.includes("OPERATIONS")
  const { isMobile } = useView()
  // console.log(roles)
  const Navigate = useNavigate()
  const onClick = (e) => {
    // setCurrent(e.key)
    // console.log(e.key);
    if (isMobile) {
      setCollapsed(!collapsed)
    }
    Navigate(e.key || "/")
  }
  const signout = () => {
    // console.log("log");
    logout()
    Navigate("/signin")
  }
  useEffect(() => {
    ConnectRoom(isAdmin?constants.ADMIN_ROOM_ID:currentUser._delegate.uid)
  }, []);
  useEffect(() => {
  
    socket.on("payment_successful",(raw) => {
   // play sound
   const sound = new Howl({
    src: notify,
  });
  sound.play();
   //pop notification
   NotifyApi.open({
     message: (<div style={{color:"white",fontWeight:"bolder"}}>
      Payment Successfull
     </div>),
     description:(<div style={{color:"white"}}>
     <p>Name : {raw?.lead?.name}</p>
     <p>Phone : {raw?.lead?.phone}</p>
     {isAdmin && <p>POC : {raw?.POC}</p>}
    </div>),
       duration: 0,
       placement:"top",
       style:{
         backgroundColor:"#35b729",
         fontWeight:"bolder",
     },
     icon: (
       <CheckCircleFilled
         style={{
           color: 'whitesmoke',
         }}
       />
     ),
   });
     });
   }, [socket]);
  const items = [
    {
      key: "1",
      label: <a>Hello, {currentUser?.email.split("@")[0]}</a>,
    },
    {
      key: "2",
      label: <a onClick={() => signout()}>Logout</a>,
    },
  ]
  return (
    <Layout hasSider>
      {contextNofify}
      <Sider
        breakpoint="lg"
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth={0}
        width={250}
        onBreakpoint={(broken) => {
          //broken : True when screen size is less then lg
          setCollapsed(broken)
        }}
        className="slider"
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          background: "#0A1628",
          color: "#FFFFFF",
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 1,
        }}
      >
        <div
          style={{
            height: 42,
            margin: 26,
            // background: "rgba(255, 255, 255, 0.2)",
          }}
        >
          <img src={Logo} width={100} />
        </div>
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={[]}
          // defaultOpenKeys={["sip"]}
          items={navList(roles)}
          onClick={onClick}
          style={{ backgroundColor: "#0A1628", color: "white" }}
        />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          transitionTimingFunction: "ease-in",
          transition: "0.2s",
          marginLeft: collapsed ? 0 : isMobile ? 0 : 250,
        }}
      >
        <Header
          style={{
            // padding: 0,
            padding: "0 15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // background: "#f5f5f5",
            background: "#0A1628",
            color: "white",
            // margin: "0 10px",
            marginLeft: collapsed ? 0 : isMobile ? 250 : 0,
          }}
        >
          {React.createElement(MenuOutlined, {
            onClick: () => setCollapsed(!collapsed),
            style: { fontSize: "1.5rem", color: "#54D8E2" },
          })}
          {/* <p>Hello {currentUser?.email}</p> */}
          <Dropdown
            menu={{ items }}
            placement="bottomRight"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <Avatar
              style={{
                backgroundColor: "#971eff",
                verticalAlign: "middle",
                cursor: "pointer",
              }}
              size="large"
            >
              {currentUser?.email.slice(0, 2).toUpperCase()}
            </Avatar>
          </Dropdown>
        </Header>

        <Content
          style={{
            marginTop: "24px",
            background: "#f5f5f5",
            overflow: "initial",
            minHeight: "70vh",
          }}
        >
          {/* <h1 className="text-3xl">Hello</h1> */}
          {component}
        </Content>

        <Footer
          style={{
            textAlign: "center",
          }}
        >
          YouVah ©2023
        </Footer>
      </Layout>
    </Layout>
  )
}

import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row, Select, Table, Input, Typography, Space, Alert, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import constants, { idToPOC } from '../../constants';
import { useAuth } from '../../contexts/authContext';
import Loader from '../../util/loader';

const { Title, Text } = Typography;
const { Option } = Select;

const PocAnalytics = () => {
  const [data, setData] = useState(null);
  const [dataPoc, setDataPoc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [errorPoc, setErrorPoc] = useState('');
  const { currentUser,roles} = useAuth();
  const [utm, setUtm] = useState('');
  const [utmPoc, setUtmPoc] = useState('');
  const [poc, setPoc] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [system, setSystem] = useState([]);
  const isAdmin = roles.includes("ADMIN")
  useEffect(() => {
    systemUser();
  }, []);

  const systemUser = async () => {
    setLoading(true);
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/systemuser`;
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      });
      setSystem(res.data.doc);
    } catch (error) {
      console.error('Error fetching system users:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (type) => {
    const isGlobal = type === 'global';
    const errorSetter = isGlobal ? setError : setErrorPoc;
    const dataSetter = isGlobal ? setData : setDataPoc;

    errorSetter('');

    if ((!utm && isGlobal) || (!utmPoc && !isGlobal)) {
      errorSetter('Month is required.');
      return;
    }

    if (isGlobal && !inputValue) {
      errorSetter('Tag ID is required.');
      return;
    }

    if (!isGlobal && !poc) {
      errorSetter('POC is required.');
      return;
    }

    try {
      setLoading(true);
      const url = isGlobal
        ? `${constants.NEW_DOMAIN}/api/v1/admin/globalAnalytics?tag=${utm}_${inputValue}`
        : `${constants.NEW_DOMAIN}/api/v1/admin/pocAnalytics?month=${utmPoc}&pocId=${poc}&year=2024`;
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
        },
      });
      const responseData = res.data?.data || [];
      dataSetter(responseData);
      if (responseData.length === 0) {
        errorSetter('No Data Found');
      }
    } catch (error) {
      errorSetter('Failed to fetch data. Please try again later.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Poc / Tag',
      dataIndex: '_id',
      render: (text) => idToPOC[text] || text || 'N/A',
    },
    ...[
      'JUNK', 'CONVERTED', 'DIRECT', 'HOT', 'COLD', 
      'CALL NOT PICKED TEXT SENT', 'NOT INTERESTED', 'AGE NOT VALID', 
      'INVALID NUMBER', 'Untouched'
    ].map(key => {
      const formattedTitle = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
      const title = formattedTitle.length > 10 
        ? (
            <Tooltip title={formattedTitle}>
              {formattedTitle.slice(0, 10)}...
            </Tooltip>
          ) 
        : formattedTitle;
        
      return {
        title,
        dataIndex: key,
        key: key,
        render: (text) => text || 0,
        sorter: (a, b) => (a[key] || 0) - (b[key] || 0),
      };
    }),
  ];

  const renderAnalyticsSection = (title, errorState, dataState, fetchFunction) => (
    <Card title={<Title level={3}>{title}</Title>} className="mb-8">
      {errorState &&  <Alert message={errorState} type='error' banner  style={{fontWeight:"bold",marginBottom:"10px"}} />}
      <Row gutter={16} className="mb-4">
        <Col span={6}>
          <Select
            placeholder="Select Month"
            style={{ width: '100%' }}
            onChange={(value) => title.includes('Global') ? setUtm(value) : setUtmPoc(value)}
          >
            <Option value="">None</Option>
            {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month, index) => (
              <Option key={month} value={title.includes('Global') ? `${month.toUpperCase()}_2024` : (index + 1).toString()}>{month}</Option>
            ))}
          </Select>
        </Col>
        <Col span={6}>
          {title.includes('Global') ? (
            <Input
              placeholder="Enter Tag Id"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          ) : (
            <Select
              placeholder="Select POC"
              style={{ width: '100%' }}
              onChange={(e) => setPoc(e)}
            >
              {system.map((d) => (
                <Option key={d._id} value={d._id}>{d.name}</Option>
              ))}
            </Select>
          )}
        </Col>
        <Col span={6}>
          <Button type="primary" onClick={() => fetchFunction(title.includes('Global') ? 'global' : 'poc')}>
            Fetch Data
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Loader />
      ) : dataState && dataState.length > 0 ? (
        <>
          <Title level={4}>
            Data According to {title.includes('Global') ? `Global Counter (${+inputValue > 1 ? +inputValue * 100 : inputValue} - ${(+inputValue > 1 ? +inputValue * 100 + 100 : +inputValue + 99)})` : 'Poc Counter ( Per 100 leads )'}
          </Title>
          <Table
            columns={columns}
            dataSource={dataState}
            pagination={{ pageSize: 15 }}
            rowKey="_id"
            className="analytics-table"
          />
        </>
      ) : null}
    </Card>
  );

  return (
    <div className="poc-analytics-container">
      <Title>Business Analytics</Title>
      <Text>Last updated: {new Date().toLocaleDateString()}</Text>
      <Space direction="vertical" size="large" style={{ width: '100%', marginTop: '20px' }}>
        {isAdmin && renderAnalyticsSection('Global Counter', error, data, fetchData)}
        {renderAnalyticsSection('Poc Counter', errorPoc, dataPoc, fetchData)}
      </Space>
    </div>
  );
};

export default PocAnalytics;
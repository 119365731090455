import { React, useRef,useEffect,useState } from "react"
import "./previewResume.css"
import mail from "./resumeImage/email.svg"
import phone from "./resumeImage/phone.svg"
import logo from "./resumeImage/Group (1).png"
import Link from "./resumeImage/link.svg"
import { useParams } from "react-router-dom"
import constants from "../../constants"
import axios from "axios"
import Loader from "../../util/loader"


function PreviewResume() {
  const componentRef = useRef()
  const {id} = useParams()
  const [profile, setProfile] = useState();
  const [status, setStatus] = useState({
    error: false,
    loading: false,
    success: true,
  })
  const fetchResume = async () => {
    try {
      setStatus({ loading: true, error: false, success: false })
      console.log(process.env.REACT_APP_JWT_TOKEN)
      const url = `${constants.NEW_DOMAIN}/api/v1/user/resume/get/${id}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setProfile(res.data.data.profile)
      setStatus({ loading: false, error: false, success: false })
    } catch (error) {
      console.log(error.response)
      setStatus({
        loading: false,
        error: "Something went wrong",
        success: false,
      })
    }
  }
  useEffect(() => {
   fetchResume()
  }, [id]);
  const getAge = (dateString) => {
    if (!dateString) return "[empty DOB]"
    let today = new Date()
    let birthDate = new Date(dateString)
    let age = today.getFullYear() - birthDate.getFullYear()
    let m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  }

  if (status.loading) {
    return <Loader />
  }

  return (
    < div style={{backgroundColor:"gray"}}>
      <div className="preview-section">
        <p>Resume</p>
       
      </div>
       
      <div className="overall-wrapper" >
        <div className="scroll">
          <div className="resume" id="resume" ref={componentRef}>
            <div className="resume_left">
              <div className="resume_content" style={{ marginTop: "24px" }}>
                <div className="resume_item resume_info">
                  <div className="title" style={{ textAlign: "center" }}>
                    <h1>{profile?.personalDetails?.name || "[empty name]"}</h1>
                    <br />
                    <h2>
                      {profile?.personalDetails.gender || "[empty gender]"},{" "}
                      {getAge(profile?.personalDetails?.dob)}
                    </h2>
                  </div>
                </div>
                <div className="resume_item resume_skills">
                  <div className="title">
                    <p>Contact</p>
                  </div>
                  <ul>
                    <li>
                      <img
                        src={mail}
                        height="11"
                        width="11"
                        style={{ marginRight: "6px" }}
                        alt="Vector"
                        border="0"
                      />
                      <div className="data">
                        {profile?.personalDetails.email || "[empty email]"}
                      </div>
                    </li>
                    <li>
                      <img
                        src={phone}
                        height="11"
                        width="11"
                        style={{ marginRight: "6px" }}
                        alt="Vector"
                        border="0"
                      />
                      <div className="data">
                        {profile?.personalDetails.phone || "[empty phone]"}
                      </div>
                    </li>
                    {/* <li>
                      <img
                        src={location}
                        height="11"
                        width="11"
                        style={{ marginRight: "6px" }}
                        alt="Vector"
                        border="0"
                      />
                      <div className="data">Joydevpur, Gazipur, Banglore</div>
                    </li> */}
                  </ul>
                </div>
                <div className="resume_item resume_skills">
                  <div className="title">
                    <p
                      className="bold"
                      style={{
                        color: "#FCFCFC",
                        fontWeight: "500",
                      }}
                    >
                      Portfolio/Work sample
                    </p>
                  </div>
                  <ul>
                    <li>
                      <div className="data">
                        <img
                          src={Link}
                          height="16"
                          width="16"
                          style={{ marginRight: "6px" }}
                          alt="Vector"
                          border="0"
                        />
                        <a href={profile?.portfolio || "#"}>
                          {profile?.portfolio || "[empty link]"}
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="resume_item resume_skills">
                  <div className="title">
                    <p
                      className="bold"
                      style={{
                        color: "#FCFCFC",
                        fontWeight: "500",
                      }}
                    >
                      Soft Skills
                    </p>
                  </div>
                  <ul>
                    {profile?.softSkills.map((skill, index) => {
                      return (
                        <li key={index}>
                          <div className="data">{skill || "[empty skill]"}</div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className="resume_item resume_skills">
                  <div className="title">
                    <p
                      className="bold"
                      style={{
                        color: "#FCFCFC",
                        fontWeight: "500",
                      }}
                    >
                      Technical Skills
                    </p>
                  </div>
                  <ul>
                    {profile?.techSkills.map((skill, index) => {
                      return (
                        <li key={index}>
                          <div className="data">{skill || "[empty skill]"}</div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className="resume_item resume_skills">
                  <div className="title">
                    <p
                      className="bold"
                      style={{
                        color: "#FCFCFC",
                        fontWeight: "500",
                      }}
                    >
                      Langauge's
                    </p>
                  </div>
                  <ul>
                    {(profile?.personalDetails.languages || []).map(
                      (lang, index) => {
                        return (
                          <li key={index}>
                            <div className="data">{lang}</div>
                          </li>
                        )
                      }
                    )}
                  </ul>
                </div>
                {/* <div className="resume_item resume_skills">
                  <div className="title">
                    <p
                      className="bold"
                      style={{
                        color: "#FCFCFC",
                        fontWeight: "500",
                      }}
                    >
                      Hobbies
                    </p>
                  </div>
                  <ul>
                    <li>
                      <div className="skill_name">Music</div>
                    </li>
                    <li>
                      <div className="skill_name">Singing</div>
                    </li>
                    <li>
                      <div className="skill_name">Travelling</div>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="resume_right">
              <div className="resume_item resume_about">
                <div className="title">
                  <p>About me</p>
                </div>
                <h3>{profile?.personalDetails.bio || "[empty bio]"}</h3>
              </div>
              <div className="resume_item resume_education">
                <div className="title">
                  <p className="bold">Education</p>
                </div>
                <ul>
                  {profile?.educationalDetails.map((edu, index) => {
                    return (
                      <li key={index}>
                        {/* <div className="date">2010 - 2013</div>  */}
                        <div className="info">
                          <p
                            className="semi-bold"
                            style={{ lineHeight: "18px" }}
                          >
                            Institution : {edu.school || "[empty school]"}
                          </p>
                          <p
                            className="semi-bold"
                            style={{ lineHeight: "18px" }}
                          >
                            Class : {edu.class || "[empty class]"}
                          </p>
                          <p
                            className="semi-bold"
                            style={{ lineHeight: "18px" }}
                          >
                            Grades : {edu.grade || "[empty grade]"} CGPA
                          </p>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
              {JSON.stringify(profile?.previousWork) !==
                JSON.stringify([{}]) && (
                <div className="resume_item resume_work">
                  <div className="title">
                    <p className="bold">Internship Experience</p>
                  </div>
                  <ul>
                    {profile?.previousWork.map((work, index) => {
                      return (
                        <li key={index}>
                          <div className="info">
                            <p
                              className="semi-bold"
                              style={{ lineHeight: "18px" }}
                            >
                              {work.company}
                            </p>
                            <p
                              className="semi-bold"
                              style={{ lineHeight: "18px" }}
                            >
                              {work.jobTitle}
                            </p>
                            {/* <div className="date" style={{ lineHeight: "18px" }}>
                            From: 02/09/1999 - To: 02/09/1999
                          </div> */}
                            <p style={{ lineHeight: "18px" }}>
                              {work.description}
                            </p>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}

              <div className="resume_item resume_education">
                <div className="title">
                  <p className="bold">Training/Courses</p>
                </div>
                <ul>
                  {profile?.certifications.map((course, index) => {
                    return (
                      <li key={index}>
                        <div className="info">
                          <p
                            className="semi-bold"
                            style={{ lineHeight: "18px" }}
                          >
                            {course.title}
                          </p>
                          <p
                            className="semi-bold"
                            style={{ lineHeight: "18px" }}
                          >
                            {course.organization}
                          </p>
                         <a href={course.certificate}> <p> Certificate : {course.certificate}</p> </a>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
              {profile?.achievements !== "" && (
                <div className="resume_item resume_about">
                  <div className="title">
                    <p className="bold">Achievements / Recognition</p>
                  </div>
                  <p>{profile?.achievements}</p>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  right: "10px",
                  position: "absolute",
                  bottom: "10px",
                }}
              >
                <p style={{ marginTop: "5px" }}>Powered By</p>
                <div>
                  <img width="90" src={logo} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
  )
}

export default PreviewResume
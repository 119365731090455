import React, { useEffect, useRef } from 'react';
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import 'ace-builds/src-noconflict/theme-monokai';
import './jsoneditor-dark-theme.css';

const Json = ({ jsonData, onChange,mode }) => {
  const editorRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const options = {
      mode: mode,
      theme: 'ace/theme/monokai', // Set Ace Editor dark theme
      onChangeText: (jsonString) => {
        if (mode === 'code' || mode === 'text') {
          try {
            const json = JSON.parse(jsonString);
            onChange(json);
          } catch (error) {
            console.error('Invalid JSON', error);
          }
        }
      },
      onChangeJSON: (json) => {
        if (mode !== 'code' && mode !== 'text') {
          onChange(json);
        }
      },
    };

    if (editorRef.current) {
      editorRef.current.destroy();
    }

    editorRef.current = new JSONEditor(containerRef.current, options);
    editorRef.current.set(jsonData);

    return () => {
      if (editorRef.current) {
        editorRef.current.destroy();
      }
    };
  }, [mode]);

  useEffect(() => {
    // if (editorRef.current && mode !== 'code' && mode !== 'text') {
      editorRef.current.update(jsonData);
    // }
  }, [jsonData, mode]);

  return <div ref={containerRef} style={{ height: '68dvh' }} />;
};

export default Json

import axios from "axios"
import { useEffect, useState, useRef } from "react"
import excelJS from "exceljs"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  json,
  useParams,
} from "react-router-dom"
import dayjs from "dayjs"
import TextArea from "antd/es/input/TextArea"
import constants from "../../constants"
import {
  Row,
  Col,
  Select,
  Space,
  Card,
  Typography,
  message,
  Skeleton,
  Avatar,
  Modal,
  Divider,
  Statistic,
  Grid,
  Button,
  List,
  Tag,
  Drawer,
  Table,
  Tooltip,
  Input,
  Segmented,
} from "antd"
import {
  HomeOutlined,
  ZoomInOutlined,
  FileSearchOutlined,
  LoadingOutlined,
  ArrowDownOutlined,
  ScissorOutlined,
  ReloadOutlined,
  ExportOutlined,
  ArrowLeftOutlined,
  ContainerTwoTone,
  SearchOutlined,
  UserOutlined,
  StopTwoTone,
  BarsOutlined,
  AppstoreOutlined,
} from "@ant-design/icons"

import * as FileSaver from "file-saver"
import { useAuth } from "../../contexts/authContext"
const { Option } = Select
const { Text } = Typography
const Schools = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const { type, id } = useParams()
  const Navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const [view, setView] = useState(true)
  const [viewType, setViewType] = useState("PAID")

  // student data

  const [dataSchool, setSchoolData] = useState()
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      position: ["topCenter"],
    },
    filters: {},
  })
  const { currentUser } = useAuth()
  // const { token } = theme.useToken();
  const [open, setOpen] = useState(false)

  // table filter

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
       <>{text}</>
      ) : (
        text
      ),
  });


  // school side code
  const exportReportData = async (data) => {
    console.log(data)
    const workbook = new excelJS.Workbook()
    const worksheet = workbook.addWorksheet("Overall User")
    worksheet.columns = [
      { header: "Name", key: "name", width: 10 },
      { header: "Email", key: "email", width: 10 },
      { header: "Phone", key: "phone", width: 10 },
      { header: "Category", key: "category", width: 10 },
      { header: "DOB", key: "dob", width: 10 },
      { header: "Class", key: "standard", width: 10 },
    ]
    await data.paid.map((data, index) => {
      worksheet.addRow({
        name : data.name,
        email : data.email,
        phone : data.phone,
        category :data.category,
        dob : data.dob,
        standard : data.standard,
      })
    })
    const worksheet1 = workbook.addWorksheet("Training Completed")
    worksheet1.columns = [
      { header: "Name", key: "name", width: 10 },
      { header: "Email", key: "email", width: 10 },
      { header: "Phone", key: "phone", width: 10 },
      { header: "Batch", key: "batch", width: 10 },
    ]
    await data.training.map((data, index) => {
      worksheet1.addRow({
        name : data.name,
        email : data.email,
        phone : data.phone,
        batch : data.batch
      })
    })
    const worksheet2 = workbook.addWorksheet("Assessment Completed")
    worksheet2.columns = [
      { header: "Name", key: "name", width: 10 },
      { header: "Email", key: "email", width: 10 },
      { header: "Phone", key: "phone", width: 10 },
      { header: "Category", key: "category", width: 10 },
      { header: "batch", key: "batch", width: 10 },
    ]
    await data.assessment.map((data, index) => {
      worksheet2.addRow({
        name : data.name,
        email : data.email,
        phone : data.phone,
        category : data.category,
        batch : data.batch,
      })
    })

    const worksheet3 = workbook.addWorksheet("Internship Started")
    worksheet3.columns = [
      { header: "Name", key: "name", width: 10 },
      { header: "Email", key: "email", width: 10 },
      { header: "Phone", key: "phone", width: 10 },
      { header: "Category", key: "category", width: 10 },
      { header: "Company", key: "company", width: 10 },
      { header: "Week 1", key: "week1", width: 10 },
      { header: "Week 2", key: "week2", width: 10 },
      { header: "Week 3", key: "week3", width: 10 },
      { header: "Week 4", key: "week4", width: 10 },
    ]
    await data.internship.map((data, index) => {
      worksheet3.addRow({
        name:data.name,
        email : data.email,
        phone : data.phone,
        category : data.category,
        company : data.company,
        week1: data.task1,
        week2: data.task2,
        week3: data.task3,
        week4: data.task4
      })
    })
    workbook.xlsx.writeBuffer().then(function (buffer) {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      })
      FileSaver.saveAs(blob, "ClubReport.xlsx")
    })
  }
  const renderHeader = () => {
    return (
      <Space size={16}>
        <Button
          type="default"
          icon={<ScissorOutlined />}
          onClick={() => setTableParams({ ...tableParams, filters: {} })}
          loading={loading}
        >
          Clear Filters
        </Button>
        <Button
          type="default"
          icon={<ReloadOutlined />}
          onClick={() => fetchReportData(false, id)}
          loading={loading}
        >
          Reload
        </Button>
      
          <Button
            type="default"
            icon={<ExportOutlined />}
            onClick={() => exportReportData(dataSchool)}
            loading={loading}
          >
            Export Report
        </Button>
        <Button
          type="default"
          icon={<ArrowLeftOutlined />}
          onClick={() => Navigate("/b2b/addLead")}
          loading={loading}
        >
          Go Back
        </Button>
      </Space>
    )
  }
  const columns = viewType === "ASSESSMENT"
      ? [
          {
            title: "Name",
            dataIndex: "name",
            render: (user) => {
              return <div>{user}</div>
            },
            ...getColumnSearchProps('name'),
          },
          {
            title: "Phone",
            dataIndex: "phone",
            render: (user) => {
              return <div>{user}</div>
            },
            ...getColumnSearchProps('phone'),
          },
          {
            title: "Email",
            dataIndex: "email",
            render: (user) => {
              return <div>{user}</div>
            },
            ...getColumnSearchProps('email'),
          },
          {
            title: "Category",
            dataIndex: "category",
            render: (code) => {
              let color =
                code.code === "SMM"
                  ? "#B2A4FF"
                  : code.code === "VE"
                  ? "#E11299"
                  : code.code === "CW"
                  ? "#867070"
                  : "#E4D0D0"
              return <Tag color={color}>{code}</Tag>
            },
          },
          {
            title: "Batch",
            dataIndex: "batch",
            render: (data) => {
              return <div>{data}</div>
            },
          },
          {
            title: "MCQ",
            dataIndex: "mcq",
            render: (data) => {
              return <div>{data}</div>
            },
          },
          {
            title: "Portfolio",
            dataIndex: "portfolio",
            width: 160,
            render: (progress) => {
              return (
                <a href={progress} target="blank">
                  <Button type="dashed">View Portfolio</Button>
                </a>
              )
            },
            // ...getColumnSearchProps("batchId"),
          },
        ]
      : viewType === "INTERNSHIP"
      ? [
          {
            title: "Name",
            dataIndex: "name",
            width: 160,
            render: (user) => {
              return <div>{user}</div>
            },
            ...getColumnSearchProps('name'),
          },
          {
            title: "Phone",
            dataIndex: "phone",
            width: 160,
            render: (user) => {
              return <div>{user}</div>
            },
            ...getColumnSearchProps('phone'),
          },
          {
            title: "Email",
            dataIndex: "email",
            width: 180,
            render: (user) => {
              return <div>{user}</div>
            },
            ...getColumnSearchProps('email'),
          },
          {
            title: "Category",
            dataIndex: "program",
            width: 100,
            render: (code) => {
              let color =
                code === "SMM"
                  ? "#B2A4FF"
                  : code === "VE"
                  ? "#E11299"
                  : code === "CW"
                  ? "#867070"
                  : "#E4D0D0"
              return <Tag color={color}>{code}</Tag>
            },
          },
          {
            title: "Portfolio",
            dataIndex: "portfolio",
            width: 160,
            render: (progress) => {
              return (
                <a href={progress} target="blank">
                  <Button type="dashed">View Portfolio</Button>
                </a>
              )
            },
            // ...getColumnSearchProps("batchId"),
          },
          {
            title: "Company",
            dataIndex: "company",
            width: 160,
            render: (user) => {
              return <div>{user}</div>
            },
            // ...getColumnSearchProps("batchId"),
          },
          {
            title: "Intern.. Task 1",
            dataIndex: "task1",
            width: 210,
            render: (progress) => {
              return (
                <>
                  {progress === undefined ? (
                    <Tag color="red">PENDING</Tag>
                  ) : (
                    <div>
                      Task :{" "}
                      <a href={progress} target="blank">
                        <Button type="dashed">View</Button>
                      </a>
                    </div>
                  )}
                </>
              )
            },
          },
          {
            title: "Intern.. Task 2",
            dataIndex: "task2",
            width: 210,
            render: (progress) => {
              return (
                <>
                  {progress === undefined ? (
                    <Tag color="red">PENDING</Tag>
                  ) : (
                    <div>
                      Task :{" "}
                      <a href={progress} target="blank">
                        <Button type="dashed">View</Button>
                      </a>
                    </div>
                  )}
                </>
              )
            },
          },
          {
            title: "Intern.. Task 3",
            dataIndex: "task3",
            width: 210,
            render: (progress) => {
              return (
                <>
                  {progress === undefined ? (
                    <Tag color="red">PENDING</Tag>
                  ) : (
                    <div>
                      Task :{" "}
                      <a href={progress} target="blank">
                        <Button type="dashed">View</Button>
                      </a>
                    </div>
                  )}
                </>
              )
            },
          },
          {
            title: "Intern.. Task 4",
            dataIndex: "task4",
            width: 210,
            render: (progress) => {
              return (
                <>
                  {progress === undefined ? (
                    <Tag color="red">PENDING</Tag>
                  ) : (
                    <div>
                      Task :{" "}
                      <a href={progress} target="blank">
                        <Button type="dashed">View</Button>
                      </a>
                    </div>
                  )}
                </>
              )
            },
          },
        ]
      : [
          {
            title: "Name",
            dataIndex: "name",
            ...getColumnSearchProps('name'),
          },
          {
            title: "Phone",
            dataIndex: "phone",
            render: (phone) => <a>{phone}</a>,
            ...getColumnSearchProps('phone'),
          },
          {
            title: "Email",
            dataIndex: "email",
            ...getColumnSearchProps('email'),
          },
          {
            title: "Category",
            dataIndex: "category",
            render: (code) => {
              let color =
                code === "SMM"
                  ? "#B2A4FF"
                  : code === "VE"
                  ? "#E11299"
                  : code === "CW"
                  ? "#867070"
                  : "#E4D0D0"
              return <Tag color={color}>{code}</Tag>
            },
          },
        ]

  const renderStatus = (_, record) => {
    if (record?.profile != undefined) return <UserOutlined />
  }
  const handleTableChange = (pagination, filters, sorter, extra) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    })

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([])
    }
  }

  const onClose = () => {
    setOpen(false)
  }

  const fetchReportData = async (isExport = false, id = undefined) => {
    setLoading(true)
    try {
      let query = []
      Object.keys(tableParams.filters).forEach((k) => {
        if (
          tableParams.filters[k] !== null &&
          tableParams.filters[k].length > 0
        ) {
          query.push(`${k}=${tableParams.filters[k]}`)
        }
      })
      const preQuery = isExport
        ? `page=${tableParams.pagination.current}&limit=1000&firebaseId=${currentUser._delegate.uid}&`
        : `page=${tableParams.pagination.current}&limit=${tableParams.pagination.pageSize}&firebaseId=${currentUser._delegate.uid}&`
      const q = preQuery + query.join("&")

      const url = `${constants.NEW_DOMAIN}/api/v1/school/generateFullSchoolReport/${id}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setView('PAID')
      console.log(res.data.data)
      setSchoolData(res.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    fetchReportData(false,id)
  }, [])
  

  return (
        <>
      {contextHolder}
      <Row>
              <Col xs={24} md={20}>
                <div className="text-3xl">{data?.name} Students Data</div>
                <p className="text-lg-light">
                  {new Date().toDateString()} -{" "}
                  {new Date().toLocaleTimeString()}
                </p>
              </Col>

              <Col xs={24} md={4}>
                {/* <Statistic
                  title="Total User"
                  loading={loading}
                  value={
                    viewType === "STUDENT"
                      ? dataSchool?.results
                      : viewType === "PAID"
                      ? dataSchool?.paid?.length
                      : dataSchool?.paid?.length
                  }
                  precision={0}
                  valueStyle={{
                    color: "blue",
                  }}
                  // prefix={<ArrowUpOutlined />}
                  suffix="users"
                /> */}
              </Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                {renderHeader()}
                <br></br>
                 <div style={{background:"white",border:"2px solid #971eff",width:'fit-content',borderRadius:"12px",padding:"1px"}}>
                  <Segmented
                    onChange={(e) => setViewType(e)}
                    options={[
                      {
                        label: "Paid",
                        value: "PAID",
                        icon: <BarsOutlined />,
                      },
                      {
                        label: "Training",
                        value: "TRAINING",
                        icon: <AppstoreOutlined />,
                      },
                      {
                        label: "Assessment",
                        value: "ASSESSMENT",
                        icon: <AppstoreOutlined />,
                      },
                      {
                        label: "Internship",
                        value: "INTERNSHIP",
                        icon: <AppstoreOutlined />,
                      },
                    ]}
                  />
              </div>
              </div>
            </Row>

            <Table
              columns={columns}
              loading={loading}
              // title={renderHeader}
              dataSource={ viewType === "PAID"
                  ? dataSchool?.paid
                  : viewType === "TRAINING"
                  ? dataSchool?.training
                  : viewType === "ASSESSMENT"
                  ? dataSchool?.assessment
                  : dataSchool?.internship
              }
              onChange={handleTableChange}
              pagination={tableParams.pagination}
              rowKey={(record) => record._id}
              scroll={{
                x: 900,
                y: 500,
              }}
            />
       
        </>
  )
}
export default Schools

import React, { useEffect, useState, useRef } from "react"
import {
  Space,
  Table,
  Tag,
  Button,
  Input,
  Row,
  Col,
  Statistic,
  Tooltip,
  Modal,
  Alert,
  message,
  Divider,
  Card,
  Popconfirm,
  Upload,
  Progress
} from "antd"
import {
  SearchOutlined,
  ScissorOutlined,
  ReloadOutlined,
  ExportOutlined,
  EditOutlined,
  UserOutlined,
  WhatsAppOutlined,
  TagTwoTone,
  InfoCircleTwoTone,
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  UploadOutlined
} from "@ant-design/icons"
import axios from "axios"
import excelJS from "exceljs"
import constants from "../../constants"
import * as FileSaver from "file-saver"
import XLXS from "sheetjs-style"
import { Form, Select } from "antd"
import Company from "../Company"
import useView from "../../hooks/useView"
import { useAuth } from "../../contexts/authContext"

import ExcelJS from 'exceljs'; // Import ExcelJS
import { socket } from "../../util/socket"

const { Option } = Select

const tailLayout = {
  wrapperCol: {
    offset: 5,
    span: 16,
  },
}
const Export = () => {

  const [data, setData] = useState()
  const [slots, setSlots] = useState({})
  const [category, setCategory] = useState()
  const [loading, setLoading] = useState(false)
  const searchInput = useRef(null)
  const { isMobile, width } = useView()
  const [open, setOpen] = useState(false)
  const [view, setView] = useState('CREATE')
  const [convertedSchool, setConvertedSchool] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [field, setField] = useState(false)
  const { currentUser, roles } = useAuth()
  const isAdmin = roles.includes("ADMIN")
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      position: ["topCenter"],
    },
    filters: {},
  })

  const [poc, setPoc] = useState([])

  const [uploadedExcelFile, setUploadedExcelFile] = useState(null); // State to hold uploaded Excel file
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [percent, setPercent] = useState({
    count:0,
    percentage:0
  })
  const [formValues, setFormValues] = useState({});

  const handleCancel = () => {
    console.log("Clicked cancel button")
    setOpen(false)
  }
  const exportExcel = async (data) => {
    const workbook = new excelJS.Workbook()
    const worksheet = workbook.addWorksheet("Overall User")
    worksheet.columns = [
      { header: "Name", key: "name", width: 10 },
      { header: "Email", key: "email", width: 10 },
      { header: "Phone", key: "phone", width: 10 },
      { header: "Category", key: "category", width: 10 },
      { header: "DOB", key: "dob", width: 10 },
      { header: "Class", key: "standard", width: 10 },
      { header: "City", key: "city", width: 10 },
      { header: "school", key: "school", width: 10 },
    ]
    await data.paid.map((data, index) => {
      worksheet.addRow({
        name: data?.name,
        email: data?.email,
        phone: data?.phone,
        category: data?.categoryCode,
        dob: data?.profile.dob,
        standard: data?.profile.standard,
        city: data?.profile.city,
        school: data.schoolId ? data.schoolId.name : data?.profile.school,
      })
    })
    const worksheet1 = workbook.addWorksheet("Training Completed")
    worksheet1.columns = [
      { header: "Name", key: "name", width: 10 },
      { header: "Email", key: "email", width: 10 },
      { header: "Phone", key: "phone", width: 10 },
    ]
    await data.training.map((data, index) => {
      worksheet1.addRow({
        name: data?.name,
        email: data?.email,
        phone: data?.phone,
      })
    })
    const worksheet2 = workbook.addWorksheet("Assessment Completed")
    worksheet2.columns = [
      { header: "Name", key: "name", width: 10 },
      { header: "Email", key: "email", width: 10 },
      { header: "Phone", key: "phone", width: 10 },
      { header: "Category", key: "category", width: 10 },
      { header: "batch", key: "batch", width: 10 },
    ]
    await data.assessment.map((data, index) => {
      worksheet2.addRow({
        name: data?.userId?.name,
        email: data?.userId?.email,
        phone: data?.userId?.phone,
        category: data?.categoryId?.code,
        batch: data?.batchId?.batchId,
      })
    })

    const worksheet3 = workbook.addWorksheet("Internship Started")
    worksheet3.columns = [
      { header: "Name", key: "name", width: 10 },
      { header: "Email", key: "email", width: 10 },
      { header: "Phone", key: "phone", width: 10 },
      { header: "Category", key: "category", width: 10 },
      { header: "Company", key: "company", width: 10 },
      { header: "Week 1", key: "week1", width: 10 },
      { header: "Week 2", key: "week2", width: 10 },
      { header: "Week 3", key: "week3", width: 10 },
      { header: "Week 4", key: "week4", width: 10 },
    ]
    await data.internship.map((data, index) => {
      worksheet3.addRow({
        name: data?.userId?.name,
        email: data?.userId?.email,
        phone: data?.userId?.phone,
        category: data?.categoryId?.code,
        company: data?.companyId.name,
        week1: data.progress?.internship?.week1?.url,
        week2: data.progress?.internship?.week2?.url,
        week3: data.progress?.internship?.week3?.url,
        week4: data.progress?.internship?.week4?.url,
      })
    })
    workbook.xlsx.writeBuffer().then(function (buffer) {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        // type :"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      FileSaver.saveAs(blob, "SipReport.xlsx")
    })
  }
  const [messageApi, contextHolder] = message.useMessage()
  const [form1] = Form.useForm()
  const [form2] = Form.useForm()
  const [form3] = Form.useForm()
  const [form] = Form.useForm()

  const onFinishCreateProgram = async (values) => {
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/user/new/program`
      if (!values.programs || !values.programs.length) {
        return messageApi.open({
          type: "error",
          content: "Add atleast one program",
        })
      }
      const res = await axios.put(url, values, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      messageApi.open({
        type: "success",
        content: "Program Created Successfully",
      })
      // setLoading(false)
    } catch (error) {
      setLoading(false)
      messageApi.open({
        type: "error",
        content: error.response?.data.message || "Something went wrong",
      })
      if (error.response?.data.message === "No User Found") {
        form.resetFields()
        setOpen(true)
      }
      console.log(error.response?.data.message)
    }
  }

  const handleSave = async (values) => {
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/user/new/user`
      const res = await axios.put(url, values, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      messageApi.open({
        type: "success",
        content:
          "User Created Successfully. You can create a program for this user",
      })
      setOpen(false)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      messageApi.open({
        type: "error",
        content: error.response?.data.message || "Something went wrong",
      })

      console.log(error.response?.data.message)
    }
  }
  const handleDelete = async (values) => {
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/user/delete/userOrProgram`
      const res = await axios.put(url, values, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      messageApi.open({
        type: "success",
        content:
          values.program ? "Program Deleted Successfully" : "User Deleted Successfully",
      })
      setOpen(false)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      messageApi.open({
        type: "error",
        content: error.response?.data.message || "Something went wrong",
      })

      console.log(error.response?.data.message)
    }
  }
  const onFinishSearch = async (values) => {
    console.log(values)
    setLoading(true)
    console.log(values)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/sip/report`
      const res = await axios.put(url, {
        type: values.type,
        name: values.name,
      }, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      console.log(res.data.data)
      //initialize sheet
      exportExcel(res.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      messageApi.open({
        type: "error",
        content: error.response?.data.message || "Something went wrong",
      })

      console.log(error.response?.data.message)
    }
  }
  const onFinishUpdatePocArray = async (values) => {
    console.log(values)
    setLoading(true)
    console.log(values)
    try {
      const url = `${constants.DOMAIN}/api/v1/admin/updatepocarray`
      const res = await axios.post(url, {
        arrayType: values.arrayType,
        poc: values.poc,
        type: values.type,
      })
      messageApi.open({
        type: "success",
        content: "Array Updated Successfully",
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      messageApi.open({
        type: "error",
        content: error.response?.data.message || "Something went wrong",
      })

      console.log(error.response?.data.message)
    }
  }
  const fetchActiveSlot = async (cateCode = "SMM") => {
    try {
      // setStatus({ loading: true, error: false, success: false })
      const url = `${constants.NEW_DOMAIN}/api/v1/batch/find/activeSuffix?cateCode=${cateCode}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setSlots({ ...slots, [cateCode]: res.data.data })
      // setStatus({ loading: false, error: false, success: false })
    } catch (error) {
      console.log(error.response)
      // setStatus({
      //   loading: false,
      //   error: "Something went wrong",
      //   success: false,
      // })
    }
  }
  const fetchConvertedSchool = async () => {
    try {
      // setStatus({ loading: true, error: false, success: false })
      const url = `${constants.NEW_DOMAIN}/api/v1/school/converted`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setConvertedSchool(res.data.data)
      // setStatus({ loading: false, error: false, success: false })
    } catch (error) {
      console.log(error.response)
      // setStatus({
      //   loading: false,
      //   error: "Something went wrong",
      //   success: false,
      // })
    }
  }

  const handleExcelUpload = (file) => {
    setUploadedExcelFile(file); // Store the uploaded file
    message.success(`${file.name} file uploaded successfully.`);
    return false; // Prevent upload default behavior
  };

  const handleInputChange = (field, value) => {
    setFormValues(prev => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (values) => {
    const finalInput = {
      // templateCode: selectedTemplate?.templateCode,
      formValues,
      // testValues,
      uploadedExcelFile,
    };

    // console.log('values-', values)
    console.log('Final input for submission:', finalInput); // Log final input

    try {
      const formData = new FormData();
      // formData.append('template',  JSON.stringify(selectedTemplate));
      formData.append('data', JSON.stringify(formValues));
      if (uploadedExcelFile) {
        formData.append('excelFile', uploadedExcelFile); // Append the uploaded Excel file
      }
      // showModal()
      // Send data to the backend API
      const response = await axios.post(`${constants.NEW_DOMAIN}/api/v1/user/new/userProgramBulk`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
        },
      });
      // handleCancel()
      message.success('Data submitted successfully!');
      console.log('Response from server:', response.data);
    } catch (error) {
      handleCancel()
      console.error('Error submitting data:', error);
      message.error(error.response.data.message);
    }
  };

  const generateSampleExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sample Data');

    // Define columns based on the selected template
    const columns = [
      { header: 'name', key: 'name', width: 15 },
      { header: 'email', key: 'email', width: 15 },
      { header: 'phone', key: 'phone', width: 20 },
      { header: 'countryCode', key: 'countryCode', width: 15 },
      { header: 'type', key: 'type', width: 15 },
      { header: 'category', key: 'category', width: 15 },
      { header: 'slot', key: 'slot', width: 15 },
      { header: 'plan', key: 'plan', width: 15 },
      { header: 'poc', key: 'poc', width: 15 },
      { header: 'orderAmount', key: 'orderAmount', width: 15 },
      { header: 'businessDomain', key: 'businessDomain', width: 15 },
      // { header: 'schoolId', key: 'schoolId', width: 15 },
      // { header: 'session', key: 'session', width: 15 },
    ];

    // // Add variable columns
    // if (selectedTemplate) {
    //   selectedTemplate.variables.forEach(variable => {
    //     columns.push({ header: variable, key: variable, width: 25 });
    //   });

    //   // // Add header file URL and name fields if applicable
    //   // if (selectedTemplate.headerFileUrl) {
    //   //   columns.push({ header: 'headerFileUrl', key: 'headerFileUrl', width: 30 });
    //   //   columns.push({ header: 'headerFileName', key: 'headerFileName', width: 30 });
    //   // }

    //   // Add URL parameters if applicable
    //   if (selectedTemplate.urlParameters) {
    //     columns.push({ header: 'urlParameters', key: 'urlParameters', width: 25 });
    //   }
    // }

    worksheet.columns = columns;

    // Add a sample row (can be modified to fit requirements)
    const sampleRow = {
      name: "priyansh",
      email: "priyansh1@gmail.com",
      phone: "9999999999",
      countryCode: '91',
      type: "B2B",
      category: "SMM",
      slot: "08_05_2024",
      plan: "HYBRID",
      poc: "6630af1d249537fdc8229932",
      orderAmount: "1000",
      businessDomain: "NEW_ORDER",
      schoolId: "MOUNT_215",
      session: "2026",
      // ...selectedTemplate?.variables.reduce((acc, variable) => {
      //   acc[variable] = '';
      //   return acc;
      // }, {}),
    }

    worksheet.addRow(sampleRow);

    // Create a download link and trigger download
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Sample_${selectedTemplate?.templateName}.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    });
  };

  const fetchPOC = async () => {
    try {
      // setStatus({ loading: true, error: false, success: false })
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/systemuser`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      console.log(res)
      setPoc(res.data.doc)
      // setStatus({ loading: false, error: false, success: false })
    } catch (error) {
      console.log(error.response)
      // setStatus({
      //   loading: false,
      //   error: "Something went wrong",
      //   success: false,
      // })
    }
  }

  useEffect(() => {
    fetchConvertedSchool()
  }, [])

  useEffect(() => {
    socket.on("progressBulkUpload", (raw) => {
      //updated Percent
      console.log(raw);
      
      setPercent(raw)
    })
  }, [socket])

  return (
    <>
      {contextHolder}
      <Modal
        title={view === 'CREATE' ? "Create User" : "Delete User"}
        open={open}
        // onOk={handleOk}
        // confirmLoading={confirmLoading}
        footer={""}
        style={{ height: "400px" }}
        scroll={true}
        onCancel={handleCancel}
      >
        <Card title="Enter Details">
          {view === 'CREATE' && <Form
            layout="horizontal"
            form={form}
            size="medium"
            onFinish={handleSave}
            // autoComplete="true"
            style={{
              maxWidth: 600,
              marginTop: "40px",
            }}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 14,
            }}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "name is required",
                },
              ]}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
            <Form.Item
              label="✉️Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
              ]}
            >
              <Input placeholder="paa@gmail.com" />
            </Form.Item>
            <Form.Item
              label="☎️Phone"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Phone is required",
                },
              ]}
            >
              <Input placeholder="1234567899" />
            </Form.Item>
            {/* <Form.Item
              name="category"
              label="🌐Program"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                style={{ width: 200 }}
                placeholder="Choose Category"
                onChange={(e) => fetchActiveSlot(e)}
              >
                <Option value={"SMM"}>SMM</Option>
                <Option value={"GD"}>GD</Option>
                <Option value={"CW"}>CW</Option>
                <Option value={"VE"}>VE</Option>
                <Option value={"FD"}>FD</Option>
                <Option value={"ID"}>ID</Option>
                <Option value={"PSY"}>PSY</Option>
                <Option value={"WD"}>WD</Option>
                <Option value={"FM"}>FM</Option>
              </Select>
            </Form.Item> */}

            {/* <Form.Item
              name="slot"
              label="🎰Slot"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select style={{ width: 200 }} placeholder="Choose Slot">
                {slots?.map((data) => {
                  return <Option value={data.code}>{data.displayText}</Option>
                })}
              </Select>
            </Form.Item> */}

            <Form.Item
              name="type"
              label="User Type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                style={{ maxWidth: "200px" }}
                onChange={(e) => {
                  if (e === "CLUB_MEMBER") {
                    fetchConvertedSchool()
                    setField(true)
                  } else setField(false)
                }}
              >
                <Option value="CLUB_MEMBER">B2B</Option>
                <Option value="SIP">B2C</Option>
              </Select>
            </Form.Item>
            {field ? (
              <Form.Item
                name="schoolId"
                label="School"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select style={{ maxWidth: "200px" }}>
                  {convertedSchool?.map((data) => {
                    return (
                      <Option value={data._id}>
                        {data.name + ", " + data.city}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            ) : (
              ""
            )}
            {field ? (
              <Form.Item
                name="session"
                label="Session"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select style={{ maxWidth: "200px" }}>
                  <Option value="FY23">2023</Option>
                  <Option value="FY22">2022</Option>
                </Select>
              </Form.Item>
            ) : (
              ""
            )}
            <Form.Item
              wrapperCol={
                width > 800
                  ? {
                    offset: 8,
                  }
                  : {}
              }
            >
              <Button
                type="primary"
                style={{ width: "200px" }}
                htmlType="submit"
              >
                📂 Create
              </Button>
            </Form.Item>
          </Form>}
          {view === 'DELETE' && <Form
            layout="horizontal"
            form={form3}
            size="medium"
            onFinish={handleDelete}
            // autoComplete="true"
            style={{
              maxWidth: 600,
              marginTop: "40px",
            }}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 14,
            }}
          >

            <Form.Item
              label="☎️Phone"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Phone is required",
                },
              ]}
            >
              <Input placeholder="1234567899" />
            </Form.Item>
            <Form.Item
              name="program"
              label="🌐Program"
              rules={[
                {
                  // required: true,
                },
              ]}
            >
              <Select
                style={{ width: 200 }}
                placeholder="Choose Category"
                onChange={(e) => fetchActiveSlot(e)}
              >
                <Option value={"SMM"}>SMM</Option>
                <Option value={"GD"}>GD</Option>
                <Option value={"CW"}>CW</Option>
                <Option value={"VE"}>VE</Option>
                <Option value={"FD"}>FD</Option>
                <Option value={"ID"}>ID</Option>
                <Option value={"PSY"}>PSY</Option>
                <Option value={"WD"}>WD</Option>
                <Option value={"FM"}>FM</Option>
                <Option value={"DM"}>DM</Option>
                <Option value={"GDPRO"}>GDPRO</Option>
                <Option value={"PSYPRO"}>PSYPRO</Option>
                <Option value={"CWPRO"}>CWPRO</Option>
              </Select>
            </Form.Item>
            <p>NOTE : If you will provide both Details only program will be deleted.</p>
            <Form.Item
              wrapperCol={
                width > 800
                  ? {
                    offset: 8,
                  }
                  : {}
              }
            >
              <Popconfirm
                title="Are you Sure you want to Delete Student ?"
                description="This action will be irreversible..."
                placement="top"
                onConfirm={() => {
                  form3.submit()
                }}
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: 'red',
                    }}
                  />

                }
              >
                <Button
                  type="primary"
                  style={{ width: "200px" }}
                  htmlType="button"
                >
                  🚚 Delete
                </Button>
              </Popconfirm>
            </Form.Item>
          </Form>}
        </Card>
      </Modal>

     {view === 'BULK_USERS' && <Modal
        title={view === "BULK_USERS"}
        open={open}
        // onOk={handleOk}
        // confirmLoading={confirmLoading}
        footer={""}
        style={{ height: "400px" }}
        scroll={true}
        onCancel={handleCancel}
      >
        <Card title="Bulk Upload Users">
          {view === 'BULK_USERS' && <Form
            layout="horizontal"
            form={form}
            size="medium"
            // onFinish={handleSave}
            onFinish={handleSubmit}
            // autoComplete="true"
            style={{
              maxWidth: 600,
              marginTop: "40px",
            }}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 14,
            }}
          >
            <Form.Item
              name="schoolId"
              label="School"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select onChange={(e) => {
                console.log('school-', e)
                handleInputChange("schoolId", e)
              }} style={{ maxWidth: "200px" }}>
                {convertedSchool?.map((data) => {
                  return (
                    <Option value={data._id}>
                      {data.name + ", " + data.city}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="session"
              label="Session"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select onChange={(e) => {
                console.log('session-', e)
                handleInputChange("session", e)
              }} style={{ maxWidth: "200px" }}>
                <Option value="FY23">2023</Option>
                <Option value="FY22">2022</Option>
              </Select>
            </Form.Item>

            <Row style={{ justifyContent: "center" }}>
              <Form.Item label="Upload Excel File">
                <Upload
                  beforeUpload={handleExcelUpload}
                  showUploadList={true}
                >
                  <Button icon={<UploadOutlined />}>Upload Excel</Button>
                </Upload>
              </Form.Item>
            </Row>

            <Row style={{ justifyContent: "center" }}>
              <Col>
                <Button
                  type="default"
                  style={{ marginLeft: '10px' }}
                  onClick={generateSampleExcel}
                >
                  Download Sample Excel
                </Button>
              </Col>

              <Col>
                <Button
                  type="primary"
                  style={{ marginLeft: '10px' }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Col>
            </Row>
            <Row>
            <Progress percent={ Math.round(percent.percentage)} />
            </Row>
          </Form>}
        </Card>
      </Modal>}

      <div style={{ backgroundColor: "#fff", padding: "20px" }}>
        <Row>
          <Col xs={24} md={20}>
            <div className="text-xl">Export SIP User Data</div>
            {/* <p className="text-lg-light">
              {new Date().toLocaleDateString()} -{" "}
              {new Date().toLocaleTimeString()}
            </p> */}
          </Col>
        </Row>
        <Divider></Divider>
        <div>
          <Form
            labelCol={{
              span: 5,
            }}
            wrapperCol={{
              span: 16,
            }}
            form={form1}
            name="control-hooks"
            // layout="inline"
            onFinish={onFinishSearch}
            style={{
              display: isMobile ? "" : "flex",
            }}
          >
            <Form.Item name="type" label="Type">
              <Select
                style={{ width: 200 }}
                placeholder="Choose Report type"
              //  label="Slot"
              //  onChange={(e)=>fetchData(e)}
              >
                <Option value={"school"}>School</Option>
                <Option value={"city"}>City</Option>
              </Select>
            </Form.Item>

            <Tooltip title="Please Enter the keyword related to school and city">
              <Form.Item
                style={{
                  width: isMobile ? "200px" : "300px",
                  marginLeft: isMobile ? "" : "30px",
                }}
                name="name"
                label="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder={"Enter City or School"} />
              </Form.Item>
            </Tooltip>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Export
              </Button>
            </Form.Item>
          </Form>
        </div>
        <Divider></Divider>
        <Row>
          <Col xs={24} md={20}>
            <div className="text-xl">Create User & Program</div>
            <p className="text-lg-light">
              First create user if this is the first purchase{" : "}
              <Button
                type="dashed"
                onClick={() => {
                  form.resetFields()
                  setView('CREATE')
                  setOpen(true)
                }}
              >
                Create User
              </Button>
            </p>
          </Col>
          <Col xs={24} md={20}>
            <div className="text-xl">Delete User & Program</div>
            <p className="text-lg-light">
              Delete User{" : "}
              <Button
                type="dashed"
                onClick={() => {
                  form.resetFields()
                  setView('DELETE')
                  setOpen(true)
                }}
              >
                Delete User
              </Button>
            </p>
          </Col>
        </Row>
        <Divider orientation="left">OR</Divider>
        <div>
          <Form
            // {...layout}
            form={form2}
            layout="inline"
            onFinish={onFinishCreateProgram}
            name="dynamic_form_nest_item"
            labelCol={{
              span: 8,
            }}
            labelAlign="left"
            labelWrap
          // wrapperCol={{
          //   span: 12,
          // }}
          >
            <Row gutter={[16, 16]}>
              <Col>
                <Form.List name="programs">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "category"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing Category",
                              },
                            ]}
                          >
                            <Select
                              style={{ width: 150 }}
                              placeholder="Choose Category"
                              onChange={(e) => fetchActiveSlot(e)}
                            >
                              <Option value={"SMM"}>SMM</Option>
                              <Option value={"GD"}>GD</Option>
                              <Option value={"CW"}>CW</Option>
                              <Option value={"VE"}>VE</Option>
                              <Option value={"FD"}>FD</Option>
                              <Option value={"ID"}>ID</Option>
                              <Option value={"PSY"}>PSY</Option>
                              <Option value={"WD"}>WD</Option>
                              <Option value={"FM"}>FM</Option>
                              {isAdmin && <Option value={"DM"}>DM</Option>}
                              {isAdmin && <Option value={"GDPRO"}>GDPRO</Option>}
                              {isAdmin && <Option value={"PSYPRO"}>PSYPRO</Option>}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "batchId"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing batch slot",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Choose Slot"
                            // style={{ width: 200 }}
                            >
                              {slots?.[
                                form2.getFieldValue("programs")?.[name]
                                  ?.category
                              ]?.map((data) => {
                                return (
                                  <Option value={data._id}>
                                    {data.displayText}
                                  </Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "plan"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing Plan",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Choose Plan"
                            // style={{ width: 200 }}
                            >

                              <Option value="RECORDED">Basic</Option>
                              <Option value="HYBRID">Premium</Option>
                              <Option value="OFFLINE">Offline ( Master Union Only)</Option>

                            </Select>
                          </Form.Item>

                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                          style={{
                            margin: "15px 0",
                            borderColor: "#971eff",
                            color: "#971eff",
                          }}
                        >
                          Add Program
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={24} md={7}>
                <Tooltip title="Please Enter the email or phone for which you want to update/create program">
                  <Form.Item
                    name="name"
                    label="Phone / Email"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder={"Enter Phone or Email"} />
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item
                  name="poc"
                  label="POC"
                  rules={[
                    {
                      // required: true,
                    },
                  ]}
                >
                  <Select
                    style={{ width: 150 }}
                    placeholder="POC"
                    onFocus={!poc.length && fetchPOC}
                    notFoundContent={<a>loading...</a>}
                  >
                    {poc?.map((data) => {
                      return <Option value={data._id}>{data.name}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Form.Item
                  name="amount"
                  label="Order Total (&#8377;)"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      validator: (rule, value) => {
                        // Custom validation logic for the number input
                        if (value && isNaN(value)) {
                          return Promise.reject("Please enter a valid number")
                        }
                        return Promise.resolve()
                      },
                    },
                  ]}
                >
                  <Input placeholder={"Total"} />
                </Form.Item>
              </Col>
              <Col xs={24} md={5}>
                <Form.Item
                  name="businessDomain"
                  label="Business Domain"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select style={{ width: 150 }} placeholder="user type">
                    <Option value={"NEW_ORDER"}>NEW_ORDER</Option>
                    <Option value={"CROSS_SELL"}>CROSS_SELL</Option>
                    <Option value={"UP_SALE"}>UP_SALE</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={5}>
                <Form.Item
                  name="orderType"
                  label="User type"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select style={{ width: 150 }} placeholder="user type">
                    <Option value={"B2C"}>B2C</Option>
                    <Option value={"B2B"}>B2B</Option>
                    <Option value={"MASTERUNION"}>MSU</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Create Program
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <Divider orientation="left"></Divider>

        {/* bulk upload users */}
        <Row>
          <Col xs={24} md={20}>
            <div className="text-xl">Bulk Upload Users</div>
            <p className="text-lg-light">
              Creates user and their enrollment with order{" : "}

              <Button
                type="dashed"
                onClick={() => {
                  form.resetFields()
                  setView('BULK_USERS')
                  setOpen(true)
                }}
              >
                Bulk Create Users
              </Button>
            </p>
          </Col>
        </Row>



        {/* <Row>
          <Form.Item
            name="schoolId"
            label="School"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select style={{ width: "200px" }}>
              {convertedSchool?.map((data) => {
                return (
                  <Option value={data._id}>
                    {data.name + ", " + data.city}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="session"
            label="Session"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select style={{ width: "100px" }}>
              <Option value="FY23">2023</Option>
              <Option value="FY22">2022</Option>
            </Select>
          </Form.Item>
        </Row> */}

        {/* <Row>
          <Col>
            <Button
              type="default"
              style={{ marginLeft: '10px' }}
              onClick={generateSampleExcel}
            >
              Download Sample Excel
            </Button>
          </Col>

          <Col>
            <Button
              type="primary"
              style={{ marginLeft: '10px' }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Col>
        </Row> */}
      </div>

    </>
  )
}

export default Export

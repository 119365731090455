import axios from "axios"
import { useEffect, useState, useRef } from "react"
import {
  SearchOutlined,
  EditOutlined,
  WhatsAppOutlined,
  CheckOutlined,
  CloseCircleTwoTone,
  ClockCircleTwoTone,
  ReloadOutlined,
  ScissorOutlined,
  BankOutlined,
  RocketTwoTone,
  StarFilled,
  HistoryOutlined,
  NodeIndexOutlined,
  SmileOutlined,
  StarTwoTone,
} from "@ant-design/icons"
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Statistic,
  Table,
  Tooltip,
  Modal,
  message,
  Empty,
  Timeline,
  Checkbox,
  List,
  Typography,
  notification,
  Tag
} from "antd"

import dayjs from "dayjs"
import TextArea from "antd/es/input/TextArea"
import constants from "../../constants"
import { Form } from "antd"
import { useAuth } from "../../contexts/authContext"
import useView from "../../hooks/useView"
import FamLogo from "../../images/fam-logo.jpg"
import { socket } from "../../util/socket"
import { logDOM } from "@testing-library/react"
import notify from "../../Sounds/sound.mp3"
import { Howl } from "howler"

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 16,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 5,
    span: 16,
  },
}
const LeadCRMForeign = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const { Text } = Typography
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      position: ["topCenter"],
    },
    filters: {},
  })
  const [edit, setEdit] = useState({
    name: "test",
    orderId: "test",
  })
  const { currentUser, roles } = useAuth()
  const isPOCViewable = roles.includes("SALES_ADMIN")|| roles.includes("SUPPORT") || roles.includes("OPERATIONS")
  const isEditable = roles.includes("ADMIN") || roles.includes("SALES_B2C")
  const isAdmin = roles.includes("ADMIN")
  const [messageApi, contextHolder] = message.useMessage()
  const [NotifyApi, contextNofify] = notification.useNotification()
  const [system, setSystem] = useState([])
  const searchInput = useRef(null)
  const { isMobile } = useView()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalRefferalOpen, setIsModalRefferalOpen] = useState(false)
  const [refferal, setRefferal] = useState(undefined)
  const [showHistory, setShowHistory] = useState(undefined)
  const [form] = Form.useForm()
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const showRefferalModal = async (record) => {
    // console.log(record);
    setLoading(true)
    try {
      const url = `${constants.DOMAIN}/api/v1/admin/refferal`
      const res = await axios.put(url, {
        email: record.email,
      })
      // console.log(res.data.data.referalcode.code)
      setRefferal(res.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
    setIsModalRefferalOpen(true)
  }
  const handleRefferalOk = () => {
    setIsModalRefferalOpen(false)
  }
  const handlerefferalCancel = () => {
    setIsModalRefferalOpen(false)
  }
  const onReset = () => {
    form.resetFields()
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={
            dataIndex === "nextFollowUp" ? "YYYY-MM-DD" : `Search ${dataIndex}`
          }
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
  })
  const systemUser = async () => {
    setLoading(true)
    try {
      const url = `${constants.DOMAIN}/api/v1/admin/systemuser`
      const res = await axios.get(url)
      setSystem(res.data.doc)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  const handleSubmit = async (record) => {
    console.log(record)
    //save the user comment
    setLoading(true)
    try {
      record.new = false
      const url = `${constants.DOMAIN}/api/v1/admin/foreignMarketingData`
      const res = await axios.put(url, {
        user: record,
        email: currentUser.email,
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  const onFinish = async (values) => {
    console.log(values)
    setLoading(true)
    try {
      const url = `${constants.DOMAIN}/api/v1/users/message/sendWPAfterCall`
      const res = await axios.post(url, {
        name: edit.name,
        phone: values.Phone,
        orderId: edit.orderId,
        preBook: values.preBook,
        offerId:
          values.Offer == undefined || values.Offer == ""
            ? undefined
            : values.Offer,
        // offerId: NO ? undefined : offerId,
      })
      // alert("Message Sent Successfully")
      messageApi.open({
        type: "success",
        content:
          values.Offer == undefined || values.Offer == ""
            ? "Message sent successfully"
            : "Message sent successfully with offer",
      })
      handleOk()
      setLoading(false)
    } catch (error) {
      console.log(error)
      // alert("Error", error.message)

      messageApi.open({
        type: "error",
        content: error.message || "Something went wrong",
      })
      handleOk()
      setLoading(false)
    }
  }

  const pocPrint = (poc) => {
    if (poc === "649bcba1e6da06f4fae8653e") {
      return "Anshul"
    }
    if (poc === "649bcba1e6da06f4fae8653f") {
      return "Akshay"
    }
    if (poc === "649bcba1e6da06f4fae86540") {
      return "Shyam"
    }
    if (poc === "64a3b733a8a16de2e4894833") {
      return "Kuldeep"
    }
    if (poc === "64a11376a8a16de2e4894825") {
      return "Deepika"
    }
    if (poc === "651e5ee97e6d414554fa3f2e") {
      return "Naama"
    }
    if (poc === "652833fe81efc0b4ddaa3f50") {
      return "Archi"
    }
    if (poc === "6528342581efc0b4ddaa3f51") {
      return "Divyank"
    }
    if (poc === "6528345881efc0b4ddaa3f52") {
      return "Chanchal"
    }
    if (poc === "6528348381efc0b4ddaa3f53") {
      return "Sakshi"
    }
    if (poc === "652834ad81efc0b4ddaa3f54") {
      return "Richard"
    }
    if (poc === "652834de81efc0b4ddaa3f55") {
      return "Himani"
    }
    if (poc === "652997a5e13119fcf267b9fa") {
      return "Kashyap"
    }
    if (poc === "65299825e13119fcf267b9fb") {
      return "Nehal"
    }
    if (poc === "65299825e13119fcf267b9fc") {
      return "Yashashree"
    }
    if (poc === "65299825e13119fcf267b9fd") {
      return "Prathmesh"
    }
    if (poc === "65323853e8bdc11d099c8baa") {
      return "Dipali Yadav"
    }
    if (poc === "65323853e8bdc11d099c8bab") {
      return "Swati Malakar"
    }
    if (poc === "65323853e8bdc11d099c8bac") {
      return "Chhavani Karma"
    }
    if (poc === "65323853e8bdc11d099c8bad") {
      return "Vedansh Naik"
    }
    if (poc === "65323853e8bdc11d099c8bae") {
      return "Aakash Yadav"
    }
    if (poc === "65323853e8bdc11d099c8baf") {
      return "Shailendra Kewat"
    }
    if (poc === "65323853e8bdc11d099c8bb0") {
      return "Srashti Sahu"
    }
    if (poc === "65323853e8bdc11d099c8bb1") {
      return "Chanchal thakur"
    }
    if (poc === "65323853e8bdc11d099c8bb2") {
      return "Ramesh Kag"
    }
    if (poc === "65323853e8bdc11d099c8bb3") {
      return "Arshdeep Singh"
    }
    if (poc === "65323853e8bdc11d099c8bb4") {
      return "Ashwini"
    }
    if (poc === "65323853e8bdc11d099c8bb5") {
      return "Suresh Patidar"
    }
    if (poc === "65323853e8bdc11d099c8bb6") {
      return "Shyam Patel"
    }
    if (poc === "65323853e8bdc11d099c8bb7") {
      return "Gaurav Soni"
    }
    if (poc === "65337a4143d2714c06c1c184") {
      return "Richa Nema"
    }
    if (poc === "65571487c57d2975cd00176a") {
      return "Yashu Upadhayay"
    }
    return "N/A"
  }

  const sendMessage = async (record) => {
    // const offerId = prompt(`Want to apply offer Link`)
    // const phone = prompt(`Enter custom number`)
    // let PH = false
    // // console.log(PH);
    // const NO = offerId === "n" || offerId === "N"
    // if(phone=="n"||phone=="N")
    // {
    //   PH=false
    // }
    // else{
    //   PH=true
    // }
    // setLoading(true)
    // try {
    //   const url = `${constants.DOMAIN}/api/v1/users/message/sendWPAfterCall`
    //   const res = await axios.post(url, {
    //     name: record.name,
    //     phone: PH ?phone:record.phone,
    //     orderId: record.razorpayOrderId,
    //     offerId: NO ? undefined : offerId,
    //   })
    //   alert("Message Sent Successfully")
    //   setLoading(false)
    // } catch (error) {
    //   console.log(error)
    //   alert("Error", error.message)
    //   setLoading(false)
    // }
    onReset()
    setEdit({
      name: record?.name,
      orderId: record?.razorpayOrderId,
    })
    form.setFieldValue("Phone", record.countryCode + record.phone)
    showModal()
  }

  const renderStatus = (_, record) => {
    if (record.leadType === "DIRECT") {
      return <RocketTwoTone twoToneColor="green" style={{ fontSize: "25px" }} />
    }
    if (record.leadType === "CONVERTED")
      return (
        <CheckOutlined
          twoToneColor="lightgreen"
          style={{ color: "lightGreen", fontSize: "25px" }}
        />
      )
    if (record.profile) {
      return <BankOutlined />
    }

    if (record.userInterested === "NO") {
      return (
        <CloseCircleTwoTone twoToneColor="red" style={{ fontSize: "20px" }} />
      )
    }

    if (record.new) {
      if (record.oldUser) {
        return (
          <>
            <Badge color="blue" />
            <Tooltip title="Repeating user :)">
              <StarFilled style={{ color: "green" }} />
            </Tooltip>
          </>
        )
      } else {
        return <Badge color="blue" />
      }
    }

    if (
      record.nextFollowUp &&
      new Date(record.nextFollowUp).toLocaleDateString() ===
        new Date().toLocaleDateString()
    )
      return (
        <ClockCircleTwoTone
          twoToneColor="orange"
          style={{ fontSize: "25px" }}
        />
      )
  }

  const columns = [
    {
      title: "",
      width: 50,
      render: (_, record) => {
        return <Space>{renderStatus(_, record)}</Space>
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      //   fixed: "left",
      sorter: false,
      render: (_, record) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Text
              copyable={{
                text: `${record.name}`,
              }}
            >
              {" "}
              {record.name}{" "}
            </Text>
            {/* {record.source === "FAMPAY" && <img src={FamLogo} width={"20px"} />} */}
          </div>
        )
      },
      ...getColumnSearchProps("name"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      sorter: false,
      //   fixed: "left",
      render: (phone, record) => (
        <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
          {`(${record.countryCode})`} {phone} {record.phoneVerified && <img width="25" height="25" src="https://img.icons8.com/color/48/verified-account--v1.png" alt="verified-account--v1"/>}
        </div>
      ),
      ...getColumnSearchProps("phone"),
    },
    // {
    //   title: "Category",
    //   dataIndex: "categoryCode",
    //   sorter: false,
    //   width: 95,
    //   render: (data,record) => (
    //     <>
    //       {record.programType === "PROCOURSE" ?
    //       <>
    //        <Tag color={"volcano"}>{data}</Tag>
    //       </> : <>
    //       {data}
    //       </>}
    //     </>
    //   ),
    //   ...getColumnSearchProps("categoryCode"),
    // },
    isMobile
      ? {
          width: 0,
        }
      : {
          title: "DOR (MM/DD/YY)",
          dataIndex: "createdAt",
          sorter: false,
          render: (date) => (
            <>
              {new Date(date).toLocaleDateString()}
              <br />
              <div style={{ color: "lightgray" }}>
                {new Date(date).toLocaleTimeString()}
              </div>
            </>
          ),
        },
       {
          title: "Slot",
          dataIndex: "slot",
          sorter: false,
          render: (date) => (
            <>
           {date ? <>
              {new Date(date).toLocaleDateString()}
              <br />
              <div style={{ color: "lightgray" }}>
                {new Date(date).toLocaleTimeString()}
              </div>
            </>:<> <Tag color="red">Not Selected</Tag></>}
            </>
          ),
        },
        {
          title: "City",
          dataIndex: "city",
          sorter: false,
          render: (city) => (
            <>
           {city ? <>
              {city}
            </>:<> <Tag color="purple">Not Given</Tag></>}
            </>
          ),
        },
    // {
    //   title: "Call Connected",
    //   dataIndex: "callConnected",
    //   filters: [
    //     {
    //       text: "Yes",
    //       value: "YES",
    //     },
    //     {
    //       text: "No",
    //       value: "NO",
    //     },
    //   ],
    //   render: (callConnected, record) => {
    //     return (
    //       <Select
    //         size="small"
    //         defaultValue={callConnected || ""}
    //         style={{
    //           width: 60,
    //         }}
    //         onChange={(e) => {
    //           record.callConnected = e
    //           console.log(record)
    //         }}
    //         options={[
    //           {
    //             value: "YES",
    //             label: "Yes",
    //           },
    //           {
    //             value: "NO",
    //             label: "No",
    //           },
    //         ]}
    //       />
    //     )
    //   },
    // },
    // {
    //   title: "Lead Type",
    //   dataIndex: "leadType",
    //   filters: [
    //     {
    //       text: "HOT",
    //       value: "HOT",
    //     },
    //     {
    //       text: "COLD",
    //       value: "COLD",
    //     },
    //     {
    //       text: "CONVERTED",
    //       value: "CONVERTED",
    //     },
    //     {
    //       text: "DIRECT",
    //       value: "DIRECT",
    //     },
    //   ],
    //   render: (leadType, record) => {
    //     return (
    //       <Select
    //         size="small"
    //         defaultValue={leadType || ""}
    //         style={{
    //           width: 100,
    //         }}
    //         onChange={(e) => {
    //           record.leadType = e
    //         }}
    //         options={[
    //           {
    //             value: "HOT",
    //             label: "Hot",
    //           },
    //           {
    //             value: "COLD",
    //             label: "Cold",
    //           },
    //           {
    //             value: "CONVERTED",
    //             label: "Converted",
    //           },
    //           {
    //             value: "DIRECT",
    //             label: "DIRECT",
    //           },
    //         ]}
    //       />
    //     )
    //   },
    // },

    // isMobile
    //   ? {
    //       width: 0,
    //     }
    //   : {
    //       title: "Next Follow up",
    //       dataIndex: "nextFollowUp",
    //       sorter: false,

    //       render: (nextFollowUp, record) => (
    //         <DatePicker
    //           //   showTime
    //           size="small"
    //           onChange={(v) => {
    //             if (!v) return

    //             //set in this way to make Date filter work
    //             record.nextFollowUp = new Date(
    //               `${v.year()}-${v.month() + 1}-${v.date()}`
    //             )
    //           }}
    //           //   onOk={(v) => {
    //           //     console.log(v.toISOString())
    //           //   }}
    //           defaultValue={nextFollowUp ? dayjs(nextFollowUp) : ""}
    //         />
    //       ),
    //       ...getColumnSearchProps("nextFollowUp"),
    //     },
    {
      title: "Comments",
      dataIndex: "comments",
      render: (comments, record) => {
        return (
          <TextArea
            rows={3}
            // value={comments}
            defaultValue={comments}
            onChange={(e) => {
              console.log(e.target.value)
              record.comments = e.target.value
            }}
          />
        )
      },
    },
    // {
    //   title: "POC",
    //   dataIndex: "POC",
    //   filters: [
    //     {
    //       text: "Anshul",
    //       value: "Anshul",
    //     },
    //     {
    //       text: "Akshay",
    //       value: "Akshay",
    //     },
    //     {
    //       text: "Shyam",
    //       value: "Shyam",
    //     },
    //     {
    //       text: "Parv",
    //       value: "Parv",
    //     },
    //     {
    //       text: "YouVah",
    //       value: "YouVah",
    //     },
    //   ],
    //   render: (POC, record) => {
    //     return (
    //       <Select
    //         size="small"
    //         defaultValue={POC || ""}
    //         style={{
    //           width: 80,
    //         }}
    //         onChange={(e) => {
    //           record.POC = e
    //         }}
    //         options={[
    //           {
    //             text: "Anshul",
    //             value: "Anshul",
    //           },
    //           {
    //             text: "Akshay",
    //             value: "Akshay",
    //           },
    //           {
    //             text: "Shyam",
    //             value: "Shyam",
    //           },
    //           {
    //             text: "YouVah",
    //             value: "YouVah",
    //           },
    //         ]}
    //       />
    //     )
    //   },
    // },
    // {
    //   title: "Interested?",
    //   dataIndex: "userInterested",
    //   filters: [
    //     {
    //       text: "Yes",
    //       value: "YES",
    //     },
    //     {
    //       text: "No",
    //       value: "NO",
    //     },
    //     {
    //       text: "Ask from Parent",
    //       value: "ASK FROM PARENT",
    //     },
    //   ],
    //   render: (userInterested, record) => {
    //     return (
    //       <Select
    //         size="small"
    //         defaultValue={userInterested || ""}
    //         style={{ width: 100 }}
    //         onChange={(e) => {
    //           record.userInterested = e
    //         }}
    //         options={[
    //           {
    //             label: "Yes",
    //             value: "YES",
    //           },
    //           {
    //             label: "No",
    //             value: "NO",
    //           },
    //           {
    //             label: "Ask from Parent",
    //             value: "ASK FROM PARENT",
    //           },
    //         ]}
    //       />
    //     )
    //   },
    // },
    // isPOCViewable
    //   ? {
    //       title: "POC",
    //       dataIndex: "POC_V1",
    //       render: (data, record) => {
    //         return <>{constants.idToPOC[data]}</>
    //       },
    //       width: 150,
    //     }
    //   : {
    //       width: 0,
    //     },
    // isAdmin
    //   ? {
    //       title: "POC",
    //       dataIndex: "POC_V1",
    //       render: (data, record) => {
    //         return (
    //           <Select
    //             size="small"
    //             defaultValue={constants.idToPOC[data] || ""}
    //             style={{ width: 100 }}
    //             onChange={(e) => {
    //               record.POC_V1 = e
    //               console.log(record)
    //             }}
    //             options={(system || []).map((d) => ({
    //               value: d._id,
    //               label: d.name,
    //             }))}
    //           />
    //         )
    //       },
    //       width: 150,
    //     }
    //   : {
    //       width: 0,
    //     },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
      width: 150,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 80,
      render: (_, record) => (
        <>
          <Space direction="vertical" size={"small"}>
            
              <Tooltip title="save">
                <Button
                  type="link"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => handleSubmit(record)}
                />
              </Tooltip>
            
            {/* <Tooltip title="Send WhatsApp">
              <Button
                type="text"
                shape="circle"
                onClick={() => sendMessage(record)}
                icon={<WhatsAppOutlined style={{ color: "#45C153" }} />}
              />
            </Tooltip>
            <Tooltip title="Referral">
              <Button
                type="text"
                shape="circle"
                onClick={() => showRefferalModal(record)}
                icon={<NodeIndexOutlined style={{ color: "#971eff" }} />}
              />
            </Tooltip>
            <Tooltip title="Show History">
              <Button
                type="text"
                shape="circle"
                onClick={() => setShowHistory(record.history || [])}
                icon={<HistoryOutlined style={{ color: "red" }} />}
              />
            </Tooltip> */}
          </Space>
        </>
      ),
    },
  ]

  const renderHeader = () => {
    return (
      <Space size={16}>
        <Button
          type="default"
          icon={<ScissorOutlined />}
          onClick={() => setTableParams({ ...tableParams, filters: {} })}
          loading={loading}
        >
          Clear Filters
        </Button>
        <Button
          type="default"
          icon={<ReloadOutlined />}
          onClick={() => fetchData()}
          loading={loading}
        >
          Reload
        </Button>
      </Space>
    )
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 }, //reset the page to 1
      filters: {
        [dataIndex]: selectedKeys,
      },
    })
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    fetchData()
  }

  const fetchData = async () => {
    setLoading(true)
    // console.log("tableParams : ", tableParams)
    try {
      let query = []
      Object.keys(tableParams.filters).forEach((k) => {
        if (
          tableParams.filters[k] !== null &&
          tableParams.filters[k].length > 0
        ) {
          if (k === "name") {
            query.push(`${k}=^${tableParams.filters[k]}`)
          } else {
            query.push(`${k}=${tableParams.filters[k]}`)
          }
        }
      })
      //   name={$regex:^fin,$options:'i'}&

      query =
        `page=${tableParams.pagination.current}&limit=${tableParams.pagination.pageSize}&firebaseId=${currentUser._delegate.uid}&` +
        query.join("&")
      const url = `${constants.DOMAIN}/api/v1/admin/foreign/marketingData?${query}`
      const res = await axios.get(url)
      setData(res.data.data.users)
      setLoading(false)
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.data.results,
        },
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  // console.log(data)
 

  useEffect(() => {
    fetchData()
    systemUser()
  }, [JSON.stringify(tableParams)])

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    })

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([])
    }
  }
  return (
    <>
      {contextHolder}
      {contextNofify}

      <div style={{ backgroundColor: "#fff", padding: "20px" }}>
        {data && console.log("data set")}
        <Modal
          title="Whatapp Message and Offer Tab"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[]}
        >
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            style={{
              maxWidth: 600,
            }}
          >
            <Form.Item
              name="Phone"
              label="Phone"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="Offer"
              label="Offer"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input
              // placeholder={edit.batchId}
              />
            </Form.Item>
            <Form.Item
              name="preBook"
              valuePropName="checked"
              wrapperCol={{
                offset: 5,
              }}
            >
              <Checkbox>It's a pre-book Link</Checkbox>
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Send
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* show history modal */}
        <Modal
          title="User Registration Histroy"
          open={showHistory}
          onCancel={() => setShowHistory(undefined)}
          footer={[]}
        >
          {showHistory && showHistory.length > 0 ? (
            <Timeline
              mode="alternate"
              items={showHistory?.map((history) => {
                return {
                  children: `Regsitered on ${new Date(
                    history.createdAt
                  ).toLocaleDateString()} for ${history.categoryCode}`,
                }
              })}
            />
          ) : (
            <Empty description="No history found" />
          )}
        </Modal>

        {/* refferal modal */}
        <Modal
          title="Refferal"
          open={isModalRefferalOpen}
          onOk={handleRefferalOk}
          onCancel={handlerefferalCancel}
          footer={[]}
        >
          {refferal ? (
            <>
              Name : {refferal.referalcode?.owner.name}
              <br></br>
              Code :{" "}
              <Typography.Text copyable={refferal.referalcode?.code}>
                {refferal.referalcode?.code}
              </Typography.Text>
              <List
                header={<div>Reffered User</div>}
                // footer={<div>Footer</div>}
                // bordered
                dataSource={refferal.referalcode?.refferals}
                renderItem={(item) => (
                  <List.Item>
                    <Typography.Text>{item.user?.name}</Typography.Text>
                    <Typography.Text>{item.user?.phone}</Typography.Text>
                    <Typography.Text>{item.user?.email}</Typography.Text>
                  </List.Item>
                )}
              />
            </>
          ) : (
            <Empty description="No history found" />
          )}
        </Modal>

        <Row>
          <Col xs={24} md={20}>
            <div className="text-3xl">Leads CRM</div>
            <p className="text-lg-light">
              {new Date().toDateString()} - {new Date().toLocaleTimeString()}
            </p>
          </Col>
          <Col xs={24} md={4}>
            {/* <Statistic
              title="Total Registrations"
              loading={loading}
              value={tableParams.pagination?.total}
              precision={0}
              valueStyle={{
                color: "blue",
              }}
              // prefix={<ArrowUpOutlined />}
              suffix="leads"
            /> */}
          </Col>
        </Row>

        <Table
          theme="dark"
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={data}
          title={renderHeader}
          pagination={tableParams.pagination}
          loading={loading}
          rowClassName="row"
          onChange={handleTableChange}
          size="medium"
          scroll={{
            x: 1500,
            // y: 500,
          }}
        />
      </div>
    </>
  )
}
export default LeadCRMForeign

import React from 'react';
import { ViewportContext } from '../contexts/viewPort';


const useView = () => {
  const { width, height } = React.useContext(ViewportContext);
  const isMobile = 1000 > width;
  return { width, height,isMobile };
};

export default useView;
import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Switch,
  Typography,
  Tabs,
  Modal,
  Select
} from "antd"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import axios from "axios"
import constants from "../../constants"
import Loader from "../../util/loader"
import useView from "../../hooks/useView"

const { Text } = Typography
const { Option } = Select
const Company = () => {
  const [company, setCompany] = useState({
    "tasks": {
      "GD": [],
      "SMM": [],
      "VE": [],
      "CW": [],
      "PSY":[],
      "ID":[],
      "FD":[],
      "WD":[],
      "FM":[],
      "GDPRO":[],
      "DM":[],
    }
  })
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const {width} = useView();
  const fetchData = async () => {
    setLoading(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/company/?type=BETA&limit=30`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setData(res.data.data)
      // console.log(res.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

const CourseTab=({cate})=>{
  return <>
   <Form.List name={cate}>
                {(fields, { add, remove }) => (
                  <>
                    {
              
                    fields.map(({ key, name, ...restField }, i) => (
                      <div key={i}>
                        <h1 style={{ textAlign: "center" }}>
                          Task #{i + 1}
                          <span>
                            <MinusCircleOutlined
                              style={{ color: "red", marginLeft: "20px" }}
                              onClick={() => remove(name)}
                            />
                          </span>
                        </h1>
                        <Form.Item
                          {...restField}
                          name={[name, "id"]}
                          label="ID"
                          rules={[
                            {
                              required: true,
                              message: "id is required",
                            },
                          ]}
                        >
                          <Input type="number" placeholder="ex : 1,2,3" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "description"]}
                          label="description"
                          rules={[
                            {
                              required: true,
                              message: "Missing description",
                            },
                          ]}
                        >
                          <Input.TextArea placeholder="Small task description" />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "presentationLink"]}
                          label="task url"
                          rules={[
                            {
                              type: "url",
                              required:true,
                            //   warningOnly: true,
                            },
                          ]}
                        >
                          <Input placeholder="Enter downloadable task link" />
                        </Form.Item>

                      </div>
                    ))}
                    <Form.Item
                      wrapperCol={{
                        offset: 3,
                        span: 16,
                      }}
                    >
                      <Button
                        // type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add New Task
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
  </>
}
// const log=()=>{
//   console.log(company);
// }
const getCompany = async(id)=>{
  // console.log(id);
  setLoading(true)
  try {
    const url = `${constants.NEW_DOMAIN}/api/v1/company/find/${id}`
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
      }
    })
    form.setFieldsValue(res.data.data)
    // console.log(res.data.data);
    setCompany(res.data.data)
  form.setFieldValue("SMM",res.data.data?.tasks?.SMM)
  form.setFieldValue("GD",res.data.data?.tasks?.GD)
  form.setFieldValue("VE",res.data.data?.tasks?.VE)
  form.setFieldValue("CW",res.data.data?.tasks?.CW)
  form.setFieldValue("PSY",res.data.data?.tasks?.PSY)
  form.setFieldValue("ID",res.data.data?.tasks?.ID)
  form.setFieldValue("FD",res.data.data?.tasks?.FD)
  form.setFieldValue("WD",res.data.data?.tasks?.WD)
  form.setFieldValue("FM",res.data.data?.tasks?.FM)
  form.setFieldValue("GDPRO",res.data.data?.tasks?.GDPRO)
  form.setFieldValue("DM",res.data.data?.tasks?.DM)
    setLoading(false)
  } catch (error) {
    setLoading(false)
    console.log(error)
  }
}
  useEffect(() => {
    fetchData()
  }, [])
 
  const onFinish = async (values) => {
//  console.log(values);
const body ={
  "companyId": values.companyId,
  "tasks": {
    "GD": values.GD===undefined? company.tasks.GD===undefined? [] : company.tasks.GD : values.GD,
    "SMM": values.SMM===undefined? company.tasks.SMM===undefined? [] : company.tasks.SMM : values.SMM,
    "VE": values.VE===undefined? company.tasks.VE===undefined? [] : company.tasks.VE : values.VE,
    "CW": values.CW===undefined? company.tasks.CW===undefined? [] : company.tasks.CW : values.CW,
    "PSY": values.PSY===undefined? company.tasks.PSY===undefined? [] : company.tasks.PSY : values.PSY,
    "ID": values.ID===undefined? company.tasks.ID===undefined? [] : company.tasks.ID : values.ID,
    "FD": values.FD===undefined? company.tasks.FD===undefined? [] : company.tasks.FD : values.FD,
    "WD": values.WD===undefined? company.tasks.WD===undefined? [] : company.tasks.WD : values.WD,
    "FM": values.FM===undefined? company.tasks.FM===undefined? [] : company.tasks.FM : values.FM,
    "GDPRO": values.GDPRO===undefined? company.tasks.GDPRO===undefined? [] : company.tasks.GDPRO : values.GDPRO,
    "DM": values.DM===undefined? company.tasks.DM===undefined? [] : company.tasks.DM : values.DM,
  },
  "description": values.description,
  "name": values.name,
  "signatureUrl": values.signatureUrl,
  "POC": values.POC,
  "designation": values.designation,
  "companyUrl": values.companyUrl,
  "logoUrl": values.logoUrl
}
    setLoading(true)
    // console.log(values);
    const url = `${constants.NEW_DOMAIN}/api/v1/company/`
    try {
      // const token = await getToken()
      const headers = {
        // authorization: `bearer ${token}`,
      }

      const res = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      form.resetFields()
      // setCompany((c) => {
      //   const newArr = c.map((category) => {
      //     if (category._id === res.data.data._id) {
      //       return res.data.data
      //     } else {
      //       return category
      //     }
      //   })
      //   return newArr
      // })
      
      setLoading(false)
    } catch (error) {
      if (error.response) {
        setLoading(false)
        console.log(error.response.data)
        console.log(error.response.status)
      }
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div style={{ margin: "0 10px" }}>
      {/* <button onClick={log}>test</button> */}
      <Row gutter={[16, 24]}>
        <Col xs={24} md={8}>
          <Card
            title="Company"
            bordered={false}
            extra={
              <Button onClick={() => form.resetFields()}>Add New +</Button>
            }
          >
        {width >800 ?<>    {data.map((c, i) => {
              return (
                <Card
                  style={{
                    marginTop: 16,
                  }}
                  key={i}
                  type="inner"
                  title={c.companyId}
                  extra={
                    <Button
                      type="link"
                      onClick={() => {
                        getCompany(c._id)
                      }}
                    >
                      Edit
                    </Button>
                  }
                >
                  <Text ellipsis={true}>{c.name}</Text>
                </Card>
              )
            })}</>:<>
            <Select
    showSearch
    style={{
      width: 200,
    }}
    placeholder="Search to Select"
    optionFilterProp="children"
    filterOption={(input, option) => (option?.label ?? '').includes(input)}
    filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
    onChange={(c)=>{ 
  
      getCompany(c)
      }}
    options={(data || []).map((d) => ({
      value: d._id,
      data:d,
      label: d.name,
    }))}
  />
            </>}
          </Card>
        </Col>

        <Col xs={24} md={16} style={{ maxHeight: "80vh", overflowY: "scroll" }}>
          <Card title="Edit Company">
            <Form
              name="dynamic_form_nest_item"
              form={form}
              labelCol={{
                span: 3,
              }}
              // initialValues={{
              //   remember: true,
              // }}
              wrapperCol={{
                span: 16,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="companyId"
                label="code"
                rules={[
                  {
                    required: true,
                    message: "code is required!",
                  },
                ]}
              >
                <Input placeholder="ex: CSB" value={form.getFieldValue("companyId")} />
              </Form.Item>

              <Form.Item
                name="name"
                label="Company Name"
                rules={[
                  {
                    required: true,
                    message: "company name is required!",
                  },
                ]}
              >
                <Input placeholder="ex: the Blunt, Chai Sutta Bar" />
              </Form.Item>

              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "Please provide description!",
                  },
                ]}
              >
                <Input.TextArea placeholder="description" />
              </Form.Item>
              <Form.Item
                name="companyUrl"
                label="Website Link"
                rules={[
                  {
                    type: "url",
                    required: true,
                    message: "Please provide company website link",
                  },
               
                ]}
              >
                <Input placeholder="Enter Company Website Link" />
              </Form.Item>
               <Form.Item
                name="signatureUrl"
                label="Signature Link"
                rules={[
                  {
                    type: "url",
                    required: true,
                    message: "Please provide signature link",
                  },
               
                ]}
              >
                <Input placeholder="Enter Company Website Link" />
              </Form.Item>
              <Form.Item
                name="logoUrl"
                label="Logo"
                rules={[
                  {
                    type: "url",
                    required: true,
                    message: "Please provide company logo url",
                  }
                ]}
              >
                <Input placeholder="Enter Company logoLink" />
              </Form.Item>
              <Form.Item name="POC" label="POC"
              rules={[
                    {
                        required:true,
                        message:"POC is required"
                    }
                ]}
              >
                <Input placeholder="Enter Company Point of Contact" />
              </Form.Item>
              <Form.Item name="designation" label="Designation"
              rules={[
                    {
                        required:true,
                        message:"Designation is required"
                    }
                ]}
              >
                <Input placeholder="Enter Company Contact Person Designation" />
              </Form.Item>
              <Divider
                orientation="left"
                style={{
                  margin: "0px 0",
                  color: "#5c4db1",
                  // fontWeight: "bolder",
                }}
              >
                Category
              </Divider>
               <Tabs centered id="tasks" 
               defaultActiveKey="1"
    items={[
      {
        label: 'SMM',
        key: '1',
        children: <CourseTab cate="SMM"/>,
      },
       {
        label: 'GD',
        key: '2',
        children: <CourseTab cate="GD"/>,
      },
       {
        label: 'CW',
        key: '3',
        children: <CourseTab cate="CW"/>,
      },
       {
        label: 'VE',
        key: '4',
        children: <CourseTab cate="VE"/>,
      },
      {
        label: 'PSY',
        key: '5',
        children: <CourseTab cate="PSY"/>,
      },
      // {
      //   label: 'ID',
      //   key: '6',
      //   children: <CourseTab cate="ID"/>,
      // },
      {
        label: 'FD',
        key: '7',
        children: <CourseTab cate="FD"/>,
      },
      {
        label: 'WD',
        key: '8',
        children: <CourseTab cate="WD"/>,
      },
      {
        label: 'FM',
        key: '9',
        children: <CourseTab cate="FM"/>,
      },
      {
        label: 'GDPRO',
        key: '10',
        children: <CourseTab cate="GDPRO"/>,
      },
      {
        label: 'DM',
        key: '11',
        children: <CourseTab cate="DM"/>,
      },
    ]}
               
              />
                
              
              
              
              <Form.Item
                wrapperCol={{
                  offset: 3,
                  span: 16,
                }}
              >
                <Button type="primary" block htmlType="submit">
                  Create Company
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Company

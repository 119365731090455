import React from "react"
import Dashboard from "./components/Dashboard/Dashboard"
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"
import PaidUser from "./components/SIP/PaidUser"
import LeadsCRM from "./components/SIP/LeadsCRM"
import Analytics from "./components/SIP/Analytics"
import Category from "./components/Category"
import { Empty } from "antd"
import Batch from "./components/Batch/Batch"
import Assessment from "./components/Category/Assessment"
import Slots from "./components/Internship/Slots"
import Company from "./components/Company"
import AssignCompany from "./components/Internship/AssignCompany"
import { AuthProvider } from "./contexts/authContext"
import SignIn from "./components/register/SignIn"
import Authorized from "./AuthRoutes/Authorized"
import UnAuthorized from "./components/UnAuthorized"
import AddLead from "./components/Sales_B2B/addLead"
import Feedback from "./components/Internship/Feedback"
import OverallUser from "./OverallUser"
import ViewportProvider from "./contexts/viewPort"
import Schools from "./components/Sales_B2B/schools"
import B2bLeadCrm from "./components/Sales_B2B/B2bLeadCrm"
import ThirdPartyAnalytics from "./components/SIP/ThirdPartyAnalytics"
import Export from "./components/Export/Export"
import CurrentAnalytics from "./components/Batch/CurrentAnalytics"
import BatchModify from "./components/Batch/BatchModify"
import AlphaInternship from "./components/alphaInternship"
import Applicants from "./components/alphaInternship/Applicants"
import PreviewResume from "./components/resume/previewResume"
import Loader from "./util/loader"
import LeadCRMForeign from "./components/ForeignProgram/LeadCRMForeign"
import ThirdPartyLeadCRM from "./components/SIP/ThirdPartyLeadCRM"
import CatalysAnalytics from "./components/SIP/CatalysAnalytics"
import Agent from "./components/AgentConfiguration/Agent"
import JsonEditor from "./components/cms/JsonEditor"
import Feedback_V1 from "./components/Internship/Feedback_V1"
import CatalysUtmAnalytics from "./components/SIP/UtmAnalytics"
import PocAnalytics from "./components/SIP/PocAnalytics"
import Messanger from "./components/Messanger/Messanger"
// import UserData from "./components/Sales_B2B/userdata"

function App() {
  return (
    <div style={{ overflowX: "hidden" }}>
      <Router>
        <ViewportProvider>
          <AuthProvider>
            <Routes>
              {/* Public routes */}
              <Route path="/signin" element={<SignIn />} />
              {/* <Route path="/t" element={<Loader />} /> */}
              <Route path="/unauthorized" element={<UnAuthorized />} />
              <Route path="/resume/:id" element={<PreviewResume />} />

              {/* Private Routes */}
              <Route path="/" element={<Authorized allowedRoles={["SUDO"]} />}>
                <Route path="/" element={<Dashboard />} />

                {/* rendering Sales B2C routes */}
                <Route
                  path="/b2c"
                  element={
                    <Authorized
                      allowedRoles={[
                        "SALES_B2C",
                        "ADMIN",
                        "SALES_ADMIN",
                        "SUPPORT", 
                        "OPERATIONS", 
                      ]}
                    />
                  }
                >
                  <Route
                    path="paid-users"
                    element={<Dashboard component={<PaidUser />} />}
                  />
                  <Route
                    path="marketing"
                    element={<Dashboard component={<LeadsCRM />} />}
                  />
                  <Route
                    path="analytics"
                    element={<Dashboard component={<Analytics />} />}
                  />
                   <Route
                    path="pocanalytics"
                    element={<Dashboard component={<PocAnalytics />} />}
                  />
                </Route>
                {/* rendering foreign program routes */}
                <Route
                  path="/foreign"
                  element={
                    <Authorized allowedRoles={["ADMIN", "FOREIGN_B2C"]} />
                  }
                >
                  <Route
                    path="marketing"
                    element={<Dashboard component={<LeadCRMForeign />} />}
                  />
                </Route>

                <Route
                  path="/analytics"
                  element={<Authorized allowedRoles={["ANALYTICS", "ADMIN"]} />}
                >
                  <Route
                    path=""
                    element={<Dashboard component={<Analytics />} />}
                  />
                </Route>

                {/* Third Party Analytics Dashboard */}
                <Route
                  path="/analytics"
                  element={<Authorized allowedRoles={["FAMPAY", "ADMIN"]} />}
                >
                  <Route
                    path="fam"
                    element={
                      <Dashboard
                        component={<ThirdPartyAnalytics partner="FAMPAY" />}
                      />
                    }
                  />
                </Route>

                <Route
                  path="/crm"
                  element={<Authorized allowedRoles={["CATALYS", "ADMIN"]} />}
                >
                  <Route
                    path="leads"
                    element={
                      <Dashboard
                        component={<ThirdPartyLeadCRM partner="FAMPAY" />}
                      />
                    }
                  />
                  <Route
                    path="analytics"
                    element={<Dashboard component={<CatalysAnalytics />} />}
                  />
                  <Route
                    path="utmanalytics"
                    element={<Dashboard component={<CatalysUtmAnalytics />} />}
                  />
                </Route>

                

                {/* rendering Sales B2B routes*/}
                <Route
                  path="/b2b"
                  element={
                    <Authorized
                      allowedRoles={["SALES_B2B", "ADMIN", "OPERATIONS","AFS", "GLENTREE"]}
                    />
                  }
                >
                  <Route
                    path="school/:type/:id"
                    element={<Dashboard component={<Schools />} />}
                  />
                  <Route
                    path="school/leadcrm/:id"
                    element={<Dashboard component={<B2bLeadCrm />} />}
                  />
                  <Route
                    path="addLead"
                    element={<Dashboard component={<AddLead />} />}
                  />
                  {/* <Route
                    path="user/:id"
                    element={<Dashboard component={<UserData />} />}
                  /> */}
                </Route>

                <Route
                  path="/"
                  element={
                    <Authorized
                      allowedRoles={["SUDO", "ADMIN", "SUPPORT", "OPERATIONS"]}
                    />
                  }
                >
                  <Route
                    path="users"
                    element={<Dashboard component={<OverallUser />} />}
                  />
                </Route>

                {/* Rendering ADMIN only routes */}
                <Route
                  path="/"
                  element={
                    <Authorized allowedRoles={["ADMIN", "OPERATIONS"]} />
                  }
                >
                  <Route
                    path="businessanalytics"
                    element={<Dashboard component={<PocAnalytics />} />}
                  />
                  <Route
                    path="batch"
                    element={<Dashboard component={<Batch />} />}
                  />
                  <Route
                    path="batch/modify"
                    element={<Dashboard component={<BatchModify />} />}
                  />
                  <Route
                    path="batch/analytics"
                    element={<Dashboard component={<CurrentAnalytics />} />}
                  />
                  <Route
                    path="courses/videos"
                    element={<Dashboard component={<Category />} />}
                  />

                  <Route
                    path="courses/assessment"
                    element={<Dashboard component={<Assessment />} />}
                  />
                  <Route
                    path="internship/slots"
                    element={<Dashboard component={<Slots />} />}
                  />
                  <Route
                    path="internship/user"
                    element={<Dashboard component={<AssignCompany />} />}
                  />
                  <Route
                    path="internship/alpha"
                    element={<Dashboard component={<AlphaInternship />} />}
                  />
                  <Route
                    path="internship/alpha/application/:id"
                    element={<Dashboard component={<Applicants />} />}
                  />

                  <Route
                    path="company"
                    element={<Dashboard component={<Company />} />}
                  />
                  <Route
                    path="export"
                    element={<Dashboard component={<Export />} />}
                  />
                   <Route
                    path="/cms/json"
                    element={<Dashboard component={<JsonEditor />} />}
                  />
                  <Route
                    path="/messanger"
                    element={<Dashboard component={<Messanger />} />}
                  />
                </Route>
      
                <Route
                  path="/live"
                  element={
                    <Authorized
                      allowedRoles={["SUDO", "OPS_LIVE"]}
                    />
                  }
                >
                   <Route
                    path="batch/modify"
                    element={<Dashboard component={<BatchModify />} />}
                  />
                  <Route
                    path="batch/analytics"
                    element={<Dashboard component={<CurrentAnalytics />} />}
                  />
                </Route>

                <Route
                  path="/"
                  element={
                    <Authorized allowedRoles={["SUDO", "ADMIN", "MENTOR"]} />
                  }
                >
                  <Route
                    path="internship/feedback"
                    element={<Dashboard component={<Feedback_V1 />} />}
                  />
                </Route>

                <Route
                  path="/"
                  element={
                    <Authorized allowedRoles={["ADMIN"]} />
                  }
                >
                  <Route
                    path="config"
                    element={<Dashboard component={<Agent />} />}
                  />
                </Route>
              </Route>
            </Routes>
          </AuthProvider>
        </ViewportProvider>
      </Router>
    </div>
  )
}

export default App

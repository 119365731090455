import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Skeleton,
  Space,
  Switch,
  TimePicker,
  Tooltip,
  Typography,
  Select
} from "antd"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import axios from "axios"
import constants from "../../constants"
import dayjs from "dayjs"
import Loader from "../../util/loader"
import useView from "../../hooks/useView"
const { Text } = Typography
const { Option } = Select
const Slots = () => {
  const [liveDays, setLiveDays] = useState()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const {width} = useView();
  const fetchData = async () => {
    setLoading(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/slot?allSlot=true`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setData(res.data.data)
      console.log(res)
      console.log(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const fetchBatchById = async (id) => {
    setLoading(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/slot/find/${id}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      // setLiveDays(res.data.data.liveDays)
      if (res.data?.data?.timeLine)
        res.data.data.timeLine = res.data.data.timeLine.map((obj) => {
          return {
            ...obj,
            startDate: dayjs(obj.startDate),
            endDate: dayjs(obj.endDate),
          }
        })
      form.setFieldsValue(res.data.data)
      form.setFieldValue("startDate", dayjs(res.data.data.startDate))
      form.setFieldValue("endDate", dayjs(res.data.data.endDate))
      // setBatch(res.data.data.doc)
      console.log(res.data.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    const url = `${constants.NEW_DOMAIN}/api/v1/slot/`
    try {
      // // const token = await getToken()
      // const headers = {
      //   // authorization: `bearer ${token}`,
      // }

      const res = await axios.post(url, values, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      console.log(res)
      form.resetFields()
      // setData((prevData) => {
      //   const newArr = prevData.map((category) => {
      //     if (category?._id === res.data.data._id) {
      //       return res.data.data
      //     } else {
      //       return category
      //     }
      //   })
      //   return newArr
      // })
      setLoading(false)
    } catch (error) {
      if (error.response) {
        setLoading(false)
        console.log(error.response.data)
        console.log(error.response.status)
      }
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div style={{ margin: "0 10px" }}>
      <Row gutter={[16, 24]}>
        <Col xs={24} md={8}>
          <Card
            title=" Internship Slots"
            extra={
              <Button onClick={() => form.resetFields()}>Add New +</Button>
            }
            style={{ maxHeight: "80vh", overflowY: "scroll" }}
          >
          {width >800 ?<>  {data.map((c, i) => {
              return (
                <Card
                  style={{
                    marginTop: 16,
                    ...(!c.intakeActive && { backgroundColor: "antiquewhite" }),
                  }}
                  key={i}
                  type="inner"
                  title={c.slotId + " : " + c.intakeActive}
                  extra={
                    <Button
                      type="link"
                      onClick={() => {
                        // console.log(c.Id, c._id)
                        fetchBatchById(c._id)
                      }}
                    >
                      Edit
                    </Button>
                  }
                >
                  <Text ellipsis={true}>{c.description}</Text>
                </Card>
              )
            })}</>:<>
            <Select
    showSearch
    style={{
      width: 200,
    }}
    placeholder="Search to Select"
    optionFilterProp="children"
    filterOption={(input, option) => (option?.label ?? '').includes(input)}
    filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
    onChange={(c)=>{ 
    //   school.map((data,i)=>{
    //     if(c === data.schoolId){
    //       form.setFieldsValue(data)
    //     }
    // })
    fetchBatchById(c)
      // console.log(c);
      }}
    options={(data || []).map((d) => ({
      value: d._id,
      data:d,
      label: d.slotId + " : " + d.intakeActive,
    }))}
  />
            </>}
          </Card>
        </Col>
        <Col xs={24} md={16} style={{ maxHeight: "80vh", overflowY: "scroll" }}>
          <Card title="Edit Internship Slots">
            <Form
              name="dynamic_form_nest_item"
              form={form}
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 16,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="slotId"
                label="Internship Id"
                rules={[
                  {
                    required: true,
                    message: "Internship Id is required!",
                  },
                ]}
              >
                <Input
                  value={form.getFieldValue("slotId")}
                  placeholder="INT_05_06"
                />
              </Form.Item>

              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "Please provide description!",
                  },
                ]}
              >
                <Input.TextArea placeholder="description" />
              </Form.Item>

              <Form.Item
                name="startDate"
                label="Internship Start Date"
                rules={[
                  {
                    required: true,
                    message: "Please provide training start date!",
                  },
                ]}
              >
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  // style={{
                  //   width: "100%",
                  // }}
                />
              </Form.Item>
              <Form.Item
                name="endDate"
                label="Internship End Date"
                rules={[
                  {
                    required: true,
                    message: "Please provide training start date!",
                  },
                ]}
              >
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  // style={{
                  //   width: "100%",
                  // }}
                />
              </Form.Item>
              <Form.Item
                name="intakeActive"
                label="Slot Active"
                valuePropName={"checked"}
              >
                <Switch />
              </Form.Item>
              <Divider
                orientation="left"
                style={{
                  margin: "30px 0",
                  color: "#5c4db1",
                  // fontWeight: "bolder",
                }}
              >
                Weeks
              </Divider>
              <Form.List name="timeLine">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, i) => (
                      <div key={i}>
                        <h1 style={{ textAlign: "center" }}>
                          Week #{i + 1}
                          <span>
                            <MinusCircleOutlined
                              style={{ color: "red", marginLeft: "20px" }}
                              onClick={() => remove(name)}
                            />
                          </span>
                        </h1>
                        <Form.Item
                          {...restField}
                          name={[name, "id"]}
                          label="ID"
                          rules={[
                            {
                              required: true,
                              message: "id is required",
                            },
                          ]}
                        >
                          <Input type="number" placeholder="ex : 1,2,3" />
                        </Form.Item>
                        <Form.Item
                          label=" Start Date"
                          rules={[
                            {
                              required: true,
                              message: "Please provide Date",
                            },
                          ]}
                        >
                          <Space direction="verticle">
                            <Form.Item
                              {...restField}
                              name={[name, "startDate"]}
                              // label="Date"
                            >
                              {/* <Input placeholder=" start date and time" /> */}
                              <DatePicker
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                // style={{
                                //   width: "100%",
                                // }}
                              />
                            </Form.Item>
                            <Tooltip title="2023-04-14 21:00:00">
                              <Typography.Link href="#API">
                                Need Help on format?
                              </Typography.Link>
                            </Tooltip>
                          </Space>
                        </Form.Item>
                        <Form.Item
                          label="End Date"
                          rules={[
                            {
                              required: true,
                              message: "Please provide Date",
                            },
                          ]}
                        >
                          <Space direction="verticle">
                            <Form.Item
                              {...restField}
                              name={[name, "endDate"]}
                              // label="Date"
                            >
                              <DatePicker
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                // style={{
                                //   width: "100%",
                                // }}
                              />
                            </Form.Item>
                            <Tooltip title="2023-04-14 21:00:00">
                              <Typography.Link href="#API">
                                Need Help on format?
                              </Typography.Link>
                            </Tooltip>
                          </Space>
                        </Form.Item>
                      </div>
                    ))}
                    <Form.Item
                      wrapperCol={{
                        offset: 3,
                        span: 16,
                      }}
                    >
                      <Button
                        // type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add New Week
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Form.Item
                wrapperCol={{
                  offset: 4,
                  span: 16,
                }}
              >
                <Button type="primary" block htmlType="submit">
                  Create Internship Slot
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Slots

import axios from "axios"
import { useEffect, useState, useRef } from "react"
import {
  SearchOutlined,
  EditOutlined,
  WhatsAppOutlined,
  CheckOutlined,
  CloseCircleTwoTone,
  ClockCircleTwoTone,
  ReloadOutlined,
  ScissorOutlined,
  BankOutlined,
  RocketTwoTone,
  StarFilled,
  HistoryOutlined,
  NodeIndexOutlined,
  ArrowLeftOutlined,
  BarsOutlined,
  AppstoreOutlined
} from "@ant-design/icons"
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Statistic,
  Table,
  Tooltip,
  Modal,
  message,
  Empty,
  Timeline,
  Checkbox,
  List,
  Typography,
  Tag,
  Segmented
} from "antd"
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useNavigate,
    json,
    useParams
  } from "react-router-dom"
import dayjs from "dayjs"
import TextArea from "antd/es/input/TextArea"
import constants from "../../constants"
import { Form } from "antd"
import { useAuth } from "../../contexts/authContext"
import useView from "../../hooks/useView"
import FamLogo from "../../images/fam-logo.jpg"

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 16,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 5,
    span: 16,
  },
}
const Applicants = () => {
  const [data, setData] = useState({})
  const [filteredData, setFilteredData] = useState({
    applicant:[],
    shortlisted:[],
    selected:[]
  })
  const [loading, setLoading] = useState(false)
  const [viewType, setViewType] = useState("APPLIED");

  const { Text } = Typography
  const {id}=useParams()
  const Navigate = useNavigate()
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      position: ["topCenter"],
    },
    filters: {},
  })
  const [edit, setEdit] = useState({
    name: "test",
    orderId: "test",
  })
  const { currentUser } = useAuth()

  const [messageApi, contextHolder] = message.useMessage()
  const searchInput = useRef(null)
  const { isMobile } = useView()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalRefferalOpen, setIsModalRefferalOpen] = useState(false)
  const [refferal, setRefferal] = useState(undefined)
  const [showHistory, setShowHistory] = useState(undefined)
  const [form] = Form.useForm()
  
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={
            dataIndex === "nextFollowUp" ? "YYYY-MM-DD" : `Search ${dataIndex}`
          }
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
  })

  const handleSubmit = async (record) => {
    console.log(record)
    //save the user comment
    setLoading(true)
    try {
      record.new = false
      const url = `${constants.NEW_DOMAIN}/api/v1/users/applicant/update`
      const res = await axios.put(url, record, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setLoading(false)
      fetchData()
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  } 


  const columns = [
    {
      title: "Name",
      dataIndex: "enrollmentId",
      //   fixed: "left",
      sorter: false,
      render: (data, record) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
           <Text > {data.userId?.name}</Text>
          </div>
        )
      },
      ...getColumnSearchProps("name"),
    },
    {
      title: "Phone",
      dataIndex: "enrollmentId",
      sorter: false,
      //   fixed: "left",
      render: (data,record) => (
        <>
       {data.userId?.phone}
        </>
      ),
      ...getColumnSearchProps("phone"),
    },
    {
      title: "City",
      dataIndex: "enrollmentId",
      //   fixed: "left",
      sorter: false,
      render: (data, record) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
           <Text > {data?.userId?.profile?.city}</Text>
          </div>
        )
      },
      ...getColumnSearchProps("city"),
    },
    {
      title: "Category",
      dataIndex: "categoryId",
      render: (data,record) => {
        let color =
          data.code === "SMM"
            ? "#B2A4FF"
            : data.code === "VE"
            ? "#E11299"
            : data.code === "CW"
            ? "#867070"
            : "#E4D0D0"
        return <Tag color={color}>{data.code}</Tag>
      },
    },
    isMobile
      ? {
          width: 0,
        }
      : {
          title: "Applied On",
          dataIndex: "appliedOn",
          sorter: false,
          render: (date) => (
            <>
              {new Date(date).toLocaleDateString()}
              <br />
              <div style={{ color: "lightgray" }}>
                {new Date(date).toLocaleTimeString()}
              </div>
            </>
          ),
        },
    {
      title: "Status",
      dataIndex: "status",
      width:200,
      // filters: [
      //   {
      //       value: "APPLIED",
      //       label: "Applied",
      //     },
      //     {
      //       value: "NOT_SELECTED",
      //       label: "Not Selected",
      //     },
      //     {
      //       value: "SHORTLISTED",
      //       label: "Shortlisted",
      //     },
      //     {
      //       value: "SELECTED",
      //       label: "Selected",
      //     },
      // ],
      render: (status, record) => {
        return (
          <Select
            size="small"
            defaultValue={status || ""}
            style={{
              width: 100,
            }}
            onChange={(e) => {
              record.status = e
              console.log(record)
            }}
            options={[
              {
                value: "APPLIED",
                label: "Applied",
              },
              {
                value: "NOT_SELECTED",
                label: "Not Selected",
              },
              {
                value: "SHORTLISTED",
                label: "Shortlisted",
              },
              {
                value: "SELECTED",
                label: "Selected",
              },
            ]}
          />
        )
      },
    },
    {
        title: "Resume",
        dataIndex: "enrollmentId",
        width:160,
        render: (data) => {
          return (
            <a href={`http://admin.youvah.com/resume/${data.userId.firebaseId}`} target="blank">
              <Button type="dashed">Resume</Button>
            </a>
          )
        },
        // ...getColumnSearchProps("batchId"),
      },
    {
        title: "Portfolio",
        dataIndex: "enrollmentId",
        width:160,
        render: (data) => {
          return (
            <a href={data.progress?.assessment?.portfolio} target="blank">
              <Button type="dashed">View Portfolio</Button>
            </a>
          )
        },
        // ...getColumnSearchProps("batchId"),
      },
      {
        title :"Intern.. Task 1",
        dataIndex: "enrollmentId",
        width:210,
        render:(data)=>{
         return (<>
         {
           data.progress?.internship?.week1 === undefined ?<Tag color="red">PENDING</Tag>:
           <div>
             Task :  <a href={data.progress?.internship?.week1.url} target="blank"><Button type="dashed">View</Button></a><br/>
             <div style={{ color: "lightgray",marginTop:"5px" }}>
             Submitted on : {new Date(data.progress?.internship?.week1.createdAt).toLocaleDateString()}<br/>
             Updated on : {new Date(data.progress?.internship?.week1.updatedAt).toLocaleDateString()}
             </div>
           </div>
         }
         </>)
        }
           },
           {
            title :"Intern.. Task 2",
            dataIndex: "enrollmentId",
            width:210,
            render:(data)=>{
             return (<>
             {
               data.progress?.internship?.week2 === undefined ?<Tag color="red">PENDING</Tag>:
               <div>
                 Task :  <a href={data.progress?.internship?.week2.url} target="blank"><Button type="dashed">View</Button></a><br/>
                 <div style={{ color: "lightgray",marginTop:"5px" }}>
                 Submitted on : {new Date(data.progress?.internship?.week2.createdAt).toLocaleDateString()}<br/>
                 Updated on : {new Date(data.progress?.internship?.week2.updatedAt).toLocaleDateString()}
                 </div>
               </div>
             }
             </>)
            }
               },
               {
                title :"Intern.. Task 3",
                dataIndex: "enrollmentId",
                width:210,
                render:(data)=>{
                 return (<>
                 {
                   data.progress?.internship?.week3 === undefined ?<Tag color="red">PENDING</Tag>:
                   <div>
                     Task :  <a href={data.progress?.internship?.week3.url} target="blank"><Button type="dashed">View</Button></a><br/>
                     <div style={{ color: "lightgray",marginTop:"5px" }}>
                     Submitted on : {new Date(data.progress?.internship?.week3.createdAt).toLocaleDateString()}<br/>
                     Updated on : {new Date(data.progress?.internship?.week3.updatedAt).toLocaleDateString()}
                     </div>
                   </div>
                 }
                 </>)
                }
                   },
                   {
                    title :"Intern.. Task 4",
                    dataIndex: "enrollmentId",
                    width:210,
                    render:(data)=>{
                     return (<>
                     {
                       data.progress?.internship?.week4 === undefined ?<Tag color="red">PENDING</Tag>:
                       <div>
                         Task :  <a href={data.progress?.internship?.week4.url} target="blank"><Button type="dashed">View</Button></a><br/>
                         <div style={{ color: "lightgray",marginTop:"5px" }}>
                         Submitted on : {new Date(data.progress?.internship?.week4.createdAt).toLocaleDateString()}<br/>
                         Updated on : {new Date(data.progress?.internship?.week4.updatedAt).toLocaleDateString()}
                         </div>
                       </div>
                     }
                     </>)
                    }
                       },

    
    {
      title: "Email",
      dataIndex: "enrollmentId",
      sorter: false,
      //   fixed: "left",
      render: (data,record) => (
        <>
       {data.userId?.email}
        </>
      ),
      // ...getColumnSearchProps("email"),
      width: 150,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 80,
      render: (_, record) => (
        <>
          <Space direction="vertical" size={"small"}>
            <Tooltip title="save">
              <Button
                type="link"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => handleSubmit(record)}
              />
            </Tooltip>
          </Space>
        </>
      ),
    },
  ]

  const renderHeader = () => {
    return (
        <>
      <Space size={16}>
        <Button
          type="default"
          icon={<ScissorOutlined />}
          onClick={() => setTableParams({ ...tableParams, filters: {} })}
          loading={loading}
        >
          Clear Filters
        </Button>
        <Button
          type="default"
          icon={<ReloadOutlined />}
          onClick={() => fetchData()}
          loading={loading}
        >
          Reload
        </Button>
        <Button
          type="default"
          icon={<ArrowLeftOutlined />}
          onClick={() => Navigate("/internship/alpha")}
          loading={loading}
        >
          Go Back
        </Button>
       
      </Space>
      <br></br>
      <br></br>
        <Segmented
              onChange={(e)=>setViewType(e)}
    options={[
      {
        label: 'Applicants',
        value: 'APPLIED',
        icon: <BarsOutlined />,
      },
      {
        label: 'Shortlisted',
        value: 'SHORTLISTED',
        icon: <AppstoreOutlined />,
      },
      {
        label: 'Selected',
        value: 'SELECTED',
        icon: <AppstoreOutlined />,
      },
    ]}
  />
      </>
    )
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 }, //reset the page to 1
      filters: {
        [dataIndex]: selectedKeys,
      },
    })
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    fetchData()
  }

  const fetchData = async () => {
    setLoading(true)
    console.log("tableParams : ", tableParams)
    try {
      let query = []
      Object.keys(tableParams.filters).forEach((k) => {
        if (
          tableParams.filters[k] !== null &&
          tableParams.filters[k].length > 0
        ) {
          if (k === "name") {
            query.push(`${k}=${tableParams.filters[k]}`)
          } else {
            query.push(`${k}=${tableParams.filters[k]}`)
          }
        }
      })
      //   name={$regex:^fin,$options:'i'}&

      query =
        `page=${tableParams.pagination.current}&limit=${tableParams.pagination.pageSize}&firebaseId=${currentUser._delegate.uid}&` +
        query.join("&")
      const url = `${constants.NEW_DOMAIN}/api/v1/users/internship/alpha/application/${id}?${query}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
            console.log('res', res);
    let applicant =[]
    let shortlisted =[]
    let selected =[]
      res?.data?.application?.map((data)=>{
        if(data.status==="APPLIED" || data.status === "NOT_SELECTED"){
            applicant.push(data)
        }
        if(data.status==="SHORTLISTED"){
            shortlisted.push(data)
        }
        if(data.status==="SELECTED"){
            selected.push(data)
        }
      })
      setData(res.data)
      setFilteredData({
        applicant,
        shortlisted,
        selected
      })
      setLoading(false)
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.data.results,
        },
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [JSON.stringify(tableParams)])

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    })

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([])
    }
  }
  return (
    <>
      {contextHolder}
      <div style={{ backgroundColor: "#fff", padding: "20px" }}>

        <Row>
          <Col xs={24} md={20}>
            <div className="text-3xl">Applications</div>
            <p className="text-lg-light">
            {data?.company?.name}
              {/* {new Date().toDateString()} - {new Date().toLocaleTimeString()} */}
            </p>
          </Col>
          <Col xs={24} md={4}>
            <Statistic
              title="Total Application"
              loading={loading}
              value={viewType === "APPLIED" ? filteredData?.applicant.length : viewType==="SHORTLISTED"?filteredData?.shortlisted.length:filteredData?.selected.length}
              precision={0}
              valueStyle={{
                color: "blue",
              }}
              // prefix={<ArrowUpOutlined />}
              suffix="Applicant"
            />
          </Col>
         
        </Row>
     
        <Table
          theme="dark"
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={viewType === "APPLIED" ? filteredData?.applicant : viewType==="SHORTLISTED"?filteredData?.shortlisted:filteredData?.selected}
          title={renderHeader}
          pagination={tableParams.pagination}
          loading={loading}
          rowClassName="row"
          onChange={handleTableChange}
          size="medium"
          scroll={{
            x: 2500,
            // y: 500,
          }}
        />
      </div>
    </>
  )
}
export default Applicants

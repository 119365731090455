import React, { useEffect, useState } from "react"
import { Card, Col, Row, Space, Statistic, Table } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { Column, Pie } from "@ant-design/plots"
import axios from "axios"
import constants from "../../constants"
import { useAuth } from "../../contexts/authContext"
import { socket } from "../../util/socket"
import Loader from "../../util/loader"

const Analytics = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const { currentUser, roles } = useAuth()
  const isAdmin =
    roles.includes("ADMIN") ||
    roles.includes("ANALYTICS") ||
    roles.includes("SALES_ADMIN") || roles.includes("CATALYS")
  const fetchData = async () => {
    setLoading(true)

    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/analytics?firebaseId=${currentUser._delegate.uid}`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      const responseData = res.data?.data
      if (!responseData) {
        return <h1>Error!</h1>
      }
      let revenueData = []
      if (isAdmin) {
        let revenueDict = {}
        for (let i = 0; i < responseData.thisMonthRevenuePerPOC.length; i++) {
          let thisMonthRevObj = responseData.thisMonthRevenuePerPOC[i]
          let todayRevObj =
            responseData.todaysRevenuePerPOC.length > i
              ? responseData.todaysRevenuePerPOC[i]
              : null
          let todaysPayObj =
            responseData.todaysPaymentPerPOC.length > i
              ? responseData.todaysPaymentPerPOC[i]
              : null

          revenueDict[thisMonthRevObj._id] = {
            ...revenueDict[thisMonthRevObj._id],
            lead: thisMonthRevObj._id,
            thisMonthRevenue: thisMonthRevObj.amount,
          }

          if (todayRevObj) {
            revenueDict[todayRevObj._id] = {
              ...revenueDict[todayRevObj._id],
              todaysRevenue: todayRevObj.amount,
            }
          }

          if (todaysPayObj) {
            revenueDict[todaysPayObj._id] = {
              ...revenueDict[todaysPayObj._id],
              todaysPayment: todaysPayObj.count,
            }
          }

          // revenueData.push({
          //   lead: responseData.thisMonthRevenuePerPOC[i]._id,
          //   todaysPayment:
          //     responseData.todaysPaymentPerPOC.length > i
          //       ? responseData.todaysPaymentPerPOC[i].count
          //       : 0,
          //   todaysRevenue:
          //     responseData.todaysRevenuePerPOC.length > i
          //       ? responseData.todaysRevenuePerPOC[i].amount
          //       : 0,
          //   thisMonthRevenue: responseData.thisMonthRevenuePerPOC[i].amount,
          // })
        }
        revenueData = Object.values(revenueDict)
      } else {
        let todaysRevenueForPoc =
          responseData.todaysRevenueForPOC.length > 0
            ? responseData.todaysRevenueForPOC[0].amount
            : 0
        let thisMonthRevenueForPoc =
          responseData.thisMonthRevenueForPOC.length > 0
            ? responseData.thisMonthRevenueForPOC[0].amount
            : 0
        console.log(todaysRevenueForPoc, thisMonthRevenueForPoc)
        revenueData.push({
          todaysPayment: responseData.todaysPaymentForPOC,
          todaysRevenueForPoc,
          thisMonthRevenueForPoc,
        })
      }

      responseData.revenueData = revenueData
      setData(responseData)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    socket.on("new_lead", (raw) => {
      //updateLeadCount
      if (!isAdmin) {
        setData((oldData) => {
          let today = oldData?.todaysLeadForPOC + 1
          let total = oldData?.totalLeadCount + 1
          return { ...oldData, todaysLeadForPOC: today, totalLeadCount: total }
        })
      } else {
        setData((oldData) => {
          let today = oldData?.todaysLead + 1
          let total = oldData?.overAllLead + 1
          return { ...oldData, todaysLead: today, overAllLead: total }
        })
      }
    })
    //payment
    socket.on("payment_successful", (raw) => {
      // if(!isAdmin){
      //   setData(oldData=>{
      //   let todayPayment = oldData?.todaysPaymentForPOC +1
      //   // let muConversions = oldData?.totalPaidUser +1
      //   return {...oldData,todaysLeadForPOC:todayPayment}
      //   })
      // }else{
      //   setData(oldData=>{
      //     let todayPayment = oldData?.todaysPayment +1
      //     let totalRZ = oldData?.data?.todaysPayment_RZPY +1
      //     return {...oldData,todaysPayment:todayPayment,todaysPayment_RZPY:totalRZ}
      //     })
      // }
      fetchData()
    })
  }, [socket])

  useEffect(() => {
    fetchData()
  }, [])

  const configBarGraph = (index, alias) => {
    return {
      loading: loading || !data || !data[index] || data[index].length == 0,
      data: data ? data[index] : [],
      xField: "_id",
      yField: "count",
      label: {
        position: "middle",

        style: {
          fill: "#FFFFFF",
          // opacity: 0.6,
        },
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      meta: {
        _id: {
          alias: alias.x,
        },
        count: {
          alias: alias.y,
        },
      },

      minColumnWidth: 20,
      maxColumnWidth: 50,
    }
  }
  const configPieChart = (index) => {
    const arr = data ? data[index] : []
    const newArr = arr.map((v) => {
      // console.log("*****", v._id)
      v._id = constants.idToPOC[v._id] || v._id
      return v
    })

    return {
      loading: loading || !data || !data[index] || data[index].length == 0,
      data: newArr,
      appendPadding: 10,
      angleField: "count",
      colorField: "_id",
      radius: 1,
      innerRadius: 0.6,
      label: {
        type: "inner",
        offset: "-50%",
        content: "{value}",
        style: {
          textAlign: "center",
          fontSize: 14,
        },
      },
      interactions: [
        {
          type: "element-selected",
        },
        {
          type: "element-active",
        },
      ],
    }
  }

  const columns = [
    {
      title: "POC",
      dataIndex: "lead",
      key: "lead",
      render: (text) => <a>{constants.idToPOC[text]}</a>,
    },
    {
      title: "Today's Payment",
      dataIndex: "todaysPayment",
      key: "todaysPayment",
      render: (text) => <p>{text || 0}</p>,
      align: "center",
    },
    {
      title: "Today's Sale",
      dataIndex: "todaysRevenue",
      // key: "todaysPayment",
      render: (text) => <p>&#8377; {text || 0}</p>,
    },
    {
      title: "Monthly Sale",
      dataIndex: "thisMonthRevenue",
      // key: "todaysPayment",
      render: (text) => <p>&#8377; {text}</p>,
    },
  ]

  if (!data) return <Loader />

  return (
    <div style={{ margin: "0 10px" }}>
      <p className="text-3xl" style={{ margin: "10px" }}>
        Analytics
      </p>
      <p
        className="text-lg-light"
        style={{ margin: "10px", marginBottom: "30px" }}
      >
        Report : {new Date().toLocaleDateString()}
      </p>
      <Row gutter={[16, 24]}>
        {isAdmin ? (
          <>
            <Col xs={12} md={4}>
              <Card>
                <Statistic
                  title="Todays Lead Count"
                  loading={loading}
                  value={data?.todaysLead}
                  precision={0}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                  // prefix={<ArrowUpOutlined />}
                />
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Statistic
                  title="Todays Total Payment Count"
                  loading={loading}
                  value={data?.todaysPayment}
                  precision={0}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                  // prefix={<ArrowUpOutlined />}
                />
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Statistic
                  title="Todays Payment RZPY"
                  loading={loading}
                  value={data?.todaysPayment_RZPY}
                  precision={0}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                  // prefix={<ArrowUpOutlined />}
                />
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Statistic
                  title="Todays Total Sale"
                  loading={loading}
                  prefix="₹"
                  value={data?.todaysSale}
                  precision={0}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                  // prefix={<ArrowUpOutlined />}
                />
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Statistic
                  title="Todays Total Sale (B2B)"
                  loading={loading}
                  prefix="₹"
                  value={data?.todaysSale_B2B}
                  precision={0}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                  // prefix={<ArrowUpOutlined />}
                />
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Statistic
                  title="Todays Total Sale (B2C)"
                  loading={loading}
                  prefix="₹"
                  value={data?.todaysSale_B2C}
                  precision={0}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                  // prefix={<ArrowUpOutlined />}
                />
              </Card>
            </Col>
            {data?.currencyConversion && data.currencyConversion.map((elem)=>{
            return <Col xs={12} md={4} key={elem._id}>
            <Card>
              <Statistic
                title={
                  <>
                    Total Payments ({elem._id})
                  </>
                }
                loading={loading}
                prefix={elem._id}
                value={elem?.totalAmount} // Original amount
                precision={0}
                valueStyle={{
                  color: "#3f8600",
                }}
                suffix={`≈ ₹${elem?.convertedToINR}`} // Display converted amount
              />
            </Card>
          </Col>
          })}
            <Col xs={12} md={4}>
              <Card>
                <Statistic
                  title="Overall Leads"
                  loading={loading}
                  value={data?.overAllLead}
                  precision={0}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                  // prefix={<ArrowUpOutlined />}
                />
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Statistic
                  title="Overall PAID User"
                  loading={loading}
                  value={data?.overAllPaidUser}
                  precision={0}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                  // prefix={<ArrowUpOutlined />}
                />
              </Card>
            </Col>

            <Col xs={12} md={4}>
              <Card style={{ backgroundColor: "beige" }}>
                <Statistic
                  title="Todays Pro Lead Count"
                  loading={loading}
                  value={data?.todaysProLeads}
                  precision={0}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                  // prefix={<ArrowUpOutlined />}
                />
              </Card>
            </Col>
            {/* <Col xs={12} md={4}>
              <Card style={{ backgroundColor: "beige" }}>
                <Statistic
                  title="Todays MSU count"
                  loading={loading}
                  value={data?.todaysMSULeads}
                  precision={0}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                  // prefix={<ArrowUpOutlined />}
                />
              </Card>
            </Col> */}

            <Col xs={24} md={24}>
              <Card title="Today's Leads per POC">
                <Pie {...configPieChart("todaysLeadPerPOC")} />
              </Card>
            </Col>
            <Col xs={24} md={24}>
              <Table
                title={() => <h3>Revenue Per POC</h3>}
                pagination={{
                  hideOnSinglePage: true,
                  responsive: true,
                  pageSize: 50,
                }}
                columns={columns}
                dataSource={data.revenueData}
              />
            </Col>
            {/* <Col xs={24} md={24}>
              <Card title="Today's Payment per POC">
                <Pie {...configPieChart("todaysPaymentPerPOC")} />
              </Card>
            </Col> */}
            {/* <Col xs={24} md={24}>
              <Card title="OverAll Leads per POC">
                <Pie {...configPieChart("totalLeadPerPOC")} />
              </Card>
            </Col> */}
            <Col xs={24} md={24}>
              <Card title="Total Leads per Category">
                <Pie {...configPieChart("totalLeadPerCategory")} />
              </Card>
            </Col>
            <Col xs={24} md={24}>
              <Card title="This month Leads summary">
                <Column
                  {...configBarGraph("leadsPerDay", {
                    x: "dates",
                    y: "Leads",
                  })}
                  loading={loading}
                />
              </Card>
            </Col>
            <Col xs={24} md={24}>
              <Card title="This month Payment summary">
                <Column
                  {...configBarGraph("paymentPerDay", {
                    x: "dates",
                    y: "payments",
                  })}
                  loading={loading}
                />
              </Card>
            </Col>
          </>
        ) : (
          <>
            <Col xs={12} md={6}>
              <Card>
                <Statistic
                  title="Todays Lead Count"
                  loading={loading}
                  value={data?.todaysLeadForPOC}
                  precision={0}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                  // prefix={<ArrowUpOutlined />}
                />
              </Card>
            </Col>

            <Col xs={12} md={6}>
              <Card>
                <Statistic
                  title="Todays Payment"
                  loading={loading}
                  value={data?.todaysPaymentForPOC}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                  // prefix={<ArrowUpOutlined />}
                />
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card>
                <Statistic
                  title="Today's Sale"
                  loading={loading}
                  value={data.revenueData[0].todaysRevenueForPoc}
                  precision={0}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                  // prefix={<ArrowUpOutlined />}
                />
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card>
                <Statistic
                  title="This Month Sale"
                  loading={loading}
                  value={data.thisMonthRevenueForPOC}
                  precision={0}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                  // prefix={<ArrowUpOutlined />}
                />
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card>
                <Statistic
                  title="My Total Leads"
                  loading={loading}
                  value={data?.totalLeadCount}
                  precision={0}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                  // prefix={<ArrowUpOutlined />}
                />
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card>
                <Statistic
                  title="My Total Conversion"
                  loading={loading}
                  value={data?.totalPaidUser}
                  precision={0}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                  // prefix={<ArrowUpOutlined />}
                />
              </Card>
            </Col>
          </>
        )}
        {/* <Col xs={24} md={6}>
          <Card title="Total Leads per Category">
            <Pie {...configPieChart("totalLeadPerCategory")} />
          </Card>
        </Col>
        <Col xs={24} md={6}>
          <Card title="Total Payment per Category">
            <Pie {...configPieChart("paymentPerCategory")} />
          </Card>
        </Col>
        <Col xs={24} md={6}>
          <Card title="Today's Leads per POC">
            <Pie {...configPieChart("todaysleadPerPOC")} />
          </Card>
        </Col>

        <Col xs={24} md={6}>
          <Card title="Today's Payment per POC">
            <Pie {...configPieChart("todaysPaymentPerPOC")} />
          </Card>
        </Col>

        <Col xs={24} md={12}>
          <Card title="Leads per Day">
            <Column
              {...configBarGraph("leadsPerDay", {
                x: "dates",
                y: "Leads",
              })}
              loading={loading}
            />
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card title="Payments per Day">
            <Column
              {...configBarGraph("paymentsPerDay", {
                x: "dates",
                y: "Payment",
              })}
              loading={loading}
            />
          </Card>
        </Col> */}
      </Row>
    </div>
  )
}

export default Analytics

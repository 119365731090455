import React, { useState, useRef } from "react"
import { auth } from "../../firebase"
import { useAuth } from "../../contexts/authContext"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import invalidFieldIndicator from "../../images/signup/invalidFieldIndicator.svg"
import { LockOutlined, UserOutlined } from "@ant-design/icons"
import { Alert, Button, Checkbox, Form, Input } from "antd"
import Logo from ".././../images/logo_white_bg.png"

const SignIn = () => {
  const navigate = useNavigate()

  const { currentUser, login, googleSignIn } = useAuth()
  const [status, setStatus] = useState({
    loading: false,
    success: false,
    error: false,
  })

  const onFinish = async (values) => {
    console.log("Received values of form: ", values)
    try {
      setStatus({ loading: true, success: false, error: "" })
      const res = await login(values.email, values.password)
      console.log("User Signed In")
      const firebaseId = res.user._delegate.uid
      setStatus({ loading: false, success: false, error: false })
      navigate("/")
    } catch (error) {
      console.log("Some Error Has Occured")
      console.log(error)

      if (error.code === "auth/wrong-password") {
        setStatus({
          loading: false,
          error: "Incorrect Password",
          success: false,
        })
      } else if (error.code === "auth/user-not-found") {
        setStatus({
          loading: false,
          error: "No user found, contact Admin",
          success: false,
        })
      } else {
        setStatus({
          loading: false,
          error: "Something went Wrong",
          success: false,
        })
      }
    }
  }

  // return (
  //     <>
  //         <div className="signin-wrap">
  //             <div className="signup-visual">
  //                 {/* <img src={pageViz} alt="" /> */}
  //                 <div className="signup-visual-textcontainer">
  //                     {/* <img src={logo} alt="" /> */}
  //                     <div></div>
  //                     <h2>
  //                         "The future is bright, and we'll help <br /> you get there"
  //                     </h2>
  //                 </div>
  //             </div>
  //             <div className="signup-form-section">
  //                 <div className="signup-form-wrap">

  //                     <form
  //                         onSubmit={handleSignInWithEmail}
  //                         className="signup-form signin-form"
  //                         onKeyDown={(e) => {
  //                             let key = e.charCode || e.keyCode || 0
  //                             if (key == 13) {
  //                                 e.preventDefault()
  //                             }
  //                         }}
  //                     >
  //                         <div className="signup-form-heading-wrap">
  //                             <h2>
  //                                 Welcome aboard homie!<span>{" 🎉 "}</span>
  //                             </h2>
  //                         </div>
  //                         {/* {currentUser && currentUser.email} */}
  //                         {/* <div className="signup-form-heading-wrap">
  //                             <h2 style={{ color: "red" }}>{status.error}</h2>
  //                         </div> */}

  //                         <label>
  //                             Enter Email Address:
  //                             <input
  //                                 name="email"
  //                                 type="email"
  //                                 value={signInDetails.email}
  //                                 onChange={handalInputChangeSignIn}
  //                                 style={{
  //                                     border: isValidSignIn.email
  //                                         ? `1px solid #7B7B7B`
  //                                         : `1px solid #F05353`,
  //                                 }}
  //                             />
  //                             <div
  //                                 className={
  //                                     isValidSignIn.email
  //                                         ? "display-none"
  //                                         : "display-block signup-form-invalid-field"
  //                                 }
  //                             >
  //                                 <img
  //                                     src={invalidFieldIndicator}
  //                                     alt="Invalid Field Indicator"
  //                                 />
  //                                 <div>Please enter valid email Id</div>
  //                             </div>
  //                             <div
  //                                 className={
  //                                     status.error === "incorrect_email"
  //                                         ? "display-block signup-form-invalid-field"
  //                                         : "display-none"
  //                                 }
  //                             >
  //                                 <img
  //                                     src={invalidFieldIndicator}
  //                                     alt="Invalid Field Indicator"
  //                                 />
  //                                 <div>This Email Id is Not Registered</div>
  //                             </div>
  //                             <div
  //                                 className={
  //                                     isValidSignIn.isEmailEmpty
  //                                         ? "display-block signup-form-invalid-field"
  //                                         : "display-none"
  //                                 }
  //                             >
  //                                 <img
  //                                     src={invalidFieldIndicator}
  //                                     alt="Invalid Field Indicator"
  //                                 />
  //                                 <div>This Field Is Required</div>
  //                             </div>
  //                         </label>
  //                         <label>
  //                             Password:
  //                             <input
  //                                 name="password"
  //                                 type="password"
  //                                 value={signInDetails.password}
  //                                 onChange={handalInputChangeSignIn}
  //                             />
  //                             <div
  //                                 className={
  //                                     status.error === "incorrect_password"
  //                                         ? "display-block signup-form-invalid-field"
  //                                         : "display-none"
  //                                 }
  //                             >
  //                                 <img
  //                                     src={invalidFieldIndicator}
  //                                     alt="Invalid Field Indicator"
  //                                 />
  //                                 <div>You Have Entered Incorrect Password</div>
  //                             </div>
  //                             <div
  //                                 className={
  //                                     isValidSignIn.isPasswordEmpty
  //                                         ? "display-block signup-form-invalid-field"
  //                                         : "display-none"
  //                                 }
  //                             >
  //                                 <img
  //                                     src={invalidFieldIndicator}
  //                                     alt="Invalid Field Indicator"
  //                                 />
  //                                 <div>This Field Is Required</div>
  //                             </div>
  //                         </label>

  //                         <div
  //                             className={
  //                                 status.error === "others"
  //                                     ? "display-block signup-form-invalid-field"
  //                                     : "display-none"
  //                             }
  //                         >
  //                             <img
  //                                 src={invalidFieldIndicator}
  //                                 alt="Invalid Field Indicator"
  //                             />
  //                             <div>Unexpected Error Has Occured! Please Try Again</div>
  //                         </div>

  //                         <button type="submit">Login</button>

  //                     </form>

  //                 </div>
  //             </div>
  //         </div>
  //     </>
  // )

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "lightgray",
        padding: 0,
        position: "relative",
      }}
    >
      <img
        style={{ position: "absolute", top: "20px", left: "20px" }}
        src={Logo}
        width="100px"
      />
      {status.error && <Alert message={status.error} type="error" showIcon />}
      <h1>Login</h1>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
            type="email"
            onChange={() => {
              setStatus({ loading: false, success: false, error: false })
            }}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            onChange={() => {
              setStatus({ loading: false, success: false, error: false })
            }}
          />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            span: 20,
            // offset: 4,
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default SignIn

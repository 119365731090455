import { io } from "socket.io-client"
import { useAuth } from "../contexts/authContext";
import constants from "../constants";

export const socket = io.connect(constants.DOMAIN,{
    transports: ['websocket'], // Use only WebSocket, avoiding polling
  });

export const ConnectRoom =(room)=>{

    socket.emit("join_room", room);
}
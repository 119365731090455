import React,{useEffect,useState} from 'react'
import {
  PlusCircleOutlined,
  ZoomInOutlined,
  EditOutlined,
  CloseOutlined,
  CheckOutlined
  } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme,Form,Input,Button,Row,Col,Card,List,Typography,Space,Badge, Divider,Tag,Statistic,
    Timeline,Empty,Modal,DatePicker,Switch,Tooltip,message,Skeleton,Avatar,Popover,Segmented,Alert,Result,
    Select,
    Table} from 'antd';
import axios from 'axios';
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import constants from '../../constants';
import useView from '../../hooks/useView';
import Loader from '../../util/loader';
const { Header, Content, Footer, Sider } = Layout;
const { Option } = Select
const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 16,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 5,
    span: 16,
  },
}

function Agent() {
    const [config, setConfig] = useState([]);
    const [systemUser, setSystemUser] = useState([]);
    const [option, setOption] = useState([]);
    const [optionUser, setUserOption] = useState([]);
    const [singleConfiguration, setSingleConfiguration] = useState()
    const [addAgent, setAddAgent]=useState(false);
    const [modalType, setModalType]=useState('EDIT');
    const [loading, setLoading] = useState(false)
    const [loadingTable, setTableLoading] = useState(false)
    const {isMobile} = useView()
    const Navigate = useNavigate()
    const [form] = Form.useForm()
    const [form1] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [isModalAnalyticsOpen, setIsModalAnalyticsOpen] = useState(false);
    const fetchData = async()=>{
        setLoading(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/config/getAllConfiguration`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      console.log(res.data.data.configurations);
      setLoading(false)
      setConfig(res.data.data.configurations)
      let arr = []
      await res.data.data.configurations.map((data)=>{
      arr.push({
          value: data.program,
          label: data.program,
        });
      })
      setOption(arr)
      messageApi.open({
        type: "success",
        content:"Data Found Successfully",
      })
    } catch (error) {
      setLoading(false)
      messageApi.open({
        type: "error",
        content: error.response.data.message || "Something went wrong",
      })
      console.log(error)
    }
    }

    const fetchSystemUserData = async()=>{
      setLoading(true)
  try {
    const url = `${constants.NEW_DOMAIN}/api/v1/config/getAllSalesAgent`
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
      }
    })
    // console.log(res.data.data.configurations);
    setLoading(false)
    setSystemUser(res.data.data.users)
    let arr = []
      await res.data.data.users.map((data)=>{
      arr.push({
          value: data._id,
          label: data.name,
        });
      })
      setUserOption(arr)
    messageApi.open({
      type: "success",
      content:"Data Found Successfully",
    })
  } catch (error) {
    setLoading(false)
    messageApi.open({
      type: "error",
      content: error.response.data.message || "Something went wrong",
    })
    console.log(error)
  }
  }

  const onFinishAddAgent = async(values)=>{

    // setLoading(true)
    try {
    const url = `${constants.NEW_DOMAIN}/api/v1/config/updatePocInConfiguration`
    const res = await axios.post(url,{
      program:singleConfiguration.program,
      action:"ADD",
      pocId:values.agent
    }, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
      }
    })
    fetchSigleconfiguration(singleConfiguration.program,false)
    setLoading(false)
    setAddAgent(false)
    messageApi.open({
      type: "success",
      content:"Agent Added Successfully",
    })
  } catch (error) {
    setLoading(false)
    messageApi.open({
      type: "error",
      content: error.response.data.message || "Something went wrong",
    })
    console.log(error)
  }
  }

  const handleSubmit = async (record) => {
    console.log(record)
    setTableLoading(true)
    try {
      record.new = false
      const url = `${constants.NEW_DOMAIN}/api/v1/config/updateSystemUserById`
      const res = await axios.post(url, {
        pocId: record._id,
        data: record,
      }, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setTableLoading(false)
    } catch (error) {
      setTableLoading(false)
      console.log(error)
    }
  }
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: false,
      render: (_, record) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
          {record.name}
          </div>
        )
      },
    },
    {
      title: "Status",
      dataIndex: "isActive",
      sorter: false,
      //   fixed: "left",
      render: (isActive, record) => (
        <>
        <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked={isActive}
      onClick={(e)=>{
       record.isActive = e
      }}
    />
        </>
      ),
      // ...getColumnSearchProps("phone"),
    },
    {
      title: "Programs",
      dataIndex: "programs",
      sorter: false,
      //   fixed: "left",
      render: (programs, record) => (
        <>
        <Select
    mode="tags"
    style={{
      width: '100%',
    }}
    onChange={(e)=>{
      record.programs = e 
    }}
    defaultValue={programs}
    // tokenSeparators={[',']}
    options={option}
  />
        </>
      ),
      // ...getColumnSearchProps("phone"),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 80,
      render: (_, record) => (
        <>
          <Space direction="vertical" size={"small"}>
              <Tooltip title="save">
                <Button
                  type="link"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => handleSubmit(record)}
                />
              </Tooltip>
          </Space>
        </>
      ),
    },
  ]
   
  const onFinishDeleteAgent = async(agentId)=>{
    // setLoading(true)
    try {
    const url = `${constants.NEW_DOMAIN}/api/v1/config/updatePocInConfiguration`
    const res = await axios.post(url,{
      program:singleConfiguration.program,
      action:"DELETE",
      pocId:agentId
    }, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
      }
    })
    fetchSigleconfiguration(singleConfiguration.program,false)
    setLoading(false)
    setAddAgent(false)
    messageApi.open({
      type: "success",
      content:"Agent Delete Successfully",
    })
  } catch (error) {
    setLoading(false)
    messageApi.open({
      type: "error",
      content: error.response.data.message || "Something went wrong",
    })
    console.log(error)
  }
  }
    const fetchSigleconfiguration = async(program,loading=true)=>{
    setLoading(loading)
    try {
    const url = `${constants.NEW_DOMAIN}/api/v1/config/getSingleConfiguration`
    const res = await axios.post(url,{
      program
    }, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
      }
    })
    setSingleConfiguration(res.data.data.configuration)
    setIsModalAnalyticsOpen(true)
    
if(loading){
    messageApi.open({
      type: "success",
      content:"Data Found Successfully",
    })
}    setLoading(false)
  } catch (error) {
    setLoading(false)
    messageApi.open({
      type: "error",
      content: error.response.data.message || "Something went wrong",
    })
    console.log(error)
  }
  }

  const onFinishCreate = async(values) =>{
  setLoading(true)
  try {
    const url = `${constants.NEW_DOMAIN}/api/v1/config/`
    const res = await axios.post(url,{
      program:values.program,
      POC:values.POC,
    }, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
      }
    })
    fetchData()
    setLoading(false)
    messageApi.open({
      type: "success",
      content:"Configuration Create Successfully",
    })
  } catch (error) {
    setLoading(false)
    messageApi.open({
      type: "error",
      content: error.response.data.message || "Something went wrong",
    })
    console.log(error)
  }
  }
    useEffect(() => {
    fetchData()
    fetchSystemUserData()
    }, []);
    
    if (loading) {
        return <Loader />
      }
        
  return (
   <>
    {contextHolder}
    <Modal title={`Create / Edit Configuration`} open={isModalAnalyticsOpen}  footer={""} onCancel={()=>setIsModalAnalyticsOpen(false)}>
    {modalType === 'EDIT' && <React.Fragment>
   <div style={{display:"flex", justifyContent:'space-between',alignItems:'center'}}><p style={{fontWeight:"bold",fontSize:"18px",color:"#971eff"}}>{singleConfiguration?.program}</p> <Button type="primary" color='#971eff' shape="round" icon={<PlusCircleOutlined />} size="small" onClick={()=>{setAddAgent(true)}}>
            Add Agent
          </Button></div>
          {addAgent && <React.Fragment>
            <Form
          {...layout}
          form={form1}
          name="control-hooks"
          onFinish={onFinishAddAgent}
          style={{
            maxWidth: 600,
            display: isMobile?"":"flex",
          }}
        >
          <Form.Item
             style={{ width:isMobile?"200px": "300px",marginLeft:isMobile?"":"30px" }}
            name="agent"
            label="Agent"
          >
            <Select style={{ marginLeft: isMobile?"":"5px" }} placeholder="Choose Agent">
              {systemUser &&
                systemUser.map((slot, key) => {
                  return (
                    <Option key={key} value={slot._id}>
                      {slot?.name}
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Form.Item>
        </Form>
            </React.Fragment>}
    <p><b>Active Agent List</b></p>
    <div style={{display:"flex",flexDirection:"column"}}>
                       {
                        singleConfiguration?.POC?.map((data)=>{
                        return <React.Fragment>
                        <div style={{display:"flex", justifyContent:'space-between',alignItems:'center'}}><p style={{fontWeight:"bold",fontSize:"14px",color:"black",display:"flex",alignItems:'center',gap:"5px"}}><Badge color={data?.isActive?"green":"red"} /> {data?.name}</p> <Button type="dashed" color='#971eff' shape="round" icon={<PlusCircleOutlined />} size="small" onClick={()=>onFinishDeleteAgent(data._id)}>
            Remove Agent
          </Button></div>
                        </React.Fragment>
                        })
                       }
                       </div>
                       </React.Fragment>}
    {modalType === 'CREATE'&& <React.Fragment>
      <Form
              name="dynamic_form_nest_item"
              form={form}
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 16,
              }}
              onFinish={onFinishCreate}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="program"
                label="Program"
                rules={[
                  {
                    required: true,
                    message: "Program is required!",
                  },
                ]}
              >
                <Input value={form.getFieldValue("program")} />
              </Form.Item>

              <Form.Item
                name="POC"
                label="Agents"
                rules={[
                  {
                    required: true,
                    message: "At least One Agent is required",
                  },
                ]}
              >
                <Select
    mode="tags"
    style={{
      width: '100%',
    }}
    options={optionUser}
  />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 4,
                  span: 16,
                }}
              >
                <Button type="primary" block htmlType="submit">
                  Create Configuration
                </Button>
              </Form.Item>
            </Form>
      </React.Fragment>}
    </Modal>
           <div style={{ backgroundColor: "#F5F5F7", padding: "20px",display:'flex', alignItems:"center",gap:"10px" }}>
            <h1>Agent Configurations</h1>
           <Button type="primary" color='#971eff' shape="round" icon={<PlusCircleOutlined />} size="middle" onClick={()=>{
            setModalType('CREATE')
             setIsModalAnalyticsOpen(true)

           }}>
            Add New Configurations
          </Button>
          </div>
          <React.Fragment>
        {
         config.length === 0 ? <Result
         status="warning"
         title="No Configurations Found"
        //  extra={
        //    <Button type="primary" key="console">
        //      Go Console
        //    </Button>
        //  }
       />:<>
            <Row gutter={[16, 24]}>
              {config?.map((s, i) => {
                return (
                  <Col xs={24} md={8} key={i}>
                    <Card
                    bordered
                      actions={[
                        <div
                          onClick={() => {
                            setModalType('EDIT')
                            fetchSigleconfiguration(s.program)
                          }}
                        >
                          Edit Configuration <ZoomInOutlined />
                        </div>
                      ]}
                    >
                       <div style={{color:"black"}}>
                       <p style={{fontWeight:"bold",fontSize:"18px",color:"#971eff"}}>{s.program}</p>
                       <p><b>Active Agents</b> : {s.totalActivePoc}</p>
                      </div>
                    </Card>
                  </Col>
                )
              })}
        </Row>
         </>
        }
          </React.Fragment>
          <div style={{ backgroundColor: "#F5F5F7", padding: "20px",display:'flex', alignItems:"center",gap:"10px" }}>
            <h1>Agent List</h1>
          </div>
<React.Fragment>

<Table
          theme="dark"
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={systemUser}
          loading={loadingTable}
          rowClassName="row"
          size="medium"
          scroll={{
            x: 1500,
          }}
        />
</React.Fragment>
    
   </>
  )
}

export default Agent
import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Switch,
  Typography,
  Select
} from "antd"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import axios from "axios"
import constants from "../../constants"
import Loader from "../../util/loader"
import useView from "../../hooks/useView"

const { Text } = Typography
const { Option } = Select
const Assessment = () => {
  const [category, setCategory] = useState(null)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const {width} = useView();
  const fetchData = async () => {
    setLoading(true)
    try {
      const url = `${constants.NEW_DOMAIN}/api/v1/category`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      setData(res.data.data.docs)
      console.log(res.data.data.docs)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
    form.setFieldValue("price", 999)
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    const url = `${constants.NEW_DOMAIN}/api/v1/category/createAndUpdate`
    try {
      // // const token = await getToken()
      // const headers = {
      //   // authorization: `bearer ${token}`,
      // }

      const res = await axios.post(url, values, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
        }
      })
      // form.resetFields()
      setData((prevData) => {
        const newArr = prevData.map((category) => {
          if (category._id === res.data.data.doc._id) {
            return res.data.data.doc
          } else {
            return category
          }
        })
        return newArr
      })
      setLoading(false)
    } catch (error) {
      if (error.response) {
        setLoading(false)
        console.log(error.response.data)
        console.log(error.response.status)
      }
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div style={{ margin: "0 10px" }}>
      <Row gutter={[16, 24]}>
        <Col xs={24} md={8}>
          <Card
            title="Courses"
            bordered={false}
            extra={
              <Button onClick={() => form.resetFields()}>Add New +</Button>
            }
          >
             {width >800 ?<> {data.map((c) => {
              return (
                <Card
                  style={{
                    marginTop: 16,
                  }}
                  type="inner"
                  title={c.title}
                  extra={
                    <Button
                      type="link"
                      onClick={() => {
                        form.setFieldsValue(c)
                      }}
                    >
                      Edit
                    </Button>
                  }
                >
                  <Text ellipsis={true}>{c.description}</Text>
                </Card>
              )
            })}</>:<>
            <Select
    showSearch
    style={{
      width: 200,
    }}
    placeholder="Search to Select"
    optionFilterProp="children"
    filterOption={(input, option) => (option?.label ?? '').includes(input)}
    filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
    onChange={(c)=>{ 
      data.map((data,i)=>{
        if(c === data.code){
          form.setFieldsValue(data)
        }
    })
      console.log(c);
      }}
    options={(data || []).map((d) => ({
      value: d.code,
      data:d,
      label: d.title,
    }))}
  />
            </>}
          </Card>
        </Col>

        <Col xs={24} md={16} style={{ maxHeight: "80vh", overflowY: "scroll" }}>
          <Card title="Edit Category">
            <Form
              name="dynamic_form_nest_item"
              form={form}
              labelCol={{
                span: 3,
              }}
              wrapperCol={{
                span: 16,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="code"
                label="code"
                rules={[
                  {
                    required: true,
                    message: "code is required!",
                  },
                ]}
              >
                <Input placeholder="ex: SMM" />
              </Form.Item>

              <Form.Item
                name="title"
                label="title"
                rules={[
                  {
                    required: true,
                    message: "title is required!",
                  },
                ]}
              >
                <Input placeholder="Social Media and Marketing" />
              </Form.Item>

              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "Please provide description!",
                  },
                ]}
              >
                <Input.TextArea placeholder="description" />
              </Form.Item>
              <Form.Item
                name="thumbnail"
                label="Thumbnail"
                rules={[
                  {
                    required: true,
                    message: "Please provide thumbnail video url from Vimeo",
                  },
                  {
                    type: "url",
                    warningOnly: true,
                  },
                ]}
              >
                <Input placeholder="Thumbnail Video URL" />
              </Form.Item>
              <Form.Item name="totalLessons" label="Total Lessons">
                <Input placeholder="15" type="number" />
              </Form.Item>
              <Form.Item name="price" label="Price">
                <Input disabled />
              </Form.Item>
              <Form.Item name="trainer" label="Trainer">
                <Input placeholder="trainer" />
              </Form.Item>
              <Divider
                orientation="left"
                style={{
                  margin: "50px 0",
                  color: "#5c4db1",
                  // fontWeight: "bolder",
                }}
              >
                Add Assessment Questions to this category
              </Divider>

              <Form.List name="assessment">
                {(fields, { add, remove }) => (
                  <>
                  {/* {console.log(fields)} */}
                    {fields.map(({ key, name, ...restField }, i) => (
                      <div key={i}>
                        <h1 style={{ textAlign: "center" }}>
                          Question #{i + 1}
                          <span>
                            <MinusCircleOutlined
                              style={{ color: "red", marginLeft: "20px" }}
                              onClick={() => remove(name)}
                            />
                          </span>
                        </h1>
                        <Form.Item
                          {...restField}
                          name={[name, "questionId"]}
                          label="questionId"
                          rules={[
                            {
                              required: true,
                              message: "id is required",
                            },
                          ]}
                        >
                          <Input type="number" placeholder="ex : 1,2,3" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "question"]}
                          label="question"
                          rules={[
                            {
                              required: true,
                              message: "question is required",
                            },
                          ]}
                        >
                          <Input placeholder="Question" />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "a"]}
                          label="a"
                          rules={[
                            {
                              required: true,
                              message: "Option is required",
                            },
                          ]}
                        >
                          <Input placeholder="Option 1" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "b"]}
                          label="b"
                          rules={[
                            {
                              required: true,
                              message: "Option is required",
                            },
                          ]}
                        >
                          <Input placeholder="Option 2" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "c"]}
                          label="c"
                          rules={[
                            {
                              required: true,
                              message: "Option is required",
                            },
                          ]}
                        >
                          <Input placeholder="Option 3" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "d"]}
                          label="d"
                          rules={[
                            {
                              required: true,
                              message: "Option is required",
                            },
                          ]}
                        >
                          <Input placeholder="Option 4" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "imageUrl"]}
                          label="url"
                          rules={[
                            {
                              type: "url",
                              warningOnly: true,
                            },
                          ]}
                        >
                          <Input placeholder="Vimeo URL" />
                        </Form.Item>
                        <Form.Item
                          name={[name, "isVisual"]}
                          label="Visual?"
                          valuePropName={"checked"}
                        >
                          <Switch />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "answer"]}
                          label="answer"
                          rules={[
                            {
                              required: true,
                              message: "Answer is required",
                            },
                          ]}
                        >
                          <Input placeholder="eg : a" />
                        </Form.Item>

                        
                      </div>
                    ))}
                    <Form.Item
                      wrapperCol={{
                        offset: 3,
                        span: 16,
                      }}
                    >
                      <Button
                        // type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add New Question
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Form.Item
                wrapperCol={{
                  offset: 3,
                  span: 16,
                }}
              >
                <Button type="primary" block htmlType="submit">
                  Create Category
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Assessment
